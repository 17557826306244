import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./recharge.css";

export default function MobileRecharge(props) {
  const [isPrepaid, setIsPrepaid] = useState(true);
  const [isPostapid, setIsPostpaid] = useState(false);
  const [operator, setOperator] = useState("");
  const [mobNum, setMobNum] = useState("");
  const [circle, setCircle] = useState("");
  const [amount, setAmount] = useState("");
  const history = useHistory();
  const circles = [
    "",
    "Andhra Pradesh",
    "Assam",
    "Bihar Jharkhand",
    "Delhi NCR",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu Kashmir",
    "Karnataka",
    "Kerala",
    "Kolkata",
    "Madhya Pradesh",
    "Chhattisgarh",
    "Maharashtra",
    "Mumbai",
    "North East",
    "Orissa",
    "Punjab",
    "Rajasthan",
    "Tamil Nadu",
    "UP East",
    "UP West",
    "West Bengal",
  ];
  const [isloading, setLoading] = useState(false);
  const [rechargeType, setType] = useState("Prepaid");

  const onTypeChange = (event) => {
    setIsPrepaid(!isPrepaid);
    console.log(event.target.value);

    setIsPostpaid(!isPostapid);
    setType(event.target.value);
  };

  const mobileNumberHandler = (mobile) => {
    setMobNum(mobile);

    if (mobile.length === 10) {
      axios
        .get("/api/recharge/getOperator/" + props.user.id, {
          params: {
            number: mobile,
          },

          headers: { Authorization: props.user.token },
        })
        .then((res) => {
          setLoading(false);

          if (res.status === 200) {
            const data = res.data;
            console.log(data.data);
            const optStatus = data.data.STATUS;
            var opt = data.data.Operator;
            var circle__name = data.data.Circle;

            if (optStatus === "1" || optStatus === 1) {
              if (opt === "IDEA" || opt === "VODAFONE") {
                opt = "VI";
              }
              if (opt === "BSNL GSM" || opt === "BSNL") {
                opt = "BSNL STV";
              }
              if (
                opt === "Reliance Jio Infocomm Limited" ||
                opt === "Reliance Jio Infocomm Limited"
              ) {
                opt = "Jio";
              }

              if (circle__name === "UP West and Uttaranchal") {
                circle__name = "UP West";
              }

              if (circle__name === "Jammu and Kashmir") {
                circle__name = "Jammu Kashmir";
              }

              if (circle__name === "MP and Chattisgarh") {
                circle__name = "Madhya Pradesh";
              }

              if (circle__name === "Bihar and Jharkhand") {
                circle__name = "Bihar Jharkhand";
              }

              setCircle(circle__name);
              setOperator(opt);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setCircle("");
      setOperator("");
    }
  };

  function MobileRechargeHandle(e) {
    e.preventDefault();
    setLoading(true);
    if (
      rechargeType !== null &&
      rechargeType !== undefined &&
      operator !== null &&
      operator !== undefined &&
      mobNum !== null &&
      mobNum !== undefined &&
      circle !== null &&
      circle !== undefined &&
      amount !== null &&
      amount !== undefined &&
      amount >= 10
    ) {
      setLoading(false);
      history.push("confirm", {
        rechargeType: rechargeType,
        operator: operator,
        num: mobNum,
        circle: circle,
        amount: amount,
      });
    }
  }
  return props.user.accountType === "RT" ? (
    <div>
      <div className="container">
        <div className="p-2">
          <div className="bg-white box-shadow-6 p-2">
            <div className="heading">Mobile Recharge</div>
            <form>
              <div className="Radio-group">
                <div className="radio-box">
                  <input
                    type="radio"
                    name="pre"
                    id="pre"
                    checked={isPrepaid ? true : false}
                    value="Prepaid"
                    onChange={onTypeChange}
                  />
                  <label htmlFor="pre">Prepaid</label>
                </div>
                <div className="radio-box" style={{ marginLeft: "50px" }}>
                  <input
                    type="radio"
                    name="pre"
                    id="pos"
                    checked={isPostapid ? true : false}
                    value="Postpaid"
                    onChange={onTypeChange}
                  />
                  <label htmlFor="pos">Postpaid</label>
                </div>
              </div>
              <div className="input-form-group">
                <input
                  type="text"
                  className="Mob-form-control"
                  id="MobNum"
                  name="MobNum"
                  placeholder="Mobile Number"
                  maxLength="10"
                  onChange={(e) => mobileNumberHandler(e.target.value)}
                />
                <select
                  className="Mob-form-control"
                  onChange={(e) => setOperator(e.target.value)}
                >
                  <option value="">Select Operator</option>
                  <option
                    value="Airtel"
                    selected={"Airtel" === operator ? true : false}
                  >
                    Airtel
                  </option>
                  <option
                    value="BSNL - TOPUP"
                    selected={"BSNL - TOPUP" === operator ? true : false}
                  >
                    BSNL - TOPUP
                  </option>
                  <option
                    value="BSNL - STV"
                    selected={"BSNL - STV" === operator ? true : false}
                  >
                    BSNL - STV
                  </option>
                  <option
                    value="Jio"
                    selected={"Jio" === operator ? true : false}
                  >
                    Jio
                  </option>
                  <option
                    value="VI"
                    selected={"VI" === operator ? true : false}
                  >
                    VI
                  </option>
                </select>

                <select
                  className="Mob-form-control"
                  onChange={(e) => setCircle(e.target.value)}
                >
                  {circles.map((circleName, index) => (
                    <option
                      key={circleName}
                      value={circleName}
                      selected={circleName === circle ? true : false}
                    >
                      {circleName === "" ? "Select Circle" : circleName}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  className="Mob-form-control"
                  id="RechAmount"
                  name="RechAmount"
                  placeholder="Amount"
                  onChange={(e) => setAmount(e.target.value)}
                />

                <div className="form-group" style={{ margin: "0" }}>
                  <button
                    type="submit"
                    id="requestBtn"
                    name="requestBtn"
                    className="btn btn-primary"
                    onClick={isloading ? null : MobileRechargeHandle}
                  >
                    {isloading ? "Loading..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="container">
        <div className="p-2">
          <div className="bg-white box-shadow-6 p-2">
            <h1>Unautherise Access</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
