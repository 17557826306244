import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import './Homepage.css'
import { CircularProgress } from '@mui/material';

export default function DTHome (props) {
  const [totalRechargeSuccessAmount, setRechargeSuccessAmount] = useState(0)
  const [totalDmtSuccessAmount, setDmtSuccessAmount] = useState(0)
  const [totalRechargeSuccessCount, setRechargeSuccessCount] = useState(0)
  const [totalDmtSuccessCount, setDmtSuccessCount] = useState(0)

  const [totalRechargePendingAmount, setRechargePendingAmount] = useState(0)
  const [totalDmtPendingAmount, setDmtPendingAmount] = useState(0)

  const [totalRechargePendingCount, setRechargePendingCount] = useState(0)
  const [totalDmtPendingCount, setDmtPendingCount] = useState(0)

  const [totalRechargeFailedAmount, setRechargeFailedAmount] = useState(0)
  const [totalDmtFailedAmount, setDmtFailedAmount] = useState(0)

  const [totalRechargeFailedCount, setRechargeFailedCount] = useState(0)
  const [totalDmtFailedCount, setDmtFailedCount] = useState(0)

  const [totalCommission, setCommission] = useState(0)
  const [totalCharge, setCharge] = useState(0)

  const [totalRechargeSell, setTotalRechargeSell] = useState(0)
  const [totalDmtSell, setTotalDmtSell] = useState(0)

  const [trList, settrList] = useState([])
  const [isLoading, setLoading] = useState(false)


  useEffect(() => {
    if (props.user.id !== null){
      setLoading(true)
      axios.get('/api/dt/dashboard/' + props.user.id, {
        headers: { Authorization: props.user.token }
      })
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setRechargeSuccessAmount(data.rechargeTotal.toFixed(2))
              setDmtSuccessAmount(data.dmtTotal.toFixed(2))
              setRechargeSuccessCount(data.rechargeSuccessCount)
              setDmtSuccessCount(data.dmtSuccessCount)

              setRechargePendingAmount(data.rechargePendingAmount.toFixed(2))
              setDmtPendingAmount(data.dmtPendingAmount.toFixed(2))

              setRechargePendingCount(data.rechargePendingCount)
              setDmtPendingCount(data.dmtPendingCount)

              setRechargeFailedAmount(data.rechargeFailedAmount.toFixed(2))
              setDmtFailedAmount(data.dmtFailedAmount.toFixed(2))

              setRechargeFailedCount(data.rechargeFailedCount)
              setDmtFailedCount(data.dmtFailedCount)
              setCommission(
                data.rechargeCommissionEarned !== null
                  ? data.rechargeCommissionEarned.toFixed(2)
                  : 0.0
              )
              setCharge(
                data.dmtCharge !== null
                  ? data.dmtCharge.toFixed(2)
                  : 0.0
              )
              setTotalRechargeSell(data.rechargeTotal.toFixed(2))
              setTotalDmtSell(data.dmtTotal.toFixed(2))
              settrList(data.trReport)
            }else if(res.data.status === 3 && res.data.message === 'Session Expire'){
              localStorage.clear()
              props.setLoggedIn(false)
            }
          }
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
        })
    }
  }, [props])

  return (
    <div>
      <div className='container'>
        <div className='wrap' style={{ flexDirection: 'column' }}>
          <div className='notificationBar'>
            <span style={{ fontSize: '16px', fontWeight: '500' }}>
            Hi, Welcome to Bs Recharge!
            </span>
          </div>
          <div className='subHeading p-1'>Recharge Dasboard</div>
          <div className='Dash-Flex-CardBox-1'>
            <div className='dash-report-card bg-success'>
              <div className='Titleheading'>SUCCESS ({totalRechargeSuccessCount})</div>
              <div className='SubtitleBox'>₹{totalRechargeSuccessAmount}</div>
            </div>
            <div className='dash-report-card bg-pending'>
              <div className='Titleheading'>PENDING ({totalRechargePendingCount})</div>
              <div className='SubtitleBox'>₹{totalRechargePendingAmount}</div>
            </div>
            <div className='dash-report-card bg-failure'>
              <div className='Titleheading'>FAILURE ({totalRechargeFailedCount})</div>
              <div className='SubtitleBox'>₹{totalRechargeFailedAmount}</div>
            </div>
            <div className='dash-report-card bg-info'>
              <div className='Titleheading'>Commission</div>
              <div className='SubtitleBox'>₹{totalCommission}</div>
            </div>
            <div className='dash-report-card'>
              <div className='Titleheading'>TOTAL SALE</div>
              <div className='SubtitleBox'>₹{totalRechargeSell}</div>
            </div>
          </div>
          <div className='subHeading p-1'>DMT Dasboard</div>
          <div className='Dash-Flex-CardBox-1'>
            <div className='dash-report-card bg-success'>
              <div className='Titleheading'>SUCCESS ({totalDmtSuccessCount})</div>
              <div className='SubtitleBox'>₹{totalDmtSuccessAmount}</div>
            </div>
            <div className='dash-report-card bg-pending'>
              <div className='Titleheading'>PENDING ({totalDmtPendingCount})</div>
              <div className='SubtitleBox'>₹{totalDmtPendingAmount}</div>
            </div>
            <div className='dash-report-card bg-failure'>
              <div className='Titleheading'>FAILURE ({totalDmtFailedCount})</div>
              <div className='SubtitleBox'>₹{totalDmtFailedAmount}</div>
            </div>
            <div className='dash-report-card bg-info'>
              <div className='Titleheading'>Charge</div>
              <div className='SubtitleBox'>₹{totalCharge}</div>
            </div>
            <div className='dash-report-card'>
              <div className='Titleheading'>TOTAL SALE</div>
              <div className='SubtitleBox'>₹{totalDmtSell}</div>
            </div>
          </div>
          <div className='Dash-sub-heading'>Recent Transaction</div>
          <div className='Admin-Dash-Flex-CardBox-2'>
            <div className='Dash-recent-transection'>
            {isLoading ? (
            <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
          ) : (
            trList.length > 0 ? (
              <table className='Dash-rech-table-view'>
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>username</th>
                    <th>Client Id</th>
                    <th>Operator Id</th>
                    <th>Operation Name</th>
                    <th>Account No</th>
                    <th>Date</th>
                    <th>Mobile No</th>
                    <th>Amount</th>
                    <th>Commission</th>
                    <th>Charge</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {trList.map((transaction, index) => (
                    <tr key={transaction.id.toString()}>
                      <td>{index + 1}</td>
                      <td>{transaction.name}</td>
                      <td>{transaction.id}</td>
                      <td>{transaction.operatorId}</td>
                      <td>{transaction.operatorName}</td>
                      <td>{transaction.accountNumber}</td>
                      <td>{moment(transaction.rechargeAt).format('YYYY-MM-DD h:mm:ss a')}</td>
                      <td>{transaction.rechargeMobile}</td>
                      <td>₹{transaction.amount}</td>
                      {transaction.commission !== null ? (
                      <td>₹{transaction.commission}</td>):(
                        <td>0.00</td>
                      )}
                      {transaction.charge !== null ? (
                      <td>₹{transaction.charge}</td>):(
                        <td>0.00</td>
                      )}
                      {transaction.rechargeStatus === '1' ? (
                        <td className='text-success'>Success</td>
                      ) : transaction.rechargeStatus === '3' ? (
                        <td className='text-failure'>Refunded</td>
                      ) : transaction.rechargeStatus === '2' ? (
                        <td className='text-failure'>Failure</td>
                      ) : (
                        <td className='text-pending'>Pending</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              ) : (
                <div className="p-1">
                  <div className="heading">No Transaction available</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
