import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./Homepage.css";
import { CircularProgress, Button } from "@mui/material";

export default function AdminHome(props) {
  const [totalRechargeSuccessAmount, setRechargeSuccessAmount] = useState(0);
  const [totalDmtSuccessAmount, setDmtSuccessAmount] = useState(0);
  const [totalRechargeSuccessCount, setRechargeSuccessCount] = useState(0);
  const [totalDmtSuccessCount, setDmtSuccessCount] = useState(0);

  const [totalRechargePendingAmount, setRechargePendingAmount] = useState(0);
  const [totalDmtPendingAmount, setDmtPendingAmount] = useState(0);

  const [totalRechargePendingCount, setRechargePendingCount] = useState(0);
  const [totalDmtPendingCount, setDmtPendingCount] = useState(0);

  const [totalRechargeFailedAmount, setRechargeFailedAmount] = useState(0);
  const [totalDmtFailedAmount, setDmtFailedAmount] = useState(0);

  const [totalRechargeFailedCount, setRechargeFailedCount] = useState(0);
  const [totalDmtFailedCount, setDmtFailedCount] = useState(0);

  const [totalCommission, setCommission] = useState(0);
  const [totalCharge, setCharge] = useState(0);

  const [totalRechargeSell, setTotalRechargeSell] = useState(0);
  const [totalDmtSell, setTotalDmtSell] = useState(0);
  const [totalAepsBalance, setAepsBalance] = useState(0.0);
  const [totalAgentBalance, setTotalAgentBalance] = useState(0.0);
  const [totalAgentPreviousBalance, setTotalAgentPreviousBalance] = useState(0.0);
  const [totalDMTBalance, setDMTBalance] = useState(0.0);
  const [totalAepsAvailComm, setAepsAvailComm] = useState(0.0);

  const [trList, settrList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isBalLoading, setBalLoading] = useState(false);

  function aepsDashboardBalance() {
    setBalLoading(true);
    axios
      .post(
        "/api/admin/aeps/dashboard/balance/" + props.user.id,
        {},
        {
          headers: { Authorization: props.user.token },
        }
      )
      .then((res) => {
        setBalLoading(false);
        if (res.status === 200) {
          const data = res.data.response;
          console.log(res);
          setAepsBalance(data.aepsbalance);
          setDMTBalance(data.dmtbalance);
          setAepsAvailComm(data.aepsAvailCommission);
          if (res.data.status === 3 || res.data.message === "Session Expire") {
            localStorage.clear();
            props.setLoggedIn(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (props.user.id !== null) {
      setLoading(true);
      axios
        .post(
          "/api/admin/dashboard/" + props.user.id,
          {},
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const data = res.data;
            console.log(res);
            if (data.status === 1) {
              setRechargeSuccessAmount(data.rechargeTotal.toFixed(2));
              setDmtSuccessAmount(data.dmtTotal.toFixed(2));
              setRechargeSuccessCount(data.rechargeSuccessCount);
              setDmtSuccessCount(data.dmtSuccessCount);
              setTotalAgentBalance(data.agentBalance.toFixed(2));
              setTotalAgentPreviousBalance(data.agentPreviousBalance.toFixed(2));

              setRechargePendingAmount(data.rechargePendingAmount.toFixed(2));
              setDmtPendingAmount(data.dmtPendingAmount.toFixed(2));

              setRechargePendingCount(data.rechargePendingCount);
              setDmtPendingCount(data.dmtPendingCount);

              setRechargeFailedAmount(data.rechargeFailedAmount.toFixed(2));
              setDmtFailedAmount(data.dmtFailedAmount.toFixed(2));

              setRechargeFailedCount(data.rechargeFailedCount);
              setDmtFailedCount(
                data.dmtFailedCount === null || data.dmtFailedCount === ""
                  ? 0
                  : data.dmtFailedCount
              );
              setCommission(
                data.rechargeCommissionEarned !== null
                  ? data.rechargeCommissionEarned.toFixed(2)
                  : 0.0
              );
              setCharge(
                data.dmtCharge !== null ? data.dmtCharge.toFixed(2) : 0.0
              );
              setTotalRechargeSell(data.rechargeTotal.toFixed(2));
              setTotalDmtSell(data.dmtTotal.toFixed(2));
              settrList(data.trReport);
            } else if (
              res.data.status === 3 ||
              res.data.message === "Session Expire"
            ) {
              localStorage.clear();
              props.setLoggedIn(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props]);

  function redeemNow() {
    if (
      window.confirm(
        "Are you sure to redeem availabe commission ₹" + totalAepsAvailComm
      )
    ) {
      axios
        .post(
          "/api/admin/aeps/balance/redeem/" + props.user.id,
          {},
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setAepsAvailComm(res.response.aepsAvailCommission);
            console.log(res);
            alert(res.response.message);
            if (
              res.data.status === 3 ||
              res.data.message === "Session Expire"
            ) {
              localStorage.clear();
              props.setLoggedIn(false);
            }
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }

  return (
    <div>
      <div className="container">
        <div className="wrap" style={{ flexDirection: "column" }}>
          <div className="notificationBar">
            <span style={{ fontSize: "16px", fontWeight: "500" }}>
              Hi, Welcome to Bs Recharge!
            </span>
          </div>
          <div className="subHeading p-1">Recharge Dashboard</div>
          <div className="Dash-Flex-CardBox-1">
            <div className="dash-report-card bg-success">
              <div className="Titleheading">
                SUCCESS ({totalRechargeSuccessCount})
              </div>
              <div className="SubtitleBox">₹{totalRechargeSuccessAmount}</div>
            </div>
            <div className="dash-report-card bg-pending">
              <div className="Titleheading">
                PENDING ({totalRechargePendingCount})
              </div>
              <div className="SubtitleBox">₹{totalRechargePendingAmount}</div>
            </div>
            <div className="dash-report-card bg-failure">
              <div className="Titleheading">
                FAILURE ({totalRechargeFailedCount})
              </div>
              <div className="SubtitleBox">₹{totalRechargeFailedAmount}</div>
            </div>
            <div className="dash-report-card bg-info">
              <div className="Titleheading">Commission</div>
              <div className="SubtitleBox">₹{totalCommission}</div>
            </div>
            <div className="dash-report-card">
              <div className="Titleheading">TOTAL SALE</div>
              <div className="SubtitleBox">₹{totalRechargeSell}</div>
            </div>
          </div>
          <div className="subHeading p-1">DMT Dashboard</div>
          <div className="Dash-Flex-CardBox-1">
            <div className="dash-report-card bg-success">
              <div className="Titleheading">
                SUCCESS ({totalDmtSuccessCount})
              </div>
              <div className="SubtitleBox">₹{totalDmtSuccessAmount}</div>
            </div>
            <div className="dash-report-card bg-pending">
              <div className="Titleheading">
                PENDING ({totalDmtPendingCount})
              </div>
              <div className="SubtitleBox">₹{totalDmtPendingAmount}</div>
            </div>
            <div className="dash-report-card bg-failure">
              <div className="Titleheading">
                FAILURE ({totalDmtFailedCount})
              </div>
              <div className="SubtitleBox">₹{totalDmtFailedAmount}</div>
            </div>
            <div className="dash-report-card bg-info">
              <div className="Titleheading">Charge</div>
              <div className="SubtitleBox">₹{totalCharge}</div>
            </div>
            <div className="dash-report-card">
              <div className="Titleheading">TOTAL SALE</div>
              <div className="SubtitleBox">₹{totalDmtSell}</div>
            </div>
          </div>
          <div className="subHeading p-1">
            Aeps Details
            {isBalLoading ? (
              <CircularProgress size="24px" thickness={4} />
            ) : (
              <Button onClick={aepsDashboardBalance}>
                <i className="material-icons">refresh</i>
              </Button>
            )}
          </div>
          <div className="Dash-Flex-CardBox-1">
            <div className="dash-report-card">
              <div className="Titleheading">AEPS Balance</div>
              <div className="SubtitleBox">₹{totalAepsBalance}</div>
            </div>
            <div className="dash-report-card">
              <div className="Titleheading">AEPS Avail Comm</div>
              <div className="SubtitleBox">₹{totalAepsAvailComm}</div>
              <div
                className="pb-1"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {parseInt(totalAepsAvailComm) > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={redeemNow}
                  >
                    Redeem
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="dash-report-card">
              <div className="Titleheading">DMT Balance</div>
              <div className="SubtitleBox">₹{totalDMTBalance}</div>
            </div>
            <div className="dash-report-card">
              <div className="Titleheading">Agent Previous Balance</div>
              <div className="SubtitleBox">₹{totalAgentPreviousBalance}</div>
            </div>
            <div className="dash-report-card">
              <div className="Titleheading">Agent Total Balance</div>
              <div className="SubtitleBox">₹{totalAgentBalance}</div>
            </div>
          </div>
          <div className="Dash-sub-heading">Recent Transaction</div>
          <div className="Admin-Dash-Flex-CardBox-2">
            <div className="Dash-recent-transection">
              {isLoading ? (
                <div
                  className="p-2"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <CircularProgress />
                </div>
              ) : trList.length > 0 ? (
                <table className="Dash-rech-table-view">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>username</th>
                      <th>Client Id</th>
                      <th>Operator Id</th>
                      <th>Operation Name</th>
                      <th>Account No</th>
                      <th>Date</th>
                      <th>Mobile No</th>
                      <th>Amount</th>
                      <th>Commission</th>
                      <th>Charge</th>
                      <th>Status</th>
                      <th>API Vendor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trList.map((transaction, index) => (
                      <tr key={transaction.id.toString()}>
                        <td>{index + 1}</td>
                        <td>{transaction.name}</td>
                        <td>{transaction.id}</td>
                        <td>{transaction.operatorId}</td>
                        <td>{transaction.operatorName}</td>
                        <td>{transaction.accountNumber}</td>
                        <td>
                          {moment(transaction.rechargeAt).format(
                            "YYYY-MM-DD h:mm:ss a"
                          )}
                        </td>
                        <td>{transaction.rechargeMobile}</td>
                        <td>₹{transaction.amount}</td>
                        {transaction.commission !== null &&
                        transaction.commission !== "0" ? (
                          <td>₹{transaction.commission}</td>
                        ) : (
                          <td>0.00</td>
                        )}
                        {transaction.charge !== null ? (
                          <td>₹{transaction.charge}</td>
                        ) : (
                          <td>0.00</td>
                        )}
                        {transaction.rechargeStatus === "1" ? (
                          <td className="text-success">Success</td>
                        ) : transaction.rechargeStatus === "3" ? (
                          <td className="text-failure">Refunded</td>
                        ) : transaction.rechargeStatus === "2" ? (
                          <td className="text-failure">Failure</td>
                        ) : (
                          <td className="text-pending">Pending</td>
                        )}
                        <td>{transaction.vendorName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="p-1">
                  <div className="heading">No Transaction available</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
