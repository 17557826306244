import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import './paymentRequest.css'

export default function PaymentRequest (props) {
  const [isNew, setNew] = useState(true)
  const [loading, setLoading] = useState(false)
  const [bankName, setBankName] = useState('')
  const [message, setMessage] = useState('')
  const [paymentDate, setPaymentDate] = useState('')
  const [paymentAmount, setPaymentAmount] = useState('')
  const [paymentUTRNo, setPaymentUTRNo] = useState('')
  const [remark, setRemark] = useState('')
  const [paymentReport, setPaymentReport] = useState([])
  const [error, setError] = useState('')


  function updatePaymentReqHandle (e) {
    e.preventDefault()
    if (
      bankName != null &&
      paymentAmount != null &&
      paymentDate != null &&
      paymentUTRNo != null &&
      remark != null
    ) {
      setLoading(true)
      axios
        .post(
          '/api/payment/paymentRequest/' + props.user.id,
          {
            bankName: bankName,
            paymentDate: paymentDate,
            paymentAmount: paymentAmount,
            bankUTRNo: paymentUTRNo,
            remark: remark
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            setBankName('')
            setMessage('')
            setPaymentDate('')
            setPaymentAmount('')
            setPaymentUTRNo('')
            setRemark('')
            if (data.status === 1) {
              setNew(true)
              setMessage('Payment Request successfully.')
            } else {
              setMessage(data.message)
            }
          }
        })
        .catch(error => {
          setMessage(error.message)
        })
    } else {
      setMessage('Empty request')
    }
  }
  useEffect(() => {
    if (props.user.id !== null && isNew === true) {
      axios
        .get('/api/payment/view/' + props.user.id, {
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            console.log(res)
            const data = res.data
            setNew(false)
            if (data.status === 1) {
              setPaymentReport(data.paymentReqReort)
            } else {
              setPaymentReport([])
              setError(data.message)
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          setError(error)
        })
    }
  }, [props, isNew])

  return (
    <div>
      <div className='container'>
        <div className='content-wrap'>
          <div className='Payment-request-box'>
            <div className='heading'>Payment Request</div>
            <div className='Payment-request-card'>
              {message !== '' ? (
                <div className='heading p-2'>{message}</div>
              ) : (
                ''
              )}
              <form className='Payment-request-form'>
                <select
                  className='form-control'
                  onChange={e => setBankName(e.target.value)}
                  value={bankName}
                >
                  <option value=''>Select Bank</option>
                  <option value='Canara Bank'>Canara Bank</option>
                  <option value='Cash'>Cash</option>
                </select>
                <input
                  type='date'
                  className='form-control'
                  id='payment-date'
                  name='payment-date'
                  placeholder='Date'
                  value={paymentDate}
                  onChange={e => setPaymentDate(e.target.value)}
                />
                <input
                  type='text'
                  className='form-control'
                  id='amount'
                  name='amount'
                  placeholder='Enter Amount'
                  value={paymentAmount}
                  onChange={e => setPaymentAmount(e.target.value)}
                />
                <input
                  type='text'
                  className='form-control'
                  id='utrNo'
                  name='utrNo'
                  placeholder='Bank Ref No. (UTR)'
                  value={paymentUTRNo}
                  onChange={e => setPaymentUTRNo(e.target.value)}
                />
                <input
                  type='text'
                  className='form-control'
                  id='remark'
                  name='remark'
                  placeholder='Remark'
                  value={remark}
                  onChange={e => setRemark(e.target.value)}
                />
                <div className='form-group'>
                  <button
                    type='submit'
                    id='requestBtn'
                    name='requestBtn'
                    className='btn btn-primary'
                    onClick={loading ? null : updatePaymentReqHandle}
                  >
                    {loading ? 'Loading...':'Submit'}
                  </button>
                  
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='content-wrap'>
          <div className='Account-detail-box'>
            <div className='heading'>Account Details</div>

            <div className='Account-detail-card'>
              <table className='Account-detail-table'>
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Bank Name</th>
                    <th>Branch Name</th>
                    <th>Account Type</th>
                    <th>IFSC Code</th>
                    <th>Account Number</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Canara Bank</td>
                    <td>chandghardhe</td>
                    <td>Current Account</td>
                    <td>CNRB0004284</td>
                    <td>4284201000015</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='content-wrap'>
          <div className='Payment-Request-detail-box'>
            <div className='heading'>Payment Request List</div>
            <div className='Payment-Request-detail-card'>
              {paymentReport.length > 0 ? (
                <table className='Payment-Request-detail-table'>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Date</th>
                      <th>Bank Name</th>
                      <th>Bank Ref(UTR NO)</th>
                      <th>Amount</th>
                      <th>Request Date</th>
                      <th>Updated Date</th>
                      <th>Response</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentReport.map((transaction, index) => (
                      <tr key={transaction.id.toString()}>
                        <td>{index + 1}</td>
                        <td>
                          {moment(transaction.paymentDate).format(
                            'YYYY-MM-DD h:mm:ss a'
                          )}
                        </td>
                        <td>{transaction.bankName}</td>
                        <td>{transaction.bankRefNo}</td>
                        <td>{transaction.amount}</td>
                        <td>
                          {moment(transaction.createdAt).format(
                            'YYYY-MM-DD h:mm:ss a'
                          )}
                        </td>
                        {transaction.updatedAt !== null ? (
                          <td>
                            {moment(transaction.updatedAt).format(
                              'YYYY-MM-DD h:mm:ss a'
                            )}
                          </td>
                        ) : (
                          <td>{transaction.updatedAt}</td>
                        )}
                        <td>{transaction.response}</td>
                        {transaction.paymentStatus === '1' ? (
                          <td className='text-success'>Success</td>
                        ) : transaction.paymentStatus === '3' ? (
                          <td className='text-failure'>Refunded</td>
                        ) : transaction.paymentStatus === '2' ? (
                          <td className='text-failure'>Failure</td>
                        ) : (
                          <td className='text-pending'>Pending</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='heading p-1'>{error}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
