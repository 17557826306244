import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material';
export default function DMTHistory (props) {
  const [trxnsHistoryList, setTrxnsHistoryList] = useState([])
  const [stDate, setStDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [startDate, setStartDate] = useState('')
  const [lastDate, setLastDate] = useState('')
  const [totalSale, setTotalSale] = useState('')
  const [number, setNumber] = useState('')
  const [txStatus, setStatusFilter] = useState('')
  const [userId, setUserId] = useState('')
  const [limit, setLimit] = useState('25')
  const [colToShow, setColToShow] = useState('')
  const [account, setAccount] = useState('')
  const [optId, setOptId] = useState('')
  const [remark, setRemark] = useState('')
  const [status, setStatus] = useState('')
  const [isLoading, setLoading] = useState(false)

  function UpdateDmtTransactionHandle (e, request) {
    e.preventDefault()
    if (request.id !== null && status !== '') {
      setLoading(true)
      axios
        .post(
          '/api/admin/report/dmt/history/update/' + props.user.id,
          {
            userId: request.id,
            remark: remark,
            optId: optId,
            status: status
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          console.log(res)
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            if (data.status === 1) {
              alert(data.message)
              getHistory(e)
            } else if (data.message === 'Session Expire' || data.status === 3) {
              alert(data.message)
              localStorage.clear()
              props.setLoggedIn(false)
            } else {
              alert(data.message)
            }
          }
        })
        .catch(e => {
          alert(e)
        })
    } else {
      alert('Please fill the form correctly')
    }
  }

  function getHistory (e) {
    e.preventDefault()
    setLoading(true)
    axios
      .get('/api/admin/report/dmt/history/' + props.user.id, {
        params: {
          stDate: stDate,
          endDate: endDate,
          number: number,
          txStatus: txStatus,
          userId: userId,
          limit: limit,
          account: account
        },
        headers: { Authorization: props.user.token }
      })
      .then(res => {
        if (res.status === 200) {
          const data = res.data
          console.log(res)
          setLoading(false)

          if (data.status === 1) {
            setTrxnsHistoryList(data.rechargesHistoryList)
            setTotalSale(data.totalSale)
            setStartDate(data.startDate)
            setLastDate(data.lastDate)
          } else if (data.message === 'Session Expire' || data.status === 3) {
            alert(data.message)
            localStorage.clear()
            props.setLoggedIn(false)
          } else {
            setTrxnsHistoryList([])
            setStartDate(data.startDate)
            setLastDate(data.lastDate)
          }
        }
      })
      .catch(error => {
        alert(error)
      })
  }

  useEffect(() => {
    if (props.user.id != null) {
      setLoading(true)
      axios
        .get('/api/admin/report/dmt/history/' + props.user.id, {
          params: {
            stDate: '',
            endDate: '',
            number: '',
            userId: '',
            limit: '25',
            account: ''
          },
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setTrxnsHistoryList(data.rechargesHistoryList)
              setTotalSale(data.totalSale)
              setStartDate(data.startDate)
              setLastDate(data.lastDate)
            } else if (data.message === 'Session Expire' || data.status === 3) {
              alert(data.message)
              localStorage.clear()
              props.setLoggedIn(false)
            } else {
              setTrxnsHistoryList([])
              setStartDate(data.startDate)
              setLastDate(data.lastDate)
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          setLoading(false)
          alert(error)
        })
    }
  }, [props])

  return (
    <div>
      <div className='container'>
        <div className='wrap' style={{ flexDirection: 'column' }}>
          <div className='box-shadow-6 p-2'>
            <form className='trans-search-wrap'>
              <input
                type='date'
                className='form-control'
                id='fromDate'
                name='fromDate'
                onChange={e => setStDate(e.target.value)}
              />
              <input
                type='date'
                className='form-control'
                id='toDate'
                name='toDate'
                onChange={e => setEndDate(e.target.value)}
              />
              <input
                type='text'
                className='form-control'
                id='MobNum'
                name='MobNum'
                placeholder='Mobile Number'
                value={number}
                onChange={e => setNumber(e.target.value)}
              />
              <input
                type='text'
                className='form-control'
                id='userId'
                name='userId'
                placeholder='Enter User Id'
                value={userId}
                onChange={e => setUserId(e.target.value)}
              />
              <input
                type='text'
                className='form-control'
                id='accountNumber'
                name='accountNumber'
                placeholder='Account Number'
                value={account}
                onChange={e => setAccount(e.target.value)}
              />
              <select
                className='form-control'
                value={limit}
                onChange={e => setStatusFilter(e.target.value)}
              >
                <option value=''>Status (All)</option>
                <option value='1'>Success</option>
                <option value='0'>Pending</option>
                <option value='2'>Failure</option>
              </select>
              <select
                className='form-control'
                value={limit}
                onChange={e => setLimit(e.target.value)}
              >
                <option value=''>Limit</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='All'>All</option>
              </select>

              <div className='form-group'>
                <button
                  type='submit'
                  id='requestBtn'
                  name='requestBtn'
                  className='btn btn-primary'
                  onClick={isLoading ? null : getHistory}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className='p-2'></div>
          <div className='heading'>Transaction History</div>
          <div className='Count-Details'>
            <div className='DateShow'>
              {startDate !== '' ? startDate : 'YYYY-MM-DD'} to{' '}
              {lastDate !== '' ? lastDate : 'YYYY-MM-DD'}
            </div>
            <div className='AccStatementDetails'>
              <div className='ShowBalance'>
                Total Sale:{' '}
                {totalSale !== '' ? parseFloat(totalSale).toFixed(2) : '0.00'}
              </div>
            </div>
          </div>
          <div className='trxns-report-card'>
          {isLoading ? (
            <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
          ) : (
            trxnsHistoryList.length > 0 ? (
              <table className='trans-report-table'>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Recharge Id</th>
                  <th>Username</th>
                  <th>Type</th>
                  <th>Operator Id</th>
                  <th>Vender Id</th>
                  <th>Account Name</th>
                  <th>Operator Name</th>
                  <th>Account No</th>
                  <th>Date</th>
                  <th>Mobile No</th>
                  <th>Amount</th>
                  <th>Debit</th>
                  <th>Charge</th>
                  <th>Status</th>
                  <th>API Vender</th>
                </tr>
                </thead>
                
                {trxnsHistoryList.map((transaction, index) => (
                  <tbody>
                    <tr
                      key={transaction.id}
                      onClick={() => {
                        colToShow === transaction.id
                          ? setColToShow('')
                          : setColToShow(transaction.id)
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{transaction.id}</td>
                      <td>{transaction.name}</td>
                      <td>{transaction.serviceType}</td>
                      <td>{transaction.operatorId}</td>
                      <td>{transaction.venderId}</td>
                      <td>{transaction.account_name}</td>
                      <td>{transaction.operatorName}</td>
                      <td>{transaction.accountNumber}</td>
                      <td>
                        {moment(transaction.rechargeAt).format(
                          'YYYY-MM-DD h:mm:ss a'
                        )}
                      </td>
                      <td>{transaction.rechargeMobile}</td>
                      {transaction.amount !== null ? (
                        <td>₹{transaction.amount}</td>
                      ) : (
                        <td>0.00</td>
                      )}
                      {transaction.debit !== null ? (
                        <td>₹{transaction.debit}</td>
                      ) : (
                        <td>0.00</td>
                      )}
                      {transaction.charge !== null ? (
                        <td>₹{transaction.charge}</td>
                      ) : (
                        <td>0.00</td>
                      )}
                      {transaction.rechargeStatus === '1' ? (
                        <td className='text-success'>Success</td>
                      ) : transaction.rechargeStatus === '3' ? (
                        <td className='text-failure'>Refunded</td>
                      ) : transaction.rechargeStatus === '2' ? (
                        <td className='text-failure'>Failure</td>
                      ) : (
                        <td className='text-pending'>Pending</td>
                      )}
                      <td>{transaction.vendorName}</td>
                    </tr>
                    {colToShow === transaction.id ? (
                      <tr>
                        <td colSpan='14' style={{ textAlign: 'left' }}>
                          <form method='POST'>
                            <span>Remark:- {transaction.remark}</span>
                            &nbsp;&nbsp;
                            <input
                              type='text'
                              id='remarkMsg'
                              name='remarkMsg'
                              className='inputEditbox'
                              placeholder='Remark'
                              value={remark}
                              onChange={e => setRemark(e.target.value)}
                            />
                            <input
                              type='text'
                              id='optId'
                              name='optId'
                              className='inputEditbox'
                              placeholder='Operator id'
                              value={optId}
                              onChange={e => setOptId(e.target.value)}
                            />
                            <select
                              className='inputEditbox'
                              onChange={e => setStatus(e.target.value)}
                              value={status}
                            >
                              <option value=''>Select Request</option>
                              <option value='1'>Success</option>
                              <option value='2'>Failure</option>
                            </select>
                            <button
                              className='btn btn-primary'
                              style={{
                                margin: ' 0px 10px',
                                padding: '8px 20px'
                              }}
                              onClick={e => {
                                UpdateDmtTransactionHandle(e, transaction)
                              }}
                            >
                              Update
                            </button>
                          </form>
                        </td>
                      </tr>
                    ) : (
                      ''
                    )}
                  </tbody>
                ))}
              </table>
            ) : (
              <div className='p-1'>
                <div className='heading'>No transaction found</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
