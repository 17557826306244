import { CircularProgress } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

export default function RedirectHomePage(props) {
  const location = useLocation();

  React.useEffect(() => {
    console.log('homepage redirecation');
    window.location.href = "https://bsrecharge.in/home.html";
  }, [location]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "Center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress></CircularProgress>
    </div>
  );
}
