import React from "react";
import { useLocation } from "react-router-dom";
import AddParam from "./screens/AddParam";
import RequestParam from "./screens/RequestParam";
import ResponseParam from "./screens/ResponseParam";

export default function ApiParamter(props) {
    const location = useLocation()
    const [tab, setTab] = React.useState("request");


  return (
    <div className="container">
      <div className="Admin-wrap">
        <div className="NavBarBtnWrap">
            <div className={tab === 'request' ? 'btn btn-primary' : 'btn'} onClick={() => setTab('request')}>Request</div>
            <div className={tab === 'response' ? 'btn btn-primary' : 'btn'} onClick={() => setTab('response')}>Response</div>
            <div className={tab === 'addNew' ? 'btn btn-primary' : 'btn'} onClick={() => setTab('addNew')}>Add Parameter</div>
        </div>
        {tab ==='request' ? (
          <RequestParam user={props.user} apiId={location.state.apiId} />
        ): tab === 'response'?(
          <ResponseParam user={props.user} apiId={location.state.apiId} />
        ):(
          <AddParam user={props.user}  apiId={location.state.apiId} />
        )}
      </div>
    </div>
  );
}
