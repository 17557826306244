import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './App.css'
import AdminRouter from './Routers/AdminRouter';
import DistributorRouter from './Routers/DistributorRouter';
import Router from './Routers/Router';



function App () {
  const location = useLocation()
  const [user, setUser] = useState(
    localStorage.getItem('user') !== null
      ? JSON.parse(localStorage.getItem('user'))
      : ''
  )
  useEffect(() => {
    setUser(user)
  }, [user, location.pathname])
  return (
    <div className='App'>
      {user.accountType === 'DT' ? (
        <DistributorRouter />
      ) : user.accountType === 'Admin' ? (
        <AdminRouter />
      ) : (
        <Router />
      )}
    </div>
  )
}

export default App
