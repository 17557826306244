import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./package.css";
import PackageDetails from "./PackageDetails";

export default function Package(props) {
  const [packageList, setPackageList] = useState([]);
  const [packageCommDetails, setPackageCommDetails] = useState([]);
  const [packageError, setPackageError] = useState("");

  ///const [error, setError] = useState('')
  const [colToShow, setColToShow] = useState("");

  const history = useHistory();

  function packageDetailsHandler(packageName) {
    if (packageName !== null) {
      setColToShow(packageName);
      axios
        .post(
          "/api/admin/package/details/" + props.user.id,
          {
            packageName: packageName,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const resData = res.data;

            if (resData.status === 1 || resData.status === "1") {
              console.log(res);
              setPackageCommDetails(resData.packageReport);
            } else {
              setPackageCommDetails([]);
              alert(resData.message);
              if (resData.message === "Session Expire") {
                window.location.reload();
              }
            }
          }
        })
        .catch((e) => {
          alert(e);
        });
    }
  }

  function newPackage() {
    history.push("/package/add_operator", {
      packageName: "",
    });
  }

  useEffect(() => {
    if (props.user.id !== null) {
      axios
        .get("/api/admin/package/list/" + props.user.id, {
          headers: { Authorization: props.user.token },
        })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            if (data.status === 1) {
              setPackageList(data.packageNameList);
            } else {
              setPackageError(data.message);
              if (data.message === "Session Expire") {
                window.location.reload();
              }
            }
          }
        })
        .catch((e) => {
          setPackageError(e);
        });
    }
  }, [props]);
  console.log(packageCommDetails);
  return (
    <div className="container" style={{ paddingTop: "80px" }}>
      <div className="wrap box-shadow-6" style={{ justifyContent: "start" }}>
        <div className="packageNameList p-1">
          <div className="heading p-1">Package List</div>
          <div
            className="packageBox pl-1 pb-1 btn btn-primary"
            onClick={newPackage}
          >
            Create New Package
          </div>
          <div className="pb-1"></div>
          {packageList.length > 0 ? (
            <div>
              {packageList.map((list, index) => (
                <div key={list.id}>
                  <div style={{ borderBottom: "1px solid #ddd" }}></div>
                  <div
                    className="packageBox pl-1 pb-1"
                    onClick={() => packageDetailsHandler(list.packageName)}
                  >
                    {list.packageName}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="pl-2 pr-2 pb-2">
              <div className="alert alert-default">{packageError}</div>
            </div>
          )}
        </div>

        <div className="PackageDetails p-1">
          {colToShow !== null ? (
            <PackageDetails
              user={props.user}
              packageCommisionDetails={packageCommDetails}
            />
          ) : (
            " "
          )}
        </div>
      </div>
    </div>
  );
}
