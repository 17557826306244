import axios from 'axios'
import React, { useState } from 'react'

export default function ChangeMpin(props) {
    const [currentMpin, setCurrentMpin] = useState('')
    const [newMpin, setNewMpin] = useState('')
    const [confirmMpin, setConfirmMpin] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    function changeMpinHandler(e) {
        e.preventDefault();
        if (currentMpin !== '' && newMpin !== '' && confirmMpin !== '') {
            if (newMpin === confirmMpin) {
                setLoading(true)
                axios.post('/api/mpin/change/' + props.user.id,
                {
                    currentMpin: currentMpin,
                    newMpin: newMpin
                },
                {
                    headers:{Authorization: props.user.token}
                }).then(res=>{
                    if(res.status === 200){
                        const data = res.data
                        console.log(res)
                        setCurrentMpin('')
                        setNewMpin('')
                        setConfirmMpin('')
                        setLoading(false)
                        if(data.status === 1){
                            setError(data.message)
                        }else{
                            setError(data.message)
                        }
                    }
                }).catch(error =>{
                    setError(error)
                })

            } else {
                setError('Mpin do not match ')
            }
        } else { 
            setError('Empty details and fill all input properly')
        }
    }
    return (
        <div>
            <div className="container">
                <div className="content-wrap">
                    <div className="heading">Change Mpin</div>
                    <div className="change_mpin_card">
                    {error ? <div className='heading p-1'>{error}</div> : ''}
                        <form method="POST" className="change_mpin_form">
                            <input type="password" maxLength='4' className="form-control" id="currentMpin" name="currentMpin" value={currentMpin} placeholder="Enter current mpin" onChange={(e) => setCurrentMpin(e.target.value)} />
                            <input type="password" maxLength='4' className="form-control" id="newMpin" name="newMpin" value={newMpin} placeholder="Enter new mpin" onChange={(e) => setNewMpin(e.target.value)} />
                            <input type="password" maxLength='4' className="form-control" id="confirmMpin" name="confirmMpin" value={confirmMpin} placeholder="Enter confirm mpin" onChange={(e) => setConfirmMpin(e.target.value)} />
                            <div className='form-group'>
                                <button
                                    type='submit'
                                    id='changeMpinBtn'
                                    name='changeMpinBtn'
                                    className='btn btn-primary'
                                    onClick={isLoading ? null : changeMpinHandler}
                                >
                                    {isLoading ? 'Loading...' : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
