import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import './report.css'
import { CircularProgress } from '@mui/material';

export default function RechargeHistory (props) {
  const [trxnsHistoryList, setTrxnsHistoryList] = useState([])
  const [stDate, setStDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [number, setNumber] = useState('')
  const [transType, setTransType] = useState('')
  const [limit, setLimit] = useState('25')
  const [operator, setOperator] = useState('')
  const [isLoading, setLoading] = useState(false)

  function getHistory (e) {
    e.preventDefault()
    setLoading(true)
    axios
      .get('/api/report/view/' + props.user.id, {
        params: {
          stDate: stDate,
          endDate: endDate,
          number: number,
          transType: transType,
          limit: limit,
          operator: operator
        },
        headers: { Authorization: props.user.token }
      })
      .then(res => {
        if (res.status === 200) {
          const data = res.data
          console.log(res)
          setLoading(false)
          if (data.status === 1) {
            setTrxnsHistoryList(data.trxnsReport)
          }else{
            setTrxnsHistoryList([])
          }
        }
      })
      .catch(error => {
        alert(error)
      })
  }

  useEffect(() => {
    if (props.user.id != null) {
      setLoading(true)
      axios
        .get('/api/report/view/' + props.user.id, {
          params: {
            stDate: '',
            endDate: '',
            number: '',
            limit: '25',
            operator: ''
          },
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setTrxnsHistoryList(data.trxnsReport)
            }else{
              setTrxnsHistoryList([])
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          alert(error)
        })
    }
  }, [props])

  return (
    <div>
      <div className='container'>
        <div className='wrap' style={{ flexDirection: 'column' }}>
          <div className='box-shadow-6 p-2'>
            <form className='trans-search-wrap'>
              <input
                type='date'
                className='form-control'
                id='fromDate'
                name='fromDate'
                value={stDate}
                onChange={e => setStDate(e.target.value)}
              />
              <input
                type='date'
                className='form-control'
                id='toDate'
                name='toDate'
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
              />
               <select
                className='form-control'
                value={transType}
                onChange={e => setTransType(e.target.value)}
              >
                <option value=''>Transaction Type</option>
                <option value='Recharge'>Recharge</option>
                <option value='DMT'>DMT</option>
                <option value='Bill Pay'>Bill Payment</option>
                <option value='FAStag'>FAStag</option>
              </select>
              <input
                type='text'
                className='form-control'
                id='MobNum'
                name='MobNum'
                placeholder='Mobile Number'
                value={number}
                onChange={e => setNumber(e.target.value)}
              />
              <input
                type='text'
                className='form-control'
                id='OptrName'
                name='OptrName'
                placeholder='Operator Name'
                value={operator}
                onChange={e => setOperator(e.target.value)}
              />
              <select
                className='form-control'
                value={limit}
                onChange={e => setLimit(e.target.value)}
              >
                <option value=''>Limit</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='All'>All</option>
              </select>

              <div className='form-group'>
                <button
                  type='submit'
                  id='requestBtn'
                  name='requestBtn'
                  className='btn btn-primary'
                  onClick={isLoading ? null : getHistory}
                >
                  {isLoading ? 'Searching..' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
          <div className='p-2'></div>
          <div className='heading'>Transaction History</div>
          {isLoading ? (
            <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className='trxns-report-card'>
              {trxnsHistoryList.length > 0 ? (
                <table className='trans-report-table'>
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Recharge Id</th>
                      <th>Type</th>
                      <th>Operator Id</th>
                      <th>Operator Name</th>
                      <th>Date</th>
                      <th>Mobile No</th>
                      <th>Amount</th>
                      <th>Debit</th>
                      <th>Commission</th>
                      <th>Status</th>
                      <th>Compain</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trxnsHistoryList.map((transaction, index) => (
                      <tr key={transaction.id.toString()}>
                        <td>{index + 1}</td>
                        <td>{transaction.id}</td>
                        <td>{transaction.serviceType}</td>
                        <td>{transaction.operatorId}</td>
                        <td>{transaction.operatorName}</td>
                        <td>
                          {moment(transaction.rechargeAt).format(
                            'YYYY-MM-DD h:mm:ss a'
                          )}
                        </td>
                        <td>{transaction.rechargeMobile}</td>
                        <td>{transaction.amount}</td>
                        <td>{transaction.debit}</td>
                        <td>{transaction.commission}</td>
                        {transaction.rechargeStatus === '1' ? (
                          <td className='text-success'>Success</td>
                        ) : transaction.rechargeStatus === '3' ? (
                          <td className='text-failure'>Refunded</td>
                        ) : transaction.rechargeStatus === '2' ? (
                          <td className='text-failure'>Failure</td>
                        ) : (
                          <td className='text-pending'>Pending</td>
                        )}
                        {transaction.rechargeStatus === '1' ? (
                          <td>
                            <Link
                              to={{
                                pathname: '/complain/',
                                query: { rechargeId: transaction.id }
                              }}
                            >
                              <i className='material-icons'>handyman</i>
                            </Link>
                          </td>
                        ) : (
                          <td>-</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='p-1'>
                  <div className='heading'>No Transaction available</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
