import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import './report.css'
import { CircularProgress } from '@mui/material';

export default function AccountStatement (props) {
  const [stDate, setStDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [number, setNumber] = useState('')
  const [limit, setLimit] = useState('25')
  const [operator, setOperator] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [statementReportList, setStatementReportList] = useState([])

  function getAccountStatement (e) {
    e.preventDefault()
    setLoading(true)
    axios
      .get('/api/report/statement/' + props.user.id, {
        params: {
          stDate: stDate,
          endDate: endDate,
          number: number,
          limit: limit,
          operator: operator
        },
        headers: { Authorization: props.user.token }
      })
      .then(res => {
        if (res.status === 200) {
          setLoading(false)
          const data = res.data
          console.log(res)
          if (data.status === 1) {
            setStatementReportList(data.statementReport)
          } else {
            setStatementReportList([])
            setError(data.message)
          }
        }
      })
      .catch(error => {
        alert(error)
      })
  }

  useEffect(() => {
    if (props.user.id != null) {
      setLoading(true)
      axios
        .get('/api/report/statement/' + props.user.id, {
          params: {
            stDate: '',
            endDate: '',
            number: '',
            limit: '25',
            operator: ''
          },
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setStatementReportList(data.statementReport)
            } else {
              setStatementReportList([])
              setError(data.message)
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          alert(error)
        })
    }
  }, [props])
  return (
    <div className='container'>
      <div className='wrap' style={{ flexDirection: 'column' }}>
        <div className='box-shadow-6 p-2'>
          <form className='trans-search-wrap'>
            <input
              type='date'
              className='form-control'
              id='fromDate'
              name='fromDate'
              onChange={e => setStDate(e.target.value)}
            />
            <input
              type='date'
              className='form-control'
              id='toDate'
              name='toDate'
              onChange={e => setEndDate(e.target.value)}
            />
            <input
              type='text'
              className='form-control'
              id='MobNum'
              name='MobNum'
              placeholder='Mobile Number'
              onChange={e => setNumber(e.target.value)}
            />
            <input
              type='text'
              className='form-control'
              id='OptrName'
              name='OptrName'
              placeholder='Operator Name'
              onChange={e => setOperator(e.target.value)}
            />
            <select
              className='form-control'
              onChange={e => setLimit(e.target.value)}
            >
              <option value=''>Limit</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='All'>All</option>
            </select>

            <div className='form-group'>
              <button
                type='submit'
                id='requestBtn'
                name='requestBtn'
                className='btn btn-primary'
                onClick={getAccountStatement}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className='heading' style={{ marginTop: '50px' }}>
          Account Statement
        </div>
        {isLoading ? (
          <div
            className='p-2'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div className='Acc-Statement-Card'>
            {statementReportList !== null && statementReportList.length > 0 ? (
              <table className='Acc-statement-table'>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Recharge Id</th>
                    <th>Transaction Type</th>
                    <th>Operator</th>
                    <th>Mobile No</th>
                    <th>Amount</th>
                    <th>Commission</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {statementReportList.map((transaction, index) => (
                    <tr key={transaction.id.toString()}>
                      <td>{index + 1}</td>
                      <td>
                        {moment(transaction.rechargeAt).format(
                          'YYYY-MM-DD h:mm:ss a'
                        )}
                      </td>
                      <td>{transaction.id}</td>
                      <td>{transaction.transType}</td>
                      <td>{transaction.operatorName}</td>
                      <td>{transaction.rechargeMobile}</td>
                      <td>{transaction.amount}</td>
                      <td>
                        {transaction.commission !== null &&
                        transaction.commission !== ''
                          ? transaction.commission
                          : '0.00'}
                      </td>
                      <td>
                        {transaction.debit !== null && transaction.debit !== ''
                          ? transaction.debit
                          : '0.00'}
                      </td>
                      <td>{transaction.credit}</td>
                      <td>{transaction.balance}</td>
                      {transaction.rechargeStatus === '1' ? (
                        <td className='text-success'>Success</td>
                      ) : transaction.rechargeStatus === '2' ? (
                        <td className='text-failure'>Refunded</td>
                      ) : transaction.rechargeStatus === '3' ? (
                        <td className='text-failure'>Failure</td>
                      ) : (
                        <td className='text-pending'>Pending</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={error !== '' ? 'heading p-1' : ''}>{error}</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
