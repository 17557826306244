import React from 'react'
import AddBeneficiary from './AddBeneficiary'
import { DeleteOutline } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { CircularProgress } from '@mui/material'

export default function WalletDetails (props) {
  const {wallet, setWallet } = props
  const [beneficiaryList, setBeneficiaryList] = React.useState([])
  const [error, setError] = React.useState()
  const [isLoading, setLoading] = React.useState(false)
  const [isBeneLoading, setBeneLoading] = React.useState(false)
  const [isChecking, setChecking] = React.useState('')
  const [walletName, setWalletName] = React.useState()
  const [walletMonthlyLimit, setWalletMonthlyLimit] = React.useState()
  const [walletAvailableLimit, setWalletAvailableLimit] = React.useState()
  const [tab, setTab] = React.useState('list')
  const history = useHistory()
  const [amount, setAmount] = React.useState('')
  const [paymentMode, setPaymentMode] = React.useState('')
  const [selectCol, setSelectCol] = React.useState('')

  function maxLengthCheck (object) {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      )
    }
  }
  function payAmountHandler (e, beneficiary) {
    e.preventDefault()
    if (
      beneficiary.id !== '' &&
      beneficiary !== null &&
      amount !== null &&
      amount !== ''
    ) {
      history.push('/dmt/confirm', {
        wallet: wallet,
        bankName: beneficiary.bene_bank,
        beneName: beneficiary.bene_name,
        beneId: beneficiary.id,
        accountName: beneficiary.account_name,
        accountNumber: beneficiary.bene_account,
        bankIfscCode: beneficiary.bene_ifsc,
        amount: amount,
        payMode:paymentMode
      })
    } else {
    }
  }

  function deleteBeneficiaryHandler (beneficiaryId) {
    if (beneficiaryId !== null) {
      axios
        .post(
          '/api/sendmoney/deleteBeneficiary/' + props.user.id,
          {
            beneId: beneficiaryId
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            if ( data.status === "1" || data.status === 1) {
              getBeneficiaryList()
              alert('Beneficiary delete Successfully')
              setTab('list')
            } else alert(data.message)
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch((error) =>{
          alert(error.message);
        });
    }
  }

  React.useEffect(() => {
    function getBeneficiaryHandler () {
      if (wallet !== null) {
        setLoading(true)
        axios
          .post(
            '/api/sendmoney/wallet/' + props.user.id,
            {
              number: wallet
            },
            {
              headers: { Authorization: props.user.token }
            }
          )
          .then(res => {
            if (res.status === 200) {
              const data = res.data
              console.log(res)
              if (data.status === "1" || data.status === 1) {
                setLoading(false)
                setWalletName(data.Name)
                setWalletMonthlyLimit(data.MonthlyLimit)
                setWalletAvailableLimit(data.AvailLimit)
                setBeneficiaryList(data.BeneList)
              }
            } else if (
              res.data.status === 3 &&
              res.data.message === 'Session Expire'
            ) {
              localStorage.clear()
              props.setLoggedIn(false)
            }
          })
          .catch(error => {
            setError(error)
          })
      } else {
        setLoading(false)
        setError('Invalid Mobile Details')
      }
    }
    getBeneficiaryHandler()
  }, [wallet, props])

  function getBeneficiaryList () {
    if (wallet !== null) {
      setBeneLoading(true)
      axios
        .post(
          '/api/sendmoney/wallet/' + props.user.id,
          {
            number: wallet
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            if ( data.status === "1" || data.status === 1 ) {
              setBeneLoading(false)
              setBeneficiaryList(data.BeneList)
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          setError(error)
        })
    } else {
      setBeneLoading(false)
      setError('Invalid Mobile Details')
    }
  }
  function getAccountVerification (beneficiary) {
    setChecking(beneficiary.id);
    if (wallet !== null) {
      axios
        .post(
          '/api/sendmoney/verification/' + props.user.id,
          {
            number: wallet,
            beneId: beneficiary.id,
            account: beneficiary.bene_account,
            bank: beneficiary.bene_bank,
            ifsc: beneficiary.bene_ifsc
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
             if (data.status === 3 && data.message === 'Session Expire') {
              setChecking('')
              localStorage.clear()
              props.setLoggedIn(false)
            }else{
              setChecking('')
                beneficiary.account_name = data.Name
                alert(data.Name);
                props.getUser();
            }
          }
        })
        .catch(error => {
          setChecking('')
          setError(error)
        })
    }
    
  }

  return (
    <div>
      {isLoading ? (
        <div className='p-2' style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
      ) : walletName === '' &&
        walletMonthlyLimit === '' &&
        walletAvailableLimit === '' ? (
        error ? (
          <div className='subHeading p-1 error-color'>{error}</div>
        ) : (
          ''
        )
      ) : (
        <>
          <div className='wallet_card'>
            <div className='subHeading p-1'>Wallet Details</div>
            <div className='py-1' style={{ display: 'flex' }}>
              <div className='px-1'>
                <div>Name</div>
                <div>{walletName}</div>
              </div>
              <div className='px-1'>
                <div>Wallet</div>
                <div>{wallet}</div>
              </div>
              <div className='px-1'>
                <div>Limit</div>
                <div>
                  {walletAvailableLimit === walletMonthlyLimit
                    ? walletMonthlyLimit
                    : walletAvailableLimit}
                </div>
              </div>
            </div>
          </div>

          <div className='mt-1 beneficiaryCard'>
            <div style={{ display: 'flex' }}>
              <div>
                <button
                  onClick={e => {
                    setTab('list')
                    getBeneficiaryList()
                  }}
                  className={
                    tab === 'list'
                      ? 'btnTransparent p-1 activeTab'
                      : ' btnTransparent p-1 '
                  }
                >
                  List
                </button>
              </div>
              <div>
                <button
                  onClick={e => {
                    setTab('addBeneficiary')
                  }}
                  className={
                    tab === 'addBeneficiary'
                      ? 'btnTransparent p-1 activeTab'
                      : ' btnTransparent p-1 '
                  }
                >
                  Add Beneficiary
                </button>
              </div>
              <div>
                <button
                  onClick={e => {
                    setTab('logout')
                    localStorage.removeItem('wallet')
                    setWallet(null)
                  }}
                  className={
                    tab === 'logout'
                      ? 'btnTransparent p-1 activeTab'
                      : ' btnTransparent p-1 '
                  }
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
          {tab === 'list' ? (
            <>
              <div className='mt-1 wallet_card'>
                <div className='subHeading p-1'>Beneficiary List</div>
                {isBeneLoading ? (
                  <div className='subHeading p-1'>Loading..</div>
                ) : (
                  <div style={{ overflow: 'hidden', overflowX: 'auto' }}>
                    {beneficiaryList.length > 0 ? (
                      <table className='table-collapse'>
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>Name</th>
                            <th>Bank Name</th>
                            <th>Account Number</th>
                            <th>Ifsc Code</th>
                            <th>Account Name</th>
                            <th>Mode</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Pay</th>
                          </tr>
                        </thead>
                        <tbody>
                        {beneficiaryList.map((beneficiary, index) => (
                          <tr key={beneficiary.id.toString()}>
                            <td>
                              <button
                                className='btnTransparent'
                                onClick={() =>
                                  deleteBeneficiaryHandler(beneficiary.id)
                                }
                              >
                                <DeleteOutline />
                              </button>
                            </td>
                            <td>{beneficiary.bene_name}</td>
                            <td>{beneficiary.bene_bank}</td>
                            <td>{beneficiary.bene_account}</td>
                            <td>{beneficiary.bene_ifsc}</td>
                            <td>{beneficiary.account_name}</td>
                            <td>
                              <select
                                className='mode-control'
                                onChange={e => setPaymentMode(e.target.value)}
                                onClick={() => setSelectCol(beneficiary.id)}
                                //setSelectCol();
                                value={paymentMode}
                              >
                                <option value=''>Select</option>
                                {selectCol === beneficiary.id ? (
                                  <>
                                    <option value='IMPS'>IMPS</option>
                                    <option value='NEFT'>NEFT</option>
                                  </>
                                ) : (
                                  ''
                                )}
                              </select>
                            </td>
                            <td>
                              <input
                                type='number'
                                maxLength='5'
                                placeholder='Amount'
                                className='amount-control'
                                id='beneficiaryAmount'
                                name='beneficiaryAmount'
                                value={
                                  selectCol === beneficiary.id ? amount : ''
                                }
                                onInput={maxLengthCheck}
                                onChange={e => {
                                  setSelectCol(beneficiary.id)
                                  setAmount(e.target.value)
                                }}
                              />
                            </td>
                            {beneficiary.account_name !== '' ? (
                              <td className='text-success'>Verified</td>
                            ) : (
                              <td>
                                <button
                                  className='BeneficiaryBtn'
                                  onClick={() => {
                                    getAccountVerification(beneficiary)
                                  }}
                                >
                                  {isChecking === beneficiary.id ? (
                                <div>verifying..</div>
                              ) : ('Verify')}
                                </button>
                              </td>
                            )}
                            <td>
                              <button
                                type='submit'
                                className='BeneficiaryBtn'
                                onClick={e => {
                                  payAmountHandler(e, beneficiary)
                                }}
                              >
                                Submit
                              </button>
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className='p-1'>
                        <div>List not available</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          ) : tab === 'addBeneficiary' ? (
            <AddBeneficiary
              getBeneficiaryHandler={getBeneficiaryList}
              setTab={setTab}
              {...props}
            />
          ) : (
            ''
          )}
        </>
      )}
    </div>
  )
}
