import React from "react";
import AepsAddBeneficiary from "./AepsAddBeneficiary";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";
import TransferToUPI from "../TransferToUPI/TransferToUPI";


export default function SettleToBank(props) {
  const { aepsBeneficiaryList, getBeneficiaryList, getBalance } = props;
  const [action, setAction] = React.useState("beneficiaryList");
  const [amount, setAmount] = React.useState("");
  const [txnpin, setTxnpin] = React.useState("");
  const [selectCol, setSelectCol] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const history = useHistory();
  function maxLengthCheck(object) {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  }

  function aepsTransferAmountHandler(e, transaction) {
    e.preventDefault();
    if (txnpin !== "" && txnpin !== null && amount !== "" && amount !== null) {
      setLoading(true);

      axios
        .post(
          "/api/aeps/settlement/" + props.user.id,
          {
            beneId: transaction.id,
            amount: amount,
            upiId: '',
            upi_name: '',
            type: "ToBank",
            txnpin: txnpin,
          },
          { headers: { Authorization: props.user.token } }
        )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            setLoading(false);
            if (data.status === 3 || data.message === "Session Expire") {
              localStorage.clear();
              props.setLoggedIn(false);
            } else {
              history.push("/aeps/response", {
                beneficiaryDetails: transaction,
                amount: amount,
                response: data.message,
                status: data.status,
                trxnId: data.trxnId,
                utrNo: data.rrn,
              });
            }
          } else {
            setAmount("");
            setTxnpin("");
          }
          console.log(res);
        })
        .catch((e) => {
          alert(e.message);
        });
    } else {
      alert("Please fill all empty fields");
      //console.log(transaction);
      // console.log(amount);
      // console.log(txnpin);
    }
  }

  return (
    <>
      <div className="mt-1 aeps_card">
        <div className=" p-1" style={{ display: "flex", flexWrap: "wrap" }}>
          <div>
            <button
              onClick={(e) => {
                setAction("beneficiaryList");
              }}
              className={
                action === "beneficiaryList"
                  ? "btnTransparent p-1 activeTab"
                  : " btnTransparent p-1 "
              }
            >
              Transfer To Bank
            </button>
          </div>
          <div>
            <button
              onClick={(e) => {
                setAction("transferToUPI");
              }}
              className={
                action === "transferToUPI"
                  ? "btnTransparent p-1 activeTab"
                  : " btnTransparent p-1 "
              }
            >
              Transfer To UPI
            </button>
          </div>
        </div>
        {action === "beneficiaryList" ? (
          <>
            <div className="subHeading p-1">
              Settlement Beneficiary
              {aepsBeneficiaryList != null && aepsBeneficiaryList.length < 3 ? (
                <Button
                  style={{ marginLeft: "30px" }}
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    setAction("addBeneficiary");
                  }}
                >
                  Add Beneficiary
                </Button>
              ) : (
                ""
              )}
            </div>
            {!isLoading ? (
              <div style={{ overflow: "hidden", overflowX: "auto" }}>
                {aepsBeneficiaryList != null &&
                aepsBeneficiaryList.length > 0 ? (
                  <table className="table-collapse">
                    <thead>
                      <tr>
                        <th>Account Name</th>
                        <th>Bank Name</th>
                        <th>Account Number</th>
                        <th>Ifsc Code</th>
                        <th>Amount</th>
                        <th>trxn pin</th>
                        <th>Pay</th>
                      </tr>
                    </thead>
                    <tbody>
                      {aepsBeneficiaryList.map((transaction, index) => (
                        <tr key={transaction.id.toString()}>
                          {transaction.account_name !== "" &&
                          transaction.account_name !== null ? (
                            <td>{transaction.account_name}</td>
                          ) : (
                            <td>{transaction.bene_name}</td>
                          )}
                          <td>{transaction.bene_bank}</td>
                          <td>{transaction.bene_account}</td>
                          <td>{transaction.bene_ifsc}</td>
                          <td>
                            <input
                              type="number"
                              maxLength="5"
                              placeholder="Amount"
                              className="amount-control"
                              id="beneficiaryAmount"
                              name="beneficiaryAmount"
                              value={selectCol === transaction.id ? amount : ""}
                              onInput={maxLengthCheck}
                              onChange={(e) => {
                                setSelectCol(transaction.id);
                                setAmount(e.target.value);
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="password"
                              maxLength="4"
                              placeholder="Trxn pin"
                              className="amount-control"
                              name="trxnpin"
                              value={selectCol === transaction.id ? txnpin : ""}
                              onChange={(e) => {
                                setSelectCol(transaction.id);
                                setTxnpin(e.target.value);
                              }}
                            />
                          </td>
                          <td>
                            <button
                              type="submit"
                              className="BeneficiaryBtn"
                              onClick={(e) => {
                                aepsTransferAmountHandler(e, transaction);
                              }}
                            >
                              Submit
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </>
        ) : action === "addBeneficiary" ? (
          <AepsAddBeneficiary
            setAction={setAction}
            getBeneficiaryList={getBeneficiaryList}
            {...props}
          />
        ) : action === "transferToUPI" ? (
          <TransferToUPI {...props}  getBalance = {getBalance} />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
