import axios from 'axios'
import React, { useState } from 'react'

export default function CreateUsers (props) {
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState('')
  const [name, setName] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [email, setEmail] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [mobNum, setMobNum] = useState('')
  const [pswd, setPswd] = useState('')
  const [accType, setAccType] = useState('')
  const [dtMobNum, setDtMobNum] = useState('')
  const [isDMT, setDMT] = useState(false)
  const [isRecharge, setRecharge] = useState(false)
  const [isAEPS, setAEPS] = useState(false)
  const [ismatm, setmatm] = useState(false)
  const [aepsId, setAepsId] = useState('')

  function createUserHandler (e) {
    e.preventDefault()
    if (
      name !== '' &&
      businessName !== '' &&
      email !== '' &&
      state !== '' &&
      city !== '' &&
      mobNum !== '' &&
      pswd !== '' &&
      accType !== '' 
    ) {
      setLoading(true)
      axios
        .post(
          '/api/admin/user/create/' + props.user.id,
          {
            name: name,
            businessName: businessName,
            email: email,
            state: state,
            city: city,
            mobNum: mobNum,
            password: pswd,
            accType: accType,
            dtMobNum: dtMobNum,
            isDMT: isDMT === false ? '0':'1',
            isRecharge: isRecharge === false ? '0':'1',
            isAEPS: isAEPS === false ? '0':'1',
            ismatm: ismatm === false ? '0':'1',
            aepsId: aepsId
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          setLoading(false)
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            setName('')
            setBusinessName('')
            setEmail('')
            setState('')
            setCity('')
            setMobNum('')
            setPswd('')
            setAccType('')
            setDtMobNum('')
            if (data.status === 1) {
              alert(data.message)
              setError('User Created Successfully.')
            } else {
              setError(data.message)
            }
          }
        })
        .catch(error => {
          setError(error)
        })
    } else {
      setError('Please fill all the details.')
    }
  }
  return (
    <div>
      <div className='container'>
        <div className='Admin-wrap'>
          <div className='heading'>Create User</div>
          <div className='user-info-box'>
            <div className={error !== '' ? 'AdminError' : ''}>{error}</div>
            <form
              className='user-create-card'
              style={{ margin: '0px auto' }}
              method='POST'
            >
              <select
                className='user-input-box'
                onChange={e => setAccType(e.target.value)}
                value={accType}
              >
                <option value=''>Select Account Type</option>
                <option value='RT'>RT</option>
                <option value='DT'>DT</option>
                <option value='API'>API</option>
              </select>
              <input
                type='text'
                name='name'
                className='user-input-box'
                placeholder='Enter User Name'
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <input
                type='text'
                name='businessName'
                className='user-input-box'
                placeholder='Enter Business Name'
                value={businessName}
                onChange={e => setBusinessName(e.target.value)}
              />
              <input
                type='text'
                name='email'
                className='user-input-box'
                placeholder='Enter Email Id'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <input
                type='text'
                name='mobNum'
                maxLength='10'
                className='user-input-box'
                placeholder='Enter Mobile Number'
                value={mobNum}
                onChange={e => setMobNum(e.target.value)}
              />
              {accType === 'RT' ? (
                <input
                  type='text'
                  name='dtMobNum'
                  maxLength='10'
                  className='user-input-box'
                  placeholder='Enter Dist. Mobile Number (Optional)'
                  value={dtMobNum}
                  onChange={e => setDtMobNum(e.target.value)}
                />
              ) : (
                ''
              )}
              <select
                className='user-input-box'
                onChange={e => setState(e.target.value)}
                value={state}
              >
                <option value=''>Select State</option>
                <option value='Andhra Pradesh'>Andhra Pradesh</option>
                <option value='Arunachal Pradesh'>Arunachal Pradesh</option>
                <option value='Assam'>Assam</option>
                <option value='Bihar'>Bihar</option>
                <option value='Chhattisgarh'>Chhattisgarh</option>
                <option value='Goa'>Goa</option>
                <option value='Gujarat'>Gujarat</option>
                <option value='Haryana'>Haryana</option>
                <option value='Himachal Pradesh'>Himachal Pradesh</option>
                <option value='Jharkhand'>Jharkhand</option>
                <option value='Karnataka'>Karnataka</option>
                <option value='Kerala'>Kerala</option>
                <option value='Madhya Pradesh'>Madhya Pradesh</option>
                <option value='Maharashtra'>Maharashtra</option>
                <option value='Manipur'>Manipur</option>
                <option value='Meghalaya'>Meghalaya</option>
                <option value='Mizoram'>Mizoram</option>
                <option value='Nagaland'>Nagaland</option>
                <option value='Odisha'>Odisha</option>
                <option value='Punjab'>Punjab</option>
                <option value='Rajasthan'>Rajasthan</option>
                <option value='Sikkim'>Sikkim</option>
                <option value='Tamil Nadu'>Tamil Nadu</option>
                <option value='Telangana'>Telangana</option>
                <option value='Tripura'>Tripura</option>
                <option value='Uttarakhand'>Uttarakhand</option>
                <option value='Uttar Pradesh'>Uttar Pradesh</option>
                <option value='West Bengal'>West Bengal</option>
              </select>
              <input
                type='text'
                name='city'
                className='user-input-box'
                placeholder='Enter City Name'
                value={city}
                onChange={e => setCity(e.target.value)}
              />
              <input
                type='text'
                name='aepsId'
                className='user-input-box'
                placeholder='Enter Aeps Id (Optional)'
                value={aepsId}
                onChange={e => setAepsId(e.target.value)}
              />
              <div className='PickAtService'>
              <div style={{display:'flex', flexDirection:'row'}}>
                    <input
                      value={isRecharge}
                      type='checkbox'
                      onChange={() => {
                        setRecharge(!isRecharge)
                      }}
                    />
                    <span className='subHeading'>Recharge</span>
                    </div>
                    <div style={{display:'flex', flexDirection:'row'}}>
                    <input
                      value={isDMT}
                      type='checkbox'
                      onChange={() => {
                        setDMT(!isDMT)
                      }}
                    />
                    <span className='subHeading'>DMT</span>
                    </div>
                    <div style={{display:'flex', flexDirection:'row'}}>
                    <input
                      value={isAEPS}
                      type='checkbox'
                      onChange={() => {
                        setAEPS(!isAEPS)
                      }}
                    />
                    <span className='subHeading'>AEPS</span>
                    </div>
                    <div style={{display:'flex', flexDirection:'row'}}>
                    <input
                      value={ismatm}
                      type='checkbox'
                      onChange={() => {
                        setmatm(!ismatm)
                      }}
                    />
                    <span className='subHeading'>MATM</span>
                    </div>
              </div>
              <input
                type='password'
                name='password'
                className='user-input-box'
                placeholder='Enter Password'
                value={pswd}
                onChange={e => setPswd(e.target.value)}
              />
              <button
                id='createUserBtn'
                name='createUserBtn'
                className='btn btn-primary'
                onClick={isLoading ? null : createUserHandler}
              >
                {isLoading ? 'Loading..' : 'Create User'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
