import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

export default function UserInfo (props) {
  const [username, setName] = useState('')
  const [aepsId, setAepsId] = useState('')
  const [isRecharge, setRecharge] = useState(false)
  const [isDMT, setDMT] = useState(false)
  const [isAEPS, setAEPS] = useState(false)
  const [ismatm, setmatm] = useState(false)
  const [emailId, setEmailId] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [mobNum, setMobNum] = useState('')
  const [ipAddress, setIpAddress] = useState('')
  const [cityName, setCityName] = useState('')
  const [stateName, setStateName] = useState('')
  const [mpin, setMpin] = useState('')
  const [password, setPassword] = useState('')
  const [dtMobNum, setDtMoNum] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [lastLogin, setLastLogin] = useState('')
  const [tab, setTabs] = useState('details')
  const [error, setError] = useState('')
  const [packageName, setPackageName] = useState('')
  const [userPackageDetails, setUserPackageDetails] = useState('')
  const [packageList, setPackageList] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [isChanged, setChanged] = useState(false)
  const location = useLocation()

  function UpdateUserInfoHandler () {
    if (
      mobNum !== null &&
      username !== null &&
      emailId !== null &&
      businessName !== null &&
      cityName !== null &&
      stateName !== null &&
      dtMobNum !== null 
    ) {
      setLoading(true)
      axios
        .post(
          '/api/admin/users/update/' + props.user.id,
          {
            mobile: mobNum,
            name: username,
            email: emailId,
            businessName: businessName,
            city: cityName,
            state: stateName,
            mpin: mpin,
            password: password,
            distMob: dtMobNum,
            isRecharge: isRecharge === false ? '0':'1',
            isDMT: isDMT === false ? '0':'1',
            isAeps: isAEPS === false ? '0': '1',
            ismatm: ismatm === false ? '0' : '1',
            aepsId: aepsId
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setError(data.message)
              window.location.reload()
              searchUserDetails();
            } else {
              setError(data.message)
            }
          }
        })
        .catch(error => {
          setLoading(false)
          setError(error)
        })
    } else {
      setError('Empty Details')
    }
  }


  function searchUserDetails(){
    if (location.state.mobile !== null && props.user.id !== null) {
      setLoading(true)
      axios
        .get(
          '/api/admin/users/' + location.state.mobile + '/' + props.user.id,
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            setName('')
            setMobNum('')
            setEmailId('')
            setBusinessName('')
            setIpAddress('')
            setCityName('')
            setStateName('')
            setAepsId('')
            setError('')
            console.log(res)
            if (data.status === 1) {
              setLoading(false)
              setName(data.userListDetail[0].name)
              setMobNum(data.userListDetail[0].mobile)
              setEmailId(data.userListDetail[0].email)
              setBusinessName(data.userListDetail[0].businessName)
              setIpAddress(data.userListDetail[0].ip)
              setCityName(data.userListDetail[0].city)
              setStateName(data.userListDetail[0].state)
              setDtMoNum(data.userListDetail[0].createdBy)
              setCreatedAt(data.userListDetail[0].createAt)
              setLastLogin(data.userListDetail[0].lastLogin)
              setRecharge(
                data.userListDetail[0].is_recharge === '0' ? false : true
              )
              setDMT(data.userListDetail[0].is_dmr === '0' ? false : true)
              setAEPS(data.userListDetail[0].is_aeps === '0' ? false : true)
              setmatm(data.userListDetail[0].is_matm === '0' ? false : true)
              setAepsId(data.userListDetail[0].aepsId)

              if (data.packageDetails.length > 0) {
                setPackageName(data.userListDetail[0].package)
                setUserPackageDetails(data.packageDetails)
              } else {
                setPackageName('Default')
                axios
                  .post(
                    '/api/admin/package/details/' + props.user.id,
                    {
                      packageName: 'Default'
                    },
                    {
                      headers: { Authorization: props.user.token }
                    }
                  )
                  .then(res => {
                    if (res.status === 200) {
                      const resData = res.data

                      if (resData.status === 1 || resData.status === '1') {
                        console.log(res)
                        setUserPackageDetails(resData.packageReport)
                      } else {
                        setUserPackageDetails([])
                        alert(resData.message)
                        if (resData.message === 'Session Expire') {
                          window.location.reload()
                        }
                      }
                    }
                  })
                  .catch(e => {
                    alert(e)
                  })
              }
              setPackageList(
                data.packageList !== undefined && data.packageList !== null
                  ? data.packageList
                  : ''
              )
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          alert(error)
        })
    }
  }
  

  function getPackageDetails (packageName) {
    if (packageName !== null) {
      setPackageName(packageName)
      axios
        .post(
          '/api/admin/package/details/' + props.user.id,
          {
            packageName: packageName
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const resData = res.data

            if (resData.status === 1 || resData.status === '1') {
              console.log(res)
              setChanged(true)
              setUserPackageDetails(resData.packageReport)
            } else {
              setUserPackageDetails([])
              alert(resData.message)
              if (resData.message === 'Session Expire') {
                window.location.reload()
              }
            }
          }
        })
        .catch(e => {
          alert(e)
        })
    }
  }

  function setNewPackage () {
    var conf = window.confirm('Are sure want to change package for this user.')

    if (conf) {
      axios
        .post(
          '/api/admin/package/set/' + props.user.id,
          {
            packageName: packageName,
            userId: mobNum
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const resData = res.data

            if (resData.status === 1 || resData.status === '1') {
              console.log(res)
              setChanged(false)
              alert(resData.message)
            } else {
              alert(resData.message)
              if (resData.message === 'Session Expire') {
                window.location.reload()
              }
            }
          }
        })
        .catch(e => {
          alert(e)
        })
    }
  }

  useEffect(() => {
    if (location.state.mobile !== null && props.user.id !== null) {
      setLoading(true)
      axios
        .get(
          '/api/admin/users/' + location.state.mobile + '/' + props.user.id,
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            setName('')
            setMobNum('')
            setEmailId('')
            setBusinessName('')
            setIpAddress('')
            setCityName('')
            setStateName('')
            setAepsId('')
            console.log(res)
            if (data.status === 1) {
              setLoading(false)
              setName(data.userListDetail[0].name)
              setMobNum(data.userListDetail[0].mobile)
              setEmailId(data.userListDetail[0].email)
              setBusinessName(data.userListDetail[0].businessName)
              setIpAddress(data.userListDetail[0].ip)
              setCityName(data.userListDetail[0].city)
              setStateName(data.userListDetail[0].state)
              setDtMoNum(data.userListDetail[0].createdBy)
              setCreatedAt(data.userListDetail[0].createAt)
              setLastLogin(data.userListDetail[0].lastLogin)
              setRecharge(
                data.userListDetail[0].is_recharge === '0' ? false : true
              )
              setDMT(data.userListDetail[0].is_dmr === '0' ? false : true)
              setAEPS(data.userListDetail[0].is_aeps === '0' ? false : true)
              setmatm(data.userListDetail[0].is_matm === '0' ? false : true)
              setAepsId(data.userListDetail[0].aepsId)

              if (data.packageDetails.length > 0) {
                setPackageName(data.userListDetail[0].package)
                setUserPackageDetails(data.packageDetails)
              } else {
                setPackageName('Default')
                axios
                  .post(
                    '/api/admin/package/details/' + props.user.id,
                    {
                      packageName: 'Default'
                    },
                    {
                      headers: { Authorization: props.user.token }
                    }
                  )
                  .then(res => {
                    if (res.status === 200) {
                      const resData = res.data

                      if (resData.status === 1 || resData.status === '1') {
                        console.log(res)
                        setUserPackageDetails(resData.packageReport)
                      } else {
                        setUserPackageDetails([])
                        alert(resData.message)
                        if (resData.message === 'Session Expire') {
                          window.location.reload()
                        }
                      }
                    }
                  })
                  .catch(e => {
                    alert(e)
                  })
              }
              setPackageList(
                data.packageList !== undefined && data.packageList !== null
                  ? data.packageList
                  : ''
              )
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          alert(error)
        })
    }
  }, [props, location.state.mobile])

  return (
    <div>
      <div className='container'>
        <div className='Admin-wrap'>
          <div className='NavBarBtnWrap'>
            <div
              style={{ marginRight: '5px' }}
              className={tab !== 'details' ? 'btn' : 'btn btn-primary'}
              onClick={() =>{ 
                searchUserDetails()
                setTabs('details')
                }
                }
            >
              User Details
            </div>
            <div
              className={tab === 'details' ? 'btn' : 'btn btn-primary'}
              onClick={() => setTabs('package')}
            >
              User Package
            </div>
          </div>
          {tab === 'details' ? (
            isLoading ? (
              <div
                className='p-2'
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div className='user-info-box'>
                <div className='heading'>User Details Update</div>
                <div className={error !== '' ? 'AdminError' : ''}>{error}</div>
                <div className='user-info-Details-box'>
                  <div className='user-info-card'>
                    <div>
                      <label className='user-info-label'>Mobile Number</label>
                      <div className='user-info-disable-input'>{mobNum}</div>
                    </div>
                    <div>
                      <label className='user-info-label'>IP Address</label>
                      <div className='user-info-disable-input'>{ipAddress}</div>
                    </div>
                    <div>
                      <label className='user-info-label'>Registration At</label>
                      <div className='user-info-disable-input'>
                        {moment(createdAt).format('YYYY-MM-DD, h:mm:ss a')}
                      </div>
                    </div>
                    <div>
                      <label className='user-info-label'>Last Login</label>
                      <div className='user-info-disable-input'>
                        {moment(lastLogin).format('YYYY-MM-DD, h:mm:ss a')}
                      </div>
                    </div>
                  </div>
                  <form className='user-info-card'>
                    <div>
                      <label className='user-info-label'>User's Name</label>
                      <input
                        type='text'
                        name='name'
                        className='user-info-input-box'
                        placeholder="Enter the User's name"
                        value={username}
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>Business Name</label>
                      <input
                        type='text'
                        name='businessName'
                        className='user-info-input-box'
                        placeholder='Enter the Business Name'
                        value={businessName}
                        onChange={e => setBusinessName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>Email</label>
                      <input
                        type='text'
                        name='emailId'
                        className='user-info-input-box'
                        placeholder='Enter Email Id'
                        value={emailId}
                        onChange={e => setEmailId(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>City Name</label>
                      <input
                        type='text'
                        name='cityName'
                        className='user-info-input-box'
                        placeholder='Enter the City Name'
                        value={cityName}
                        onChange={e => setCityName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>State Name</label>
                      <input
                        type='text'
                        name='stateName'
                        className='user-info-input-box'
                        placeholder='Enter the State Name'
                        value={stateName}
                        onChange={e => setStateName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>Distributor</label>
                      <input
                        type='text'
                        name='dtMobNum'
                        className='user-info-input-box'
                        placeholder='Enter Distributor Mobile No'
                        value={dtMobNum}
                        onChange={e => setDtMoNum(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>MPIN</label>
                      <input
                        type='password'
                        name='mpin'
                        maxLength='4'
                        className='user-info-input-box'
                        placeholder='Enter the Mpin'
                        value={mpin}
                        onChange={e => setMpin(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>Password</label>
                      <input
                        type='password'
                        name='password'
                        className='user-info-input-box'
                        placeholder='Enter the Password'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>Aeps Id</label>
                      <input
                        type='text'
                        name='aepsId'
                        className='user-info-input-box'
                        placeholder='Enter Aeps Id (Optional)'
                        value={aepsId}
                        onChange={e => setAepsId(e.target.value)}
                      />
                    </div>
                    <div className='PickAtService'>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <input
                          value={isRecharge}
                          type='checkbox'
                          checked={isRecharge}
                          onChange={() => {
                            setRecharge(!isRecharge)
                          }}
                        />
                        <span className='subHeading'>Recharge</span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <input
                          value={isDMT}
                          type='checkbox'
                          checked={isDMT}
                          onChange={() => {
                            setDMT(!isDMT)
                          }}
                        />
                        <span className='subHeading'>DMT</span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <input
                          value={isAEPS}
                          type='checkbox'
                          checked={isAEPS}
                          onChange={() => {
                            setAEPS(!isAEPS)
                          }}
                        />
                        <span className='subHeading'>AEPS</span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <input
                          value={ismatm}
                          type='checkbox'
                          checked={ismatm}
                          onChange={() => {
                            setmatm(!ismatm)
                          }}
                        />
                        <span className='subHeading'>MATM</span>
                      </div>
                    </div>
                    <button
                      type='button'
                      id='UserInfoUpdateBtn'
                      name='UserInfoUpdateBtn'
                      className='btn btn-primary'
                      onClick={isLoading ? null : UpdateUserInfoHandler}
                    >
                      {isLoading ? 'Loading..' : 'Update'}
                    </button>
                  </form>
                </div>
              </div>
            )
          ) : (
            <div className='user-package-info-box'>
              <div className='heading'>User Package Details</div>
              {packageList.length > 0 ? (
                <div>
                  <select
                    className='form-control'
                    onChange={e => getPackageDetails(e.target.value)}
                    defaultValue={packageName}
                  >
                    <option value=''>Select Package</option>
                    {packageList.map((list, index) => (
                      <option key={'' + (index + 1)} value={list.packageName}>
                        {list.packageName}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div className='alert alert-default'>
                  No Package available. Please create new package
                </div>
              )}
              {userPackageDetails.length > 0 ? (
                <div>
                  <table className='Commission-table-Box'>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Operator</th>
                        <th>Operator Code</th>
                        <th>Commission</th>
                        <th>Circle</th>
                        <th>Daily Limit</th>
                        <th>Slab Type</th>
                        <th>API Vendor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userPackageDetails.map((packageReport, index) => (
                        <tr key={packageReport.id.toString()}>
                          <td>{index + 1}</td>
                          <td>{packageReport.opt}</td>
                          <td>{packageReport.optCode}</td>
                          {packageReport.agentSlabType !== 'percentage' ? (
                            <td>{packageReport.agentCommission}</td>
                          ) : (
                            <td>{packageReport.agentCommission}%</td>
                          )}
                          <td>{packageReport.circle}</td>
                          <td>{packageReport.dailyLimit}</td>
                          <td>{packageReport.isCommission}</td>
                          <td>{packageReport.vendorName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {isChanged ? (
                    <button
                      className='btn btn-success'
                      onClick={() => setNewPackage()}
                    >
                      Set Package
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <div>
                  <div className='subHeading p-1'>
                    No Package set to user. Default package will work in this
                    case.
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
