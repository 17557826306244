import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

export default function PackageDetails(props) {
  const packageDetails = props.packageCommisionDetails;
  const packageName =
    packageDetails[0] !== undefined ? packageDetails[0].packageName : "";

  console.log("Package" + JSON.stringify(packageDetails[0]));

  const history = useHistory();

  function goto(path) {
    history.push(path, {
      packageName: packageName,
    });
  }

  function delete_operator(optId) {
    var conf = window.confirm("are you sure want to delete this operator");
    if (conf) {
      axios
        .post(
          "/api/admin/package/delete/operator/" + props.user.id,
          {
            optId: optId,
            packageName: packageName,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            alert(data.message);
            window.location.reload();
          }
        })
        .catch((e) => {
          alert(e);
        });
    }
  }

  function changeOperatorDetails(optId, status, key) {
    var conf = window.confirm(
      "are you sure want to update details of this operator"
    );
    if (conf) {
      axios
        .post(
          "/api/admin/package/update/operator/" + props.user.id,
          {
            optId: optId,
            key: key,
            value: status,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            alert(data.message);
            // window.location.reload();
          }
        })
        .catch((e) => {
          alert(e);
        });
    }
  }

  function deletePackage() {
    var conf = window.confirm("are you sure want to delete this package");
    if (conf) {
      axios
        .post(
          "/api/admin/package/delete/" + props.user.id,
          {
            packageName: packageName,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            alert(data.message);
            window.location.reload();
          }
        })
        .catch((e) => {
          alert(e);
        });
    }
  }

  return (
    <div>
      {packageDetails.length > 0 ? (
        <div>
          <div className="packageNavBar pb-1">
            <div className="PackageStatus"> Package: {packageName}</div>
            <div className="btn btn-danger" onClick={() => deletePackage()}>
              Delete Package
            </div>

            <div
              className="btn btn-primary"
              onClick={() => goto("/package/add_operator")}
            >
              Add Operator
            </div>
          </div>
          <div className="UserCommissionDetails">
            <table className="userCommission-table">
              <tr>
                <th>S.No</th>
                <th>Operator</th>
                <th>Operator Code</th>
                <th>Vendor Commission</th>
                <th>Agent Commission</th>
                <th>Circle</th>
                <th>Status</th>
                <th>Lower Cap</th>
                <th>Upper Cap</th>
                <th>Daily Limit</th>
                <th>Slab Type</th>
                <th>Vendor</th>
                <th>Action</th>
              </tr>
              {packageDetails.map((packageReport, index) => (
                <tr key={packageReport.id}>
                  <td>{index + 1}</td>
                  <td>{packageReport.opt}</td>
                  <td>{packageReport.optCode}</td>
                  {packageReport.VendorSlabType !== "percentage" ? (
                    <td>₹{packageReport.vendorCommission}</td>
                  ) : (
                    <td>{packageReport.vendorCommission}%</td>
                  )}
                  {packageReport.agentSlabType !== "percentage" ? (
                    <td>₹{packageReport.agentCommission}</td>
                  ) : (
                    <td>{packageReport.agentCommission}%</td>
                  )}
                  <td>{packageReport.circle}</td>
                  <td>
                    <select
                      className="form-control"
                      style={{ maxWidth: "100px", minWidth: "100px" }}
                      onChange={(e) =>
                        changeOperatorDetails(
                          packageReport.id,
                          e.target.value,
                          "status"
                        )
                      }
                    >
                      <option
                        value="1"
                        selected={
                          packageReport.status === "1" ||
                          packageReport.status === 1
                            ? true
                            : false
                        }
                      >
                        Active
                      </option>
                      <option
                        value="0"
                        selected={
                          packageReport.status === "0" ||
                          packageReport.status === 0
                            ? true
                            : false
                        }
                      >
                        Inactive
                      </option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      style={{ maxWidth: "50px", minWidth: "50px" }}
                      onKeyPress={(e) => {
                        var charCode = "";
                        if (e.key !== undefined) {
                          charCode = e.key;
                        } else if (e.keyIdentifier !== undefined) {
                          charCode = e.keyIdentifier;
                        }

                        if (charCode === 13 || charCode === "Enter") {
                          changeOperatorDetails(
                            packageReport.id,
                            e.target.value,
                            "lowerLimit"
                          );
                        }

                        console.log(charCode);
                      }}
                      defaultValue={packageReport.greaterThan}
                      placeholder="Lower Cap"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      style={{ maxWidth: "50px", minWidth: "50px" }}
                      onKeyPress={(e) => {
                        var charCode = "";
                        if (e.key !== undefined) {
                          charCode = e.key;
                        } else if (e.keyIdentifier !== undefined) {
                          charCode = e.keyIdentifier;
                        }

                        if (charCode === 13 || charCode === "Enter") {
                          changeOperatorDetails(
                            packageReport.id,
                            e.target.value,
                            "upperLimit"
                          );
                        }

                        console.log(charCode);
                      }}
                      defaultValue={packageReport.lessThan}
                      placeholder="Upper Cap"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      style={{ maxWidth: "50px", minWidth: "50px" }}
                      onKeyPress={(e) => {
                        var charCode = "";
                        if (e.key !== undefined) {
                          charCode = e.key;
                        } else if (e.keyIdentifier !== undefined) {
                          charCode = e.keyIdentifier;
                        }

                        if (charCode === 13 || charCode === "Enter") {
                          changeOperatorDetails(
                            packageReport.id,
                            e.target.value,
                            "limit"
                          );
                        }

                        console.log(charCode);
                      }}
                      defaultValue={packageReport.dailyLimit}
                      placeholder="Daily Limit"
                    />
                  </td>
                  <td>{packageReport.isCommission}</td>
                  <td>{packageReport.vendorName}</td>
                  <td>
                    <button
                      className="btn"
                      onClick={() => delete_operator(packageReport.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
