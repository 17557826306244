import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { CircularProgress } from '@mui/material';

export default function TopUpHistory(props) {
  const [topUpList, settopUpList] = useState([]);
  const [stTopUpDate, setStTopUpDate] = useState("");
  const [endTopUpDate, setEndTopUpDate] = useState("");
  const [userId, setUserId] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [topUpLimit, setTopUpLimit] = useState("25");
  const [operatorType, setOperatorType] = useState("");

  function getTopUpHistory(e) {
    e.preventDefault();
    if (props.user.id != null) {
      setLoading(true)
      axios
        .get("/api/admin/report/topUp/" + props.user.id, {
          params: {
            stTopUpDate: stTopUpDate,
            endTopUpDate: endTopUpDate,
            userId: userId,
            operatorType: operatorType,
            topUpLimit: topUpLimit,
          },
          headers: { Authorization: props.user.token },
        })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data;
            console.log(res);
            if (data.status === 1) {
              settopUpList(data.topUpReport);
            } else {
              settopUpList([]);
              setError(data.message);
            }
          }
        })
        .catch((error) => {
          setLoading(false)
          setError(error);
        });
    }
  }
  useEffect(() => {
    if (props.user.id != null) {
      setLoading(true)
      axios
        .get("/api/admin/report/topUp/" + props.user.id, {
          params: {
            stTopUpdate: "",
            endTopUpDate: "",
            userId: "",
            operatorType: "",
            topUpLimit: "25",
          },
          headers: { Authorization: props.user.token },
        })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data;
            console.log(res);
            if (data.status === 1) {
              settopUpList(data.topUpReport);
            } else {
              settopUpList([]);
              setError(data.message);
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === "Session Expire"
          ) {
            localStorage.clear();
            props.setLoggedIn(false);
          }
        })
        .catch((error) => {
          setLoading(false)
          alert(error);
        });
    }
  }, [props]);
  return (
    <div>
      <div className="container">
        <div className="content-wrap">
          <div className="box-shadow-6 p-2">
            <form className="trans-search-wrap">
              <input
                type="date"
                className="form-control"
                id="fromDate"
                name="fromDate"
                onChange={(e) => setStTopUpDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control"
                id="toDate"
                name="toDate"
                onChange={(e) => setEndTopUpDate(e.target.value)}
              />

              <input
                type="text"
                className="form-control"
                id="userId"
                name="userId"
                maxLength="10"
                placeholder="Enter User Id"
                onChange={(e) => setUserId(e.target.value)}
              />
              <select
                className="form-control"
                onChange={(e) => setOperatorType(e.target.value)}
              >
                <option value="">Operator Type</option>
                <option value="System">System</option>
                <option value="PAYMENT GATEWAY">PAYMENT GATEWAY	</option>
                <option value="Distributor">Distributor</option>
              </select>
              <select
                className="form-control"
                onChange={(e) => setTopUpLimit(e.target.value)}
              >
                <option value="">Limit</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="All">All</option>
              </select>

              <div className="form-group">
                <button
                  type="submit"
                  id="requestBtn"
                  name="requestBtn"
                  className="btn btn-primary"
                  onClick={ loading ? null : getTopUpHistory}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className="heading" style={{ marginTop: "50px" }}>
            TopUp History
          </div>
          <div className="Topup-history-card">
          {loading ? (
            <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
          ) : (
            topUpList.length > 0 ? (
              <table className="topup-history-table">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Client Id</th>
                  <th>Username</th>
                  <th>User Id</th>
                  <th>Transaction Type</th>
                  <th>Operator Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Category</th>
                  <th>Remark</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {topUpList.map((transaction, index) => (
                  <tr key={transaction.id.toString()}>
                    <td>{index + 1}</td>
                    <td>{transaction.id}</td>
                    <td>{transaction.name}</td>
                    <td>{transaction.mobile}</td>
                    <td>{transaction.transType}</td>
                    <td>{transaction.operatorName}</td>
                    <td>
                      {moment(transaction.rechargeAt).format(
                        "YYYY-MM-DD h:mm:ss a"
                      )}
                    </td>
                    {transaction.credit !== null &&
                    transaction.credit !== "" ? (
                      <td>₹{transaction.credit}</td>
                    ) : (
                      <td>₹{transaction.debit}</td>
                    )}
                    {transaction.credit !== null &&
                    transaction.credit !== "" ? (
                      <td>Credit</td>
                    ) : (
                      <td>Debit</td>
                    )}
                    <td>{transaction.remark}</td>
                    {transaction.rechargeStatus === "1" ? (
                      <td className="text-success">Success</td>
                    ) : transaction.rechargeStatus === "3" ? (
                      <td className="text-failure">Refunded</td>
                    ) : transaction.rechargeStatus === "2" ? (
                      <td className="text-failure">Failure</td>
                    ) : (
                      <td className="text-pending">Pending</td>
                    )}
                  </tr>
                ))}
                </tbody>
              </table>
            ) : (
              <div className={error !== "" ? "heading p-1" : ""}>{error}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
