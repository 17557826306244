import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

export default function UserInfo (props) {
  const [username, setName] = useState('')
  const [emailId, setEmailId] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [mobNum, setMobNum] = useState('')
  const [ipAddress, setIpAddress] = useState('')
  const [cityName, setCityName] = useState('')
  const [stateName, setStateName] = useState('')
  const [mpin, setMpin] = useState('')
  const [password, setPassword] = useState('')
  const [dtMobNum, setDtMoNum] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [lastLogin, setLastLogin] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.state.mobile !== null && props.user.id !== null) {
      setLoading(true)
      axios
        .get('/api/dt/users/' + location.state.mobile + '/' + props.user.id, {
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            setName('')
            setMobNum('')
            setEmailId('')
            setBusinessName('')
            setIpAddress('')
            setCityName('')
            setStateName('')
            console.log(res)
            if (data.status === 1) {
              setName(data.userListDetail[0].name)
              setMobNum(data.userListDetail[0].mobile)
              setEmailId(data.userListDetail[0].email)
              setBusinessName(data.userListDetail[0].businessName)
              setIpAddress(data.userListDetail[0].ip)
              setCityName(data.userListDetail[0].city)
              setStateName(data.userListDetail[0].state)
              setDtMoNum(data.userListDetail[0].createdBy)
              setCreatedAt(data.userListDetail[0].createAt)
              setLastLogin(data.userListDetail[0].lastLogin)
            } else {
              setError(data.message)
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          setLoading(false)
          setError(error)
        })
    }
  }, [props, location.state.mobile])

  return (
    <div>
      <div className='container'>
        <div className='Admin-wrap'>
          <div className='heading'>User Details</div>

          <div className='user-info-box'>
            {isLoading ? (
              <div
                className='p-2'
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className={error !== '' ? 'AdminError' : ''}>{error}</div>
                <div className='user-info-Details-box'>
                  <div className='user-info-card'>
                    <div>
                      <label className='user-info-label'>Mobile Number</label>
                      <div className='user-info-disable-input'>{mobNum}</div>
                    </div>
                    <div>
                      <label className='user-info-label'>IP Address</label>
                      <div className='user-info-disable-input'>{ipAddress}</div>
                    </div>
                    <div>
                      <label className='user-info-label'>Registration At</label>
                      <div className='user-info-disable-input'>
                        {moment(createdAt).format('YYYY-MM-DD, h:mm:ss a')}
                      </div>
                    </div>
                    <div>
                      <label className='user-info-label'>Last Login</label>
                      <div className='user-info-disable-input'>
                        {moment(lastLogin).format('YYYY-MM-DD, h:mm:ss a')}
                      </div>
                    </div>
                  </div>
                  <form className='user-info-card'>
                    <div>
                      <label className='user-info-label'>User's Name</label>
                      <input
                        type='text'
                        name='name'
                        className='user-info-input-box'
                        placeholder="Enter the User's name"
                        value={username}
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>Business Name</label>
                      <input
                        type='text'
                        name='businessName'
                        className='user-info-input-box'
                        placeholder='Enter the Business Name'
                        value={businessName}
                        onChange={e => setBusinessName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>Email</label>
                      <input
                        type='text'
                        name='emailId'
                        className='user-info-input-box'
                        placeholder='Enter Email Id'
                        value={emailId}
                        onChange={e => setEmailId(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>City Name</label>
                      <input
                        type='text'
                        name='cityName'
                        className='user-info-input-box'
                        placeholder='Enter the City Name'
                        value={cityName}
                        onChange={e => setCityName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>State Name</label>
                      <input
                        type='text'
                        name='stateName'
                        className='user-info-input-box'
                        placeholder='Enter the State Name'
                        value={stateName}
                        onChange={e => setStateName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>Distributor</label>
                      <input
                        type='text'
                        name='dtMobNum'
                        className='user-info-input-box'
                        placeholder='Enter Distributor Mobile No'
                        value={dtMobNum}
                        onChange={e => setDtMoNum(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>MPIN</label>
                      <input
                        type='password'
                        name='mpin'
                        maxLength='4'
                        className='user-info-input-box'
                        placeholder='Enter the Mpin'
                        value={mpin}
                        onChange={e => setMpin(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className='user-info-label'>Password</label>
                      <input
                        type='password'
                        name='password'
                        className='user-info-input-box'
                        placeholder='Enter the Password'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
