import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './complain.css'

export default function Complain (props) {
  const location = useLocation()
  const [rechargeId, setRechargeId] = useState(
    location.query === undefined ? '' : location.query.rechargeId
  )
  const [isNew, setNew] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [complainMsg, setComplainMsg] = useState('')
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [rechargeComplainReport, setRechargeComplainReport] = useState([])


  function complainRaisedBtn (e) {
    e.preventDefault()
    if (
      rechargeId !== null &&
      rechargeId !== '' &&
      complainMsg !== null &&
      complainMsg !== ''
    ) {
      setLoading(true)
      axios
        .post(
          '/api/complain/new/' + props.user.id,
          {
            rechargeId: rechargeId,
            message: complainMsg
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            setRechargeId('')
            setComplainMsg('')
            setLoading(false)
            if (data.status === 1) {
              setNew(true)
              setMessage('Complaint successfully raised')
            } else {
              setMessage(data.message)
            }
          }
        })
        .catch(error => {
          alert(error)
        })
    }
  }

  useEffect(() => {
    if (props.user.id !== null && isNew === true) {
      axios
        .get('/api/complain/view/' + props.user.id, {
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            setNew(false)
            console.log(res)
            if (data.status === 1) {
              setRechargeComplainReport(data.complainReports)
            } else {
              setRechargeComplainReport([])
              setError(data.message)
            }
          }else if(res.data.status === 3 && res.data.message === 'Session Expire'){
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          setError(error)
        })
    }
  }, [props, isNew])
  return (
    <div className='container'>
      <div className='content-wrap'>
        <div className='heading' style={{ marginTop: '50px' }}>
          Complain
        </div>
        <div className='Complain-card'>
          <form className='Complain-form' method='POST'>
          {message !== '' ? <div className='errorClass p-3' style={{textAlign: "center"}}>{message}</div> : ''}
            <input
              type='text'
              className='ComplainInputText'
              id='complain-recharge-id'
              name='complain-recharge-id'
              placeholder='Recharge Id'
              value={rechargeId}
              onChange={e => setRechargeId(e.target.value)}
            />
            <textarea
              className='ComplainInputText'
              cols='50'
              rows='4'
              placeholder='Message'
              onChange={e => setComplainMsg(e.target.value)}
              value={complainMsg}
            ></textarea>
            <div className='form-group'>
              <button
                type='button'
                id='ComplainBtn'
                name='ComplainBtn'
                className='btn btn-primary'
                onClick={isLoading ? null : complainRaisedBtn}
              >
                {isLoading ? 'Processing..' : 'Submit'}
              </button>
              <button
                style={{ marginLeft: '10px' }}
                type='button'
                id='CancelBtn'
                name='CancelBtn'
                className='btn btn-primary'
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
        <div className='heading' style={{ marginTop: '50px' }}>
          Complain View
        </div>
        <div className='Complain-table-card'>
          {rechargeComplainReport.length > 0 ? (
            <table className='Complain-table'>
            <thead>
              <tr>
                <th>Complain Id</th>
                <th>Recharge Id</th>
                <th>Operator</th>
                <th>Mobile No</th>
                <th>Amount</th>
                <th>Recharge Status</th>
                <th>Recharge Date</th>
                <th>Update Date</th>
                <th>Message</th>
                <th>Response</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              {rechargeComplainReport.map((transaction, index) => (
                <tr key={transaction.rechargeId.toString()}>
                  <td>{index + 1}</td>
                  <td>{transaction.rechargeId}</td>
                  <td>{transaction.operator}</td>
                  <td>{transaction.rechargeMobile}</td>
                  <td>{transaction.amount}</td>
                  {transaction.rechargeStatus === '1' ?(
                    <td className='text-success'>Success</td>
                    ) : transaction.rechargeStatus === '3' ? (
                      <td className='text-failure'>Refunded</td>
                    ) : transaction.rechargeStatus === '2' ? (
                      <td className='text-failure'>Failure</td>
                    ) : (
                      <td className='text-pending'>Pending</td>
                    )}
                  <td>{moment(transaction.rechargeAt).format('YYYY-MM-DD h:mm:ss a')}</td>  
                  <td>{moment(transaction.solvedAt).format('YYYY-MM-DD h:mm:ss a')}</td>  
                  <td>{transaction.complainMessage}</td>  
                  <td>{transaction.complainResponse}</td>  
                  {transaction.complainStatus === '1' ?(
                    <td className='text-success'>Success</td>
                    ) : transaction.complainStatus === '3' ? (
                      <td className='text-failure'>Refunded</td>
                    ) : transaction.complainStatus === '2' ? (
                      <td className='text-failure'>Failure</td>
                    ) : (
                      <td className='text-pending'>Pending</td>
                    )}
                </tr>
              ))}
              </tbody>
            </table>
          ) : (
            <div className='heading p-1'>{error}</div>
          )}
        </div>
      </div>
    </div>
  )
}
