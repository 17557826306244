import axios from 'axios'
import React, { useState } from 'react'
import { CircularProgress } from '@mui/material';

export default function CreateUsers(props) {
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [email, setEmail] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [mobNum, setMobNum] = useState('')
  const [pswd, setPswd] = useState('')
 

  function createUserHandler(e) {
    e.preventDefault();
    if (
      name !== '' &&
      businessName !== '' &&
      email !== '' &&
      state !== '' &&
      city !== '' &&
      mobNum !== '' &&
      pswd !== ''
     

    ) {
      setLoading(true)
      axios.post('/api/dt/user/create/' + props.user.id,
        {
          name: name,
          businessName: businessName,
          email: email,
          state: state,
          city: city,
          mobNum: mobNum,
          password: pswd,
        }, {
        headers: { Authorization: props.user.token }
      }).then(res => {
        setLoading(false)
        if (res.status === 200) {
          const data = res.data
          console.log(res)
          setName('')
          setBusinessName('')
          setEmail('')
          setState('')
          setCity('')
          setMobNum('')
          setPswd('')
          if (data.status === 1) {
            alert(data.message)
            setError('User Created Successfully.')
          } else {
            setError(data.message)
          }
        }
      }).catch(error => {
        setLoading(false)
        setError(error)
      })
    } else {
      setError('Please fill all the details.')
    }
  }
  return (
    <div>
      <div className='container'>
        <div className='Admin-wrap'>
          <div className='heading'>Create User</div>
          {isLoading ? (
            <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
          ) : (
          <div className='user-info-box'>
            <div className={error !== '' ? 'AdminError' : ''}>{error}</div>
            <form className='user-create-card' style={{ margin: '0px auto' }} method="POST">
              <input
                type='text'
                name='name'
                className='user-input-box'
                placeholder='Enter Full Name'
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <input
                type='text'
                name='businessName'
                className='user-input-box'
                placeholder='Enter Business Name'
                value={businessName}
                onChange={e => setBusinessName(e.target.value)}
              />
              <input
                type='text'
                name='email'
                className='user-input-box'
                placeholder='Enter Email Id'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <input
                type='text'
                name='mobNum'
                maxLength='10'
                className='user-input-box'
                placeholder='Enter Mobile Number'
                value={mobNum}
                onChange={e => setMobNum(e.target.value)}
              />
              <select
                className='user-input-box'
                onChange={e => setState(e.target.value)}
                value={state}
              >
                <option value=''>Select State</option>
                <option value='Andhra Pradesh'>Andhra Pradesh</option>
                <option value='Arunachal Pradesh'>Arunachal Pradesh</option>
                <option value='Assam'>Assam</option>
                <option value='Bihar'>Bihar</option>
                <option value='Chhattisgarh'>Chhattisgarh</option>
                <option value='Goa'>Goa</option>
                <option value='Gujarat'>Gujarat</option>
                <option value='Haryana'>Haryana</option>
                <option value='Himachal Pradesh'>Himachal Pradesh</option>
                <option value='Jharkhand'>Jharkhand</option>
                <option value='Karnataka'>Karnataka</option>
                <option value='Kerala'>Kerala</option>
                <option value='Madhya Pradesh'>Madhya Pradesh</option>
                <option value='Maharashtra'>Maharashtra</option>
                <option value='Manipur'>Manipur</option>
                <option value='Meghalaya'>Meghalaya</option>
                <option value='Mizoram'>Mizoram</option>
                <option value='Nagaland'>Nagaland</option>
                <option value='Odisha'>Odisha</option>
                <option value='Punjab'>Punjab</option>
                <option value='Rajasthan'>Rajasthan</option>
                <option value='Sikkim'>Sikkim</option>
                <option value='Tamil Nadu'>Tamil Nadu</option>
                <option value='Telangana'>Telangana</option>
                <option value='Tripura'>Tripura</option>
                <option value='Uttarakhand'>Uttarakhand</option>
                <option value='Uttar Pradesh'>Uttar Pradesh</option>
                <option value='West Bengal'>West Bengal</option>
              </select>
              <input
                type='text'
                name='city'
                className='user-input-box'
                placeholder='Enter City Name'
                value={city}
                onChange={e => setCity(e.target.value)}
              />
              <input
                type='password'
                name='password'
                className='user-input-box'
                placeholder='Enter Password'
                value={pswd}
                onChange={e => setPswd(e.target.value)}
              />
              <button
                id='createUserBtn'
                name='createUserBtn'
                className='btn btn-primary'
                onClick={isLoading ? null : createUserHandler}
              >
               Create User
              </button>
            </form>
          </div>)}
        </div>
      </div>
    </div>
  )
}
