import axios from "axios";
import React from "react";

export default function NewAPI(props) {
  const [apiName, setApiName] = React.useState("");
  const [apiRechargeURL, setApiRechargeURL] = React.useState("");
  const [apiWebsite, setApiWebsite] = React.useState("");
  const [apiStatusUrl, setApiStatusUrl] = React.useState("");
  const [apiMethod, setApiMethod] = React.useState("");
  const [errorMessae, setErrorMessae] = React.useState("");
  const [apiCallbackIp, setApiCallbackIp] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);

  function submitFormHandler() {
    if (
      apiMethod !== "" &&
      apiName !== "" &&
      apiRechargeURL !== "" &&
      apiWebsite !== ""
    ) {
      setLoading(true);
      setErrorMessae("");

      axios
        .post(
          "/api/admin/settings/api/addnew/" + props.user.id,
          {
            apiName: apiName,
            apiMethod: apiMethod,
            apiWebsite: apiWebsite,
            apiRechargeURL: apiRechargeURL,
            apiStatusUrl: apiStatusUrl,
            apiCallbackIp: apiCallbackIp,
          },
          { headers: { Authorization: props.user.token } }
        )
        .then((res) => {
          setLoading(false);

          if (res.status === 200) {
            const resData = res.data;
            setErrorMessae(resData.message);
            if (resData.status === 1) {
              setApiName("");
              setApiRechargeURL("");
              setApiWebsite("");
              setApiStatusUrl("");
              setApiMethod("");
              setApiCallbackIp("");
            } else {
              if (resData.message === "Session Expire") {
                window.location.reload();
              }
            }
          } else {
            setErrorMessae(res.statusText);
          }
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessae(error.message);
        });
    } else {
      setErrorMessae("Please fill the form correctly");
    }
  }

  return (
    <div className="box-shadow-6">
      <div className="heading p-2">Add New API</div>
      {errorMessae !== "" ? (
        <div className="pl-2 pr-2 pb-2">
          <div className="alert alert-default">{errorMessae}</div>
        </div>
      ) : (
        ""
      )}
      <div className="pl-2 pr-2 pb-2">
        <form
          method="POST"
          onSubmit={(e) => {
            e.preventDefault();
            submitFormHandler();
          }}
        >
          <div className="pb-2">
            <strong>Note:- </strong>Please enter API details carefully. Wrong
            details can cause system error. It may take 12 hrs to get api
            activated.
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="API Name"
              value={apiName}
              onChange={(e) => setApiName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="API Website"
              value={apiWebsite}
              onChange={(e) => setApiWebsite(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Recharge URL"
              value={apiRechargeURL}
              onChange={(e) => setApiRechargeURL(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Status Check URL (Optional)"
              value={apiStatusUrl}
              onChange={(e) => setApiStatusUrl(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="API Callback IP (Optional)"
              value={apiCallbackIp}
              onChange={(e) => setApiCallbackIp(e.target.value)}
            />
          </div>
          <div className="form-group">
            <select
              className="form-control"
              onChange={(e) => setApiMethod(e.target.value)}
            >
              <option value="">Select Method</option>
              <option value="GET">GET</option>
              <option value="POST">POST</option>
            </select>
          </div>
          <div className="form-group pl-1">
            {!isLoading ? (
              <button className="btn btn-primary">Submit</button>
            ) : (
              <div className="p-2" style={{ color: "blue" }}>
                Processing...
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
