import React, { useEffect, useState } from "react";
import ServiceType from "./ServiceType";
import ElectricityIcon from "@mui/icons-material/TipsAndUpdatesRounded";
import OpacityIcon from "@mui/icons-material/Opacity";
import axios from "axios";
import "../utility.css";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function BillPayForm(props) {
  const [serviceType, setServiceType] = useState("Postpaid");
  const [paramList, setParamList] = useState([]);
  const [operatorList, setOperatorList] = useState([]);
  const [operatorId, setOperatorId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [optNumber, setOptNumber] = useState("");
  const [optNumber2, setOptNumber2] = useState("");
  const [optNumber3, setOptNumber3] = useState("");
  const [latitude, setLatitude] = useState("");
  const [logitude, setLogitude] = useState("");
  const [gateway, setGateway] = useState("");
  const [operatorName, setOperatorName] = useState("");
  const [detail, setDetail] = useState(null);
  const history = useHistory();

  function fetchBillDetails(e) {
    e.preventDefault();
    if (props.user.id !== null) {
      setLoading(true);
      axios
        .post(
          "/api/billpayment/fetch_bill/" + props.user.id,
          {
            operatorId: operatorId,
            billNumber: billNumber,
            optNumber: optNumber,
            optNumber2: optNumber2,
            optNumber3: optNumber3,
            latitude: latitude,
            logitude: logitude,
            ad1_name:
              detail !== null && detail.ad1_name !== null
                ? detail.ad1_name
                : paramList.length > 1 && paramList[1].param_name !== null
                ? paramList[1].param_name
                : "",
            ad2_name:
              detail !== null && detail.ad2_name !== null
                ? detail.ad2_name
                : paramList.length > 2 && paramList[2].param_name !== null
                ? paramList[2].param_name
                : "",
            ad3_name:
              detail !== null && detail.ad3_name !== null
                ? detail.ad3_name
                : paramList.length > 3 && paramList[3].param_name !== null
                ? paramList[3].param_name
                : "",
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            const data = res.data;
            setLoading(false);
            if (data.status === 1) {
              history.push("/BillPay/confirm", {
                user_name: data.consumerName,
                billNumber: data.billNumber,
                bill_amount: data.billAmount,
                dueDate: data.dueDate,
                billDate: data.billDate,
                partPay: data.partPay,
                operatorId: operatorId,
                operator: operatorName,
                serviceType: serviceType,
                ad1_name:
                  detail !== null && detail.ad1_name !== null
                    ? detail.ad1_name
                    : paramList.length > 1 && paramList[1].param_name !== null
                    ? paramList[1].param_name
                    : "",
                ad2_name:
                  detail !== null && detail.ad1_name !== null
                    ? detail.ad1_name
                    : paramList.length > 2 && paramList[2].param_name !== null
                    ? paramList[2].param_name
                    : "",
                ad3_name:
                  detail !== null && detail.ad1_name !== null
                    ? detail.ad1_name
                    : paramList.length > 3 && paramList[3].param_name !== null
                    ? paramList[3].param_name
                    : "",
                optNumber: optNumber,
                optNumber2: optNumber2,
                optNumber3: optNumber3,
              });
            } else if (
              res.data.status === 3 &&
              res.data.message === "Session Expire"
            ) {
              localStorage.clear();
              props.setLoggedIn(false);
            } else {
              setError(data.message);
            }
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }

  function getOperator(service) {
    if (props.user.id !== null) {
      setLoading(true);
      axios
        .post(
          "/api/billpayment/getoperator/" + props.user.id,
          {
            serviceType: service,
            source: "web",
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            const data = res.data;
            setLoading(false);
            if (data.status === 1) {
              setOperatorList(data.list);
              setGateway(data.gateway);
              setDetail(null);
              setParamList([]);
            } else if (
              res.data.status === 3 &&
              res.data.message === "Session Expire"
            ) {
              localStorage.clear();
              props.setLoggedIn(false);
            } else {
              setOperatorList([]);
              setError(
                data.message !== "" && data.message !== {}
                  ? data.message
                  : "error"
              );
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }
  function getOperatorParamList(optId) {
    if (props.user.id !== null) {
      axios
        .post(
          "/api/billpayment/fetch_bill_param/" + props.user.id,
          {
            operatorId: optId,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            const data = res.data;

            if (data.status === 1) {
              setParamList(data.list);
              setDetail(null);
            } else if (
              res.data.status === 3 &&
              res.data.message === "Session Expire"
            ) {
              localStorage.clear();
              props.setLoggedIn(false);
            } else {
              setParamList([]);
              setError(data.message);
            }
          }
        })
        .catch((error) => {
          setError(error);
        });
    }
  }

  useEffect(() => {
    function getoperator() {
      if (props.user.id !== null) {
        setLoading(true);
        axios
          .post(
            "/api/billpayment/getoperator/" + props.user.id,
            {
              serviceType: serviceType,
              source: "web",
            },
            {
              headers: { Authorization: props.user.token },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              console.log(res);
              const data = res.data;
              setLoading(false);
              if (data.status === 1) {
                setOperatorList(data.list);
                setGateway(data.gateway);
              } else if (
                res.data.status === 3 &&
                res.data.message === "Session Expire"
              ) {
                localStorage.clear();
                props.setLoggedIn(false);
              } else {
                setOperatorList([]);
                setError(
                  data.message !== "" && data.message !== {}
                    ? data.message
                    : "error"
                );
                setLoading(false);
              }
            }
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      }
    }
    getoperator();
    navigator.geolocation.getCurrentPosition(function(position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      setLatitude(position.coords.latitude);
      setLogitude(position.coords.longitude);
    });
  }, []);

  return (
    <div className="container">
      <div className="content-wrap">
        <div className="heading">Bill Payment Dashboard</div>
        <div className="utility-services box-shadow-6 p-2">
          <ServiceType
            icon={
              <img src="/postpaid.png" alt="Postpaid" width="32" height="32" />
            }
            setServiceType={setServiceType}
            getOperator={getOperator}
            label={"Postpaid"}
          />
          <ServiceType
            icon={
              <ElectricityIcon sx={{ fontSize: "32px", color: "#666666" }} />
            }
            setServiceType={setServiceType}
            getOperator={getOperator}
            label={"Electricity"}
          />
          <ServiceType
            icon={<img src="/gas_icon.png" alt="Gas" width="32" height="32" />}
            setServiceType={setServiceType}
            getOperator={getOperator}
            label={"Gas"}
          />

          <ServiceType
            icon={
              <img
                src="/insurance-icon.jpeg"
                alt="Insurance"
                width="32"
                height="32"
              />
            }
            setServiceType={setServiceType}
            getOperator={getOperator}
            label={"Insurance"}
          />
          <ServiceType
            icon={<img src="/emi-icon.png" alt="EMI" width="32" height="32" />}
            setServiceType={setServiceType}
            getOperator={getOperator}
            label={"EMI"}
          />
          <ServiceType
            icon={
              <img
                src="/broadband-icon.png"
                alt="Broadband"
                width="32"
                height="32"
              />
            }
            setServiceType={setServiceType}
            getOperator={getOperator}
            label={"Broadband"}
          />
          <ServiceType
            icon={
              <img
                src="/phone-office-icon.png"
                alt="Landline"
                width="32"
                height="32"
              />
            }
            setServiceType={setServiceType}
            getOperator={getOperator}
            label={"Landline"}
          />
          <ServiceType
            icon={<OpacityIcon sx={{ fontSize: "32px", color: "#666666" }} />}
            setServiceType={setServiceType}
            getOperator={getOperator}
            label={"Water"}
          />
        </div>
        {loading ? (
          <div
            className="p-4"
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "100px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            className="box-shadow-6 p-2"
            style={{
              marginTop: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div className="heading">{serviceType + " Payment Details"}</div>
            {error !== "" ? (
              <div className="p-1" style={{ color: "red" }}>
                {error}
              </div>
            ) : (
              ""
            )}
            <form className="utility-services-form">
              <select
                className="form-control"
                //value={operatorName}
                onChange={(e) => {
                  if (
                    e.target.value !== null &&
                    e.target.value !== "" &&
                    e.target.value !== undefined
                  ) {
                    setOperatorName(
                      operatorList[e.target.value].name !== "" &&
                        operatorList[e.target.value].name !== null
                        ? operatorList[e.target.value].name
                        : operatorList[e.target.value].Operator !== "" &&
                          operatorList[e.target.value].Operator !== null
                        ? operatorList[e.target.value].Operator
                        : ""
                    );
                    setOperatorId(
                      operatorList[e.target.value].id !== null &&
                        operatorList[e.target.value].id !== ""
                        ? operatorList[e.target.value].id
                        : operatorList[e.target.value].spKey !== null &&
                          operatorList[e.target.value].spKey !== ""
                        ? operatorList[e.target.value].spKey
                        : ""
                    );
                    setDetail(
                      e.target.value !== "" &&
                        e.target.value !== null &&
                        e.target.value !== undefined &&
                        operatorList[e.target.value].displayname !== null &&
                        operatorList[e.target.value].displayname !== "" &&
                        operatorList[e.target.value].displayname !== undefined
                        ? operatorList[e.target.value]
                        : e.target.value !== "" &&
                          e.target.value !== null &&
                          e.target.value !== undefined &&
                          operatorList[e.target.value].operator !== null &&
                          operatorList[e.target.value].operator !== "" &&
                          operatorList[e.target.value].operator !== undefined
                        ? operatorList[e.target.value]
                        : ""
                    );
                    // console.log(e.target.value);
                    // console.log(operatorList[e.target.value].name);
                    // console.log(operatorList[e.target.value].id);
                    // console.log(operatorName);
                    // console.log(operatorId);
                    // console.log(detail);
                  } else {
                    setOperatorName("");
                    setOperatorId("");
                    setDetail("");
                  }
                  if (gateway === "EKO") {
                    getOperatorParamList(operatorList[e.target.value].id);
                  }
                }}
              >
                <option value="">Select Operator List</option>
                {operatorList.map((h, i) => {
                  return (
                    <option key={i} value={i}>
                      {h.name !== null && h.name !== "" ? h.name : h.Operator}
                    </option>
                  );
                })}
              </select>
              {/* /// PaySprint Service Operator Field [0] ///  */}

              {detail !== null && detail !== "" && detail !== undefined ? (
                /// PaySprint Service Operator Field [0]
                <input
                  type="text"
                  className="form-control"
                  id="billNumber"
                  name="billNumber"
                  placeholder={"Enter " + detail.displayname}
                  value={billNumber}
                  onChange={(e) => setBillNumber(e.target.value)}
                />
              ) : (
                ""
              )}
              {/* /// Eko Service Operator Field [0] ///  */}

              {paramList !== null && paramList.length > 0 ? (
                /// Eko Service Operator Field [0]
                <input
                  type="text"
                  className="form-control"
                  id="billNumber"
                  name="billNumber"
                  placeholder={"Enter " + paramList[0].param_label}
                  value={billNumber}
                  onChange={(e) => setBillNumber(e.target.value)}
                />
              ) : (
                ""
              )}

              {/* /// Quicksun Service Operator Field [0] ///  */}
              {detail !== null && detail !== "" && detail !== undefined ? (
                detail.LabelData !== null &&
                detail.LabelData !== "" &&
                detail.LabelData !== undefined ? (
                  /// Quicksun Service Operator Field [0]
                  <input
                    type="text"
                    className="form-control"
                    id="optNumber"
                    name="optNumber"
                    placeholder={
                      "Enter " + detail.LabelData[0].Labels.split(":")[0]
                    }
                    value={billNumber}
                    onChange={(e) => setBillNumber(e.target.value)}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              {detail !== null && detail !== "" ? (
                ///// PayPrint Service Operator Optional parameter
                <>
                  {detail.ad1_d_name !== null && detail.ad1_d_name !== "" ? (
                    <input
                      type="text"
                      className="form-control"
                      id="optNumber"
                      name="optNumber"
                      placeholder={"Enter " + detail.ad1_d_name}
                      value={optNumber}
                      onChange={(e) => setOptNumber(e.target.value)}
                    />
                  ) : (
                    ""
                  )}
                  {detail.ad2_d_name !== null && detail.ad2_d_name !== "" ? (
                    <input
                      type="text"
                      className="form-control"
                      id="optNumber2"
                      name="optNumber2"
                      placeholder={"Enter " + detail.ad2_d_name}
                      value={optNumber2}
                      onChange={(e) => setOptNumber2(e.target.value)}
                    />
                  ) : (
                    ""
                  )}
                  {detail.ad3_d_name !== null && detail.ad3_d_name !== "" ? (
                    <input
                      type="text"
                      className="form-control"
                      id="optNumber3"
                      name="optNumber3"
                      placeholder={"Enter " + detail.ad3_d_name}
                      value={optNumber3}
                      onChange={(e) => {
                        setOptNumber3(e.target.value);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {detail.LabelData !== undefined &&
                  detail.LabelData !== null &&
                  detail.LabelData !== "" ? (
                    ///// Quicksun Service operator Optional parameter

                    <>
                      {detail["LabelData"] !== undefined ||
                      detail["LabelData"].length > 1 ? (
                        <input
                          type="text"
                          className="form-control"
                          id="optNumber"
                          name="optNumber"
                          placeholder={
                            "Enter " + detail.LabelData[1].Labels.split(":")[0]
                          }
                          value={optNumber}
                          onChange={(e) => setOptNumber(e.target.value)}
                        />
                      ) : (
                        ""
                      )}
                      {detail["LabelData"] !== undefined ||
                      detail["LabelData"].length > 2 ? (
                        <input
                          type="text"
                          className="form-control"
                          id="optNumber2"
                          name="optNumber2"
                          placeholder={
                            "Enter " + detail.LabelData[2].Labels.split(":")[0]
                          }
                          value={optNumber2}
                          onChange={(e) => setOptNumber2(e.target.value)}
                        />
                      ) : (
                        ""
                      )}
                      {detail["LabelData"] !== undefined ||
                      detail["LabelData"].length > 3 ? (
                        <input
                          type="text"
                          className="form-control"
                          id="optNumber3"
                          name="optNumber3"
                          placeholder={
                            "Enter " + detail.LabelData[3].Labels.split(":")[0]
                          }
                          value={optNumber3}
                          onChange={(e) => setOptNumber3(e.target.value)}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : paramList !== null && paramList.length > 1 ? (
                ///// Eko Services Operator optional parameter

                <>
                  {paramList !== null && paramList.length > 1 ? (
                    <input
                      type="text"
                      className="form-control"
                      id="optNumber"
                      name="optNumber"
                      placeholder={"Enter " + paramList[1].param_label}
                      value={optNumber}
                      onChange={(e) => setOptNumber(e.target.value)}
                    />
                  ) : (
                    ""
                  )}
                  {paramList !== null && paramList.length > 2 ? (
                    <input
                      type="text"
                      className="form-control"
                      id="optNumber2"
                      name="optNumber2"
                      placeholder={"Enter " + paramList[2].param_label}
                      value={optNumber}
                      onChange={(e) => setOptNumber2(e.target.value)}
                    />
                  ) : (
                    ""
                  )}
                  {paramList !== null && paramList.length > 3 ? (
                    <input
                      type="text"
                      className="form-control"
                      id="optNumber3"
                      name="optNumber3"
                      placeholder={"Enter " + paramList[3].param_label}
                      value={optNumber3}
                      onChange={(e) => setOptNumber3(e.target.value)}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              <div className="form-group">
                <button
                  type="submit"
                  id="requestBtn"
                  name="requestBtn"
                  className="btn btn-primary"
                  onClick={loading ? null : fetchBillDetails}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
