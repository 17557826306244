import React from 'react'
import './dmt.css'
import WalletDetails from './Wallet/WalletDetails'
import WalletLogin from './Wallet/WalletLogin'

export default function DMT (props) {
  const [wallet, setWallet] = React.useState(localStorage.getItem('wallet'))

  return (
    <div>
      <div className='container'>
        <div className='content-wrap'>
          <div className='heading'>DMT</div>
          {wallet === null ? (
            <WalletLogin setWallet={setWallet} {...props} />
          ) : (
            <WalletDetails wallet={wallet} setWallet={setWallet} {...props} />
          )}
        </div>
      </div>
    </div>
  )
}
