import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import axios from 'axios'

export default function SettleToWallet (props) {
  const { getBalance } = props
  const [response, setResponse] = React.useState('')
  const [error, setError] = React.useState('')
  const [settleAmount, setSettleAmount] = React.useState('')
  const [loading, setLoading] = React.useState('')
  const [txnpin, setTxnpin] = React.useState('')

  function settleToWalletPaymentHandler (e) {
    e.preventDefault()
    if (
      settleAmount !== null &&
      settleAmount !== '' &&
      txnpin !== null &&
      txnpin !== ''
    ) {
      setLoading(true)
      axios
        .post(
          '/api/aeps/settlement/' + props.user.id,
          {
            beneId: '',
            upiId: '',
            upi_name: '',
            amount: settleAmount,
            type: 'ToWallet',
            txnpin: txnpin
          },
          { headers: { Authorization: props.user.token } }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            if (data.status === 1) {
              setLoading(false)
              setResponse(data.message)
              getBalance()
              props.getUser();
              setSettleAmount('')
              setTxnpin('')
            } else if (
              res.data.status === 3 &&
              res.data.message === 'Session Expire'
            ) {
              localStorage.clear()
              props.setLoggedIn(false)
            } else {
              setLoading(false)
              setSettleAmount('')
              setTxnpin('')
              setResponse('')
              setError(data.message)
            }
          } else {
            setLoading(false)
            setSettleAmount('')
            setTxnpin('')
            setResponse('')
            setError(res.message)
          }
          console.log(res)
        })
        .catch(e => {
          setResponse('')
          setError(e.message)
        })
    } else {
      setResponse('Input field is empty')
    }
  }
  return (
    <>
      <div className='mt-1 aeps_card'>
       
            <div className='subHeading p-1'>Transfer to Wallet</div>
            {loading ? (
          <div className='p-2' style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {response !== '' && error === '' ? (
              <div className='subHeading p-1'>
                {response}
              </div>
            ) : response === '' && error !== '' ? (
              <div className='subHeading p-1' style={{color:'red'}}>{error}</div>
            ): ('')}
            <form method='POST' className='wallet_create_form'>
              <input
                type='number'
                className='form-control'
                id='settleAmount'
                name='settleAmount'
                value={settleAmount}
                placeholder='Enter Amount'
                onChange={e => setSettleAmount(e.target.value)}
              />
              <input
                type='password'
                className='form-control'
                name='txnpin'
                value={txnpin}
                placeholder='Transaction Pin'
                onChange={e => setTxnpin(e.target.value)}
              />
              <div className='form-group' style={{ margin: '10px' }}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={settleToWalletPaymentHandler}
                >
                  Transfer To Wallet
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  )
}
