import React from "react";

const BillPayPrintResponse = React.forwardRef((props, ref) => {
  const { location } = props;
  return (
    <div ref={ref}>
      <div className="heading text-center">{location.state.serviceType + " Transaction Reciept"}</div>
      <div className="justify-center p-2">
        {location.state.status === 1 || location.state.status === "1" ? (
          <div className="statusCard" style={{ backgroundColor: "blue" }}>
            <i className="material-icons">check</i>
          </div>
        ) : location.state.status === 2 || location.state.status === "2" ? (
          <div className="statusCard" style={{ backgroundColor: "red" }}>
            <i className="material-icons">close</i>
          </div>
        ) : (
          <div className="statusCard" style={{ backgroundColor: "orange" }}>
            <i className="material-icons">info</i>
          </div>
        )}
      </div>
      <div className="text-center" style={{ fontSize: "18px" }}>
        {location.state.message}
      </div>
      <div style={{ paddingTop: "2rem" }}>
        <table style={{ margin: "auto" }}>
          <tbody>
            <tr>
              <td>Status</td>
              <td>
                {location.state.status === "1" || location.state.status === 1
                  ? "Successful"
                  : location.state.status === "2" || location.state.status === 2
                  ? "Failure"
                  : "Pending"}
              </td>
            </tr>
            <tr>
              <td>Operator Name</td>
              <td>{location.state.operator}</td>
            </tr>
            <tr>
              <td>Username</td>
              <td>{location.state.user_name}</td>
            </tr>
            <tr>
              <td>Bill Number</td>
              <td>{location.state.billNumber}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>₹{location.state.bill_amount}</td>
            </tr>
            {location.state.date !== undefined &&
            location.state.date !== null &&
            location.state.date !== "" ? (
              <tr>
                <td>Date</td>
                <td>₹{location.state.date}</td>
              </tr>
            ) : (
              ""
            )}
            {location.state.trxnId !== null &&
            location.state.trxnId !== "" &&
            location.state.trxnId !== undefined ? (
              <tr>
                <td>Transaction Id</td>
                <td>#{location.state.trxnId}</td>
              </tr>
            ) : (
              ""
            )}
            {location.state.optId !== null &&
            location.state.optId !== "" &&
            location.state.optId !== undefined ? (
              <tr>
                <td>Operator Id</td>
                <td>#{location.state.optId}</td>
              </tr>
            ) : (
              ""
            )}
            {location.state.refId !== null &&
            location.state.refId !== "" &&
            location.state.refId !== undefined ? (
              <tr>
                <td>Reference Id</td>
                <td>{location.state.refId}</td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
});
export default BillPayPrintResponse;

