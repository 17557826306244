import React from 'react'
import ShowAepsBalance from './Withdrawal/ShowAepsBalance'
export default function Aeps (props) {
  return (
    <>
      <div className='container'>
        <div className='content-wrap'>
          <div className='heading'>AEPS</div>
          <ShowAepsBalance {...props} />
        </div>
      </div>
    </>
  )
}
