import { Button } from "@mui/material";
import React, { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import FastagPrintResponse from "./FastagPrintResponse";

export default function FastagResponse(props) {
  const location = useLocation();
  const history = useHistory();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log(location.state.status);

  return props.user.accountType === "RT" ? (
    <div className="container">
      <div className="p-2">
        <div className="bg-white box-shadow-6 p-2">
          <FastagPrintResponse ref={componentRef} location ={location} />
          <div
            style={{
              margin: "20px auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={history.goBack}
            >
              Done
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrint}
            >
              PRINT
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="container">
        <div className="p-2">
          <div className="bg-white box-shadow-6 p-2">
            <h1>Unautherise Access</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
