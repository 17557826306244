import React, { useState } from 'react'
import { ArrowBack } from '@mui/icons-material'
import axios from 'axios'
//0 = Pending verificiation
//1 = LoginSuccess
//2 = Registration required
//5 = Initiated State

export default function WalletLogin (props) {
  const { setWallet } = props
  const [walletStatus, setWalletStatus] = useState(5)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [mobileNum, setMobileNum] = useState('')
  const [otp, setOTP] = useState('')
  const [name, setName] = useState('')
  const [city, setCity] = useState('')
  function mobileNumberCheckHandler (e) {
    e.preventDefault()
    if (mobileNum !== null && mobileNum.length === 10) {
      axios
        .post(
          '/api/sendmoney/login/' + props.user.id,
          {
            number: mobileNum
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            setLoading(false)
            if (data.status === 1) {
              if (data.IsReg === true) {
                setWalletStatus(1)
                localStorage.setItem('wallet', mobileNum)
                setWallet(mobileNum)
              } else {
                setWalletStatus(2)
              }
            } else if (data.status === 0) {
              if (data.IsReg === true) {
                setWalletStatus(0)
              }
            } else {
              setError(data.message)
            }
          }else if(res.data.status === 3 && res.data.message === 'Session Expire'){
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          setError(error)
        })
    } else {
      setError('Please fill the mobile number in the field.')
    }
  }
  function createWalletHandler (e) {
    e.preventDefault()
    if (
      mobileNum !== null &&
      mobileNum.length === 10 &&
      name !== null &&
      city !== null
    ) {
      axios
        .post(
          '/api/sendmoney/createwallet/ ' + props.user.id,
          {
            number: mobileNum,
            name: name,
            city: city
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            if (data.status === 0) {
              setWalletStatus(0)
            } else if(res.data.status === 3 && res.data.message === 'Session Expire'){
            localStorage.clear()
            props.setLoggedIn(false)
          }
          }
        })
        .catch(error => {
          setError(error)
        })
    } else {
      setError(
        'Invalid wallet details.Please fill valid details specially Mobile Number.'
      )
    }
  }
  function verifyOtpHandler (e) {
    e.preventDefault()
    if (otp !== null && otp.length >= 4) {
      axios
        .post(
          '/api/sendmoney/verifyWallet/' + props.user.id,
          {
            number: mobileNum,
            otp: otp
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            if ( data.status === "1" || data.status === 1) {
              if(data.IsReg === true){
                setWalletStatus(1)
                localStorage.setItem('wallet', mobileNum)
                setWallet(mobileNum)
              }else{
                setError(data.message)
              }
            }else{
              setError(data.message)
            }
          }else if(res.data.status === 3 && res.data.message === 'Session Expire'){
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          setError(error)
        })
    } else {
      setError('Otp input is empty.')
    }
  }

  function resendOtpHandler (e) {
    e.preventDefault()
    axios
      .post(
        '/api/sendmoney/resendOtp/' + props.user.id,
        {
          number: mobileNum
        },
        {
          headers: { Authorization: props.user.token }
        }
      )
      .then(res => {
        if (res.status === 200) {
          const data = res.data
          console.log(res)
          if ( data.status === "1" || data.status === 1) {
          } else {
            setError(data.message)
          }
        }else if(res.data.status === 3 && res.data.message === 'Session Expire'){
          localStorage.clear()
          props.setLoggedIn(false)
        }
      })
      .catch(error => {
        setError(error)
      })
  }

  function returnDmtHandler () {
    setName()
    setCity()
    setWalletStatus(5)
  }

  return (
    <div>
      <div className='wallet_card'>
        <div className='subHeading p-1'>
          {walletStatus === 5 ? (
            'Customer Login'
          ) : walletStatus === 2 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <button className='arrowBtn' onClick={returnDmtHandler}>
                <ArrowBack />
              </button>
              <div className='subHeading'>Create Wallet</div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start'
              }}
            >
              {' '}
              <button
                className='arrowBtn'
                onClick={returnDmtHandler}
              >
                <ArrowBack />
              </button>
              <div className='subHeading'>Verify By OTP</div>
            </div>
          )}
        </div>
        {error ? <div className='subHeading p-1 error-color'>{error}</div> : ''}
        <form method='POST' className='wallet_create_form'>
          <input
            type='text'
            className='form-control'
            id='mobileNum'
            name='mobileNum'
            disabled={walletStatus !== 5}
            value={mobileNum}
            placeholder='Enter Mobile Number'
            maxLength='10'
            onChange={e => setMobileNum(e.target.value)}
          />
          {walletStatus === 2 ? (
            <>
              <input
                type='text'
                className='form-control'
                value={name}
                placeholder='Enter Name'
                onChange={e => setName(e.target.value)}
              />
              <input
                type='text'
                className='form-control'
                value={city}
                placeholder='City'
                onChange={e => setCity(e.target.value)}
              />
            </>
          ) : walletStatus === 0 ? (
            <input
              type='number'
              className='form-control'
              value={otp}
              placeholder='Enter Otp'
              onChange={e => setOTP(e.target.value)}
            />
          ) : (
            ''
          )}
          <div className='form-group'>
            <button
              type='submit'
              id='create-wallet-btn'
              name='create-wallet-btn'
              className='btn btn-primary'
              onClick={
                walletStatus === 5
                  ? mobileNumberCheckHandler
                  : walletStatus === 2
                  ? createWalletHandler
                  : walletStatus === 0
                  ? verifyOtpHandler
                  : null
              }
            >
              {isLoading ? 'Loading....' : 'Submit'}
            </button>
            {walletStatus === 0 ? (
              <button
                type='submit'
                id='create-wallet-btn'
                name='create-wallet-btn'
                className='btn btn-danger'
                onClick={isLoading ? null : resendOtpHandler}
                style={{ marginLeft: '10px' }}
              >
                Resend OTP
              </button>
            ) : (
              ''
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
