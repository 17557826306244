import React from "react";
import APIList from "./screens/APIList";
import NewAPI from "./screens/NewAPI";

export default function ApiConfig(props) {
  const [tab, setTab] = React.useState("list");

  return (
    <div className="container">
      <div className="Admin-wrap">
        <div className="NavBarBtnWrap">
          <div
            className={tab === "list" ? "btn btn-primary" : "btn"}
            onClick={() => setTab("list")}
          >
            API List
          </div>
          <div
            className={tab === "new" ? "btn btn-primary" : "btn"}
            onClick={() => setTab("new")}
          >
            Add New API
          </div>
        </div>

        {tab === "list" ? (
          <APIList user={props.user} />
        ) : (
          <NewAPI user={props.user} />
        )}
      </div>
    </div>
  );
}
