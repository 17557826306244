import axios from 'axios'
import React, {useEffect} from 'react'

export default function ResponseParam(props) {
    const [errorMessage, setErrorMessage] = React.useState('')
    const [responseParam, setResponseParam] = React.useState([])

    useEffect(() => {
        if(props.user.id !== null){
            axios.get("/api/admin/settings/api/response/param/" + props.user.id,{
              params: {
                    apiId: props.apiId
              },
                headers: {Authorization: props.user.token}
            }).then((res)=>{
                if(res.status === 200){
                    const data = res.data
                    console.log(res)
                    if(data.status === 1){
                        setResponseParam(data.responseParamList)

                    }else{
                        setResponseParam([])
                        setErrorMessage(data.message)
                    }
                }
            }).catch((e)=> {
                setErrorMessage(e)
            })
        }
       
    }, [props])
    return (
        <div className="box-shadow-6">
            <div className="heading p-2">Response Parameter</div>
            {errorMessage !== "" ? (
        <div className="pl-2 pr-2 pb-2">
          <div className="alert alert-default">{errorMessage}</div>
        </div>
      ) : (
        ""
      )}

      {responseParam.length > 0 ? (
        <div>
          <table className="trans-report-table">
          <thead>
            <tr>
              <th>S.N</th>
              <th>API ID</th>
              <th>Name</th>
              <th>Type</th>
           
            </tr>
            </thead>
            <tbody>
            {responseParam.map((list, index) => (
              <tr key={list.id}>
                <td>{index + 1}</td>
                <td>{list.api_id}</td>
                <td>{list.param_name}</td>
                <td>{list.paramType}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
        </div>
    )
}
