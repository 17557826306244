import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import Header from './Header/Header'
import MobileHeader from './Header/MobileHeader'

export default function ProtectedPage (props) {
  const Page = props.page
  const [isLoggedIn, setLoggedIn] = useState(
    localStorage.getItem('user') !== null ? true : false
  )

  const [balance, setBalance] = useState(0)
  const [accountType, setAccType] = useState('')

  const [user, setUser] = useState(
    localStorage.getItem('user') !== null
      ? JSON.parse(localStorage.getItem('user'))
      : ''
  )

  const getUser = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const token = user.token

    if (token !== null && token !== undefined && token !== '') {
      axios
        .get('/api/user/' + user.id, { headers: { Authorization: token } })
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setUser(data.user)
              setBalance(data.balance)
              setAccType(data.user.accountType)
            } else {
              alert(data.message)
              localStorage.clear()
              setLoggedIn(false)
            }
          } else {
            localStorage.clear()
            setLoggedIn(false)
          }
        })
        .catch(error => {
          alert(error)
        })
    } else {
      setLoggedIn(false)
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      getUser()
    }
  }, [isLoggedIn])

  return isLoggedIn ? (
    <div>
      <Header
        balance={balance}
        getUser={getUser}
        setLoggedIn={setLoggedIn}
        accountType={accountType}
      />
      <MobileHeader
        balance={balance}
        setLoggedIn={setLoggedIn}
        accountType={accountType}
      />
      <Page user={user} getUser={getUser} setLoggedIn={setLoggedIn} />
    </div>
  ) : (

    //location.reload()
    // window.location.replace("https://bsrecharge.in/home.html")
   <Redirect to='/logout'></Redirect>
    
  )
}
