import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'


export default function Complaints(props) {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [complaintError, setComplaintError] = useState('')
  const [stDate, setStDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [responseMsg, setResponseMsg] = useState('')
  const [complaintStatus, setComplaintStatus] = useState('')
  const [colToShow, setColToShow] = useState('')
  const [rechargeId, setRechargeId] = useState('')
  const [complaintReport, setComplainReport] = useState([])

  function UpdateComplaintHandle(e, complaintReq) {
    e.preventDefault();
    if (complaintReq.id !== null && complaintReq.id !== undefined && responseMsg !== '' && complaintStatus !== '') {
      axios.post('/api/admin/users/complain/update/' + props.user.id,
        {
          complaintId: complaintReq.id,
          responseMsg: responseMsg,
          complaintStatus: complaintStatus
        },
        {
          headers: { Authorization: props.user.token }
        }
      ).then(res => {
        if (res.status === 200) {
          const data = res.data
          console.log(res)
          setResponseMsg('')
          setComplaintStatus('')
          if (data.status === 1) {
            alert(data.message)
            complaintSearchHandler(e);
          } else {
            alert(data.message)
          }
        }
      }).catch(error => {
        alert(error)
      })
    } else {
      alert('Empty Details and filled all details properly.')
    }
  }


  function complaintSearchHandler(e) {
    e.preventDefault()
    if (stDate !== '' && stDate !== null && endDate !== undefined && endDate !== '' && endDate !== null) {
      setLoading(true)
      axios
        .get(
          '/api/admin/users/complain/list/' + props.user.id, {
          params: {
            rechargeId: rechargeId,
            stDate: stDate,
            endDate: endDate
          },
          headers: { Authorization: props.user.token }
        }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            setLoading(false)
            if (data.status === 1) {
              setComplainReport(data.complaintList)
            } else {
              setComplainReport([])
              setError(data.message)
            }
          }
        })
        .catch(error => {
          alert(error)
        })
    } else {
      setComplaintError('Please fill all entry.')
    }
  }

  useEffect(() => {
    if (props.user.id !== null) {
      axios
        .get('/api/admin/users/complain/list/' + props.user.id, {
          params: {
            stDate: '',
            endDate: '',
            rechargeId: ''
          },
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setComplainReport(data.complaintList)
            } else {
              setComplainReport([])
              setError(data.message)
            }
          }else if(res.data.status === 3 && res.data.message === 'Session Expire'){
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          setError(error)
        })
    }
  }, [props])
  return (
    <div className='container'>
      <div className='content-wrap'>
        <div className='heading' style={{ marginTop: '50px' }}>
          Complaints Search
        </div>
        <div className='Complain-search-card'>
          <div className={complaintError ? 'error' : ''}>{complaintError}</div>
          <form className='complaint-search-form' method='GET'>
            <input
              type='date'
              className='form-control'
              id='stDate'
              name='stDate'
              placeholder='Enter Date'
              value={stDate}
              onChange={(e) => setStDate(e.target.value)}
            />
            <input
              type='date'
              className='form-control'
              id='endDate'
              name='endDate'
              placeholder='Enter Date'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <input
              type='text'
              className='form-control'
              id='rechargeId'
              name='rechargeId'
              placeholder='Enter Recharge Id'
              value={rechargeId}
              onChange={(e) => setRechargeId(e.target.value)}
            />
            <div className='form-group'>
              <button
                type='submit'
                id='ComplaintSearchBtn'
                name='ComplaintSearchBtn'
                className='btn btn-primary'
                onClick={isLoading ? null : complaintSearchHandler}
              >
                {isLoading ? 'Loading...' : 'Search'}
              </button>
            </div>

          </form>
        </div>
        <div className='heading' style={{ marginTop: '50px' }}>
          Complaints List
        </div>
        <div className='Complain-table-card'>
          {complaintReport.length > 0 ? (
            <table className='Complain-table'>
              <tr>
                <th>Complain Id</th>
                <th>username</th>
                <th>Recharge Id</th>
                <th>Operator</th>
                <th>Mobile No</th>
                <th>Amount</th>
                <th>Recharge Status</th>
                <th>Recharge Date</th>
                <th>Update Date</th>
                <th>Message</th>
                <th>Response</th>
                <th>Status</th>
              </tr>
              {complaintReport.map((complainList, index) => (
                <tbody>
                  <tr key={complainList.id.toString()} onClick={() => { colToShow === complainList.id ? setColToShow('') : setColToShow(complainList.id); }}>
                    <td>{index + 1}</td>
                    <td>{complainList.name}</td>
                    <td>{complainList.rechargeId}</td>
                    <td>{complainList.operator}</td>
                    <td>{complainList.rechargeMobile}</td>
                    <td>{complainList.amount}</td>
                    {complainList.rechargeStatus === '1' ? (
                      <td className='text-success'>Success</td>
                    ) : complainList.rechargeStatus === '3' ? (
                      <td className='text-failure'>Refunded</td>
                    ) : complainList.rechargeStatus === '2' ? (
                      <td className='text-failure'>Failure</td>
                    ) : (
                            <td className='text-pending'>Pending</td>
                          )}
                    <td>{moment(complainList.rechargeAt).format('YYYY-MM-DD h:mm:ss a')}</td>
                    {complainList.solvedAt !== null ? (
                      <td>{moment(complainList.solvedAt).format('YYYY-MM-DD h:mm:ss a')}</td>
                    ) : (<td>{complainList.solvedAt}</td>)}
                    <td>{complainList.complainMessage}</td>
                    <td>{complainList.complainResponse}</td>
                    {complainList.complainStatus === '1' ? (
                      <td className='text-success'>Success</td>
                    ) : complainList.complainStatus === '3' ? (
                      <td className='text-failure'>Refunded</td>
                    ) : complainList.complainStatus === '2' ? (
                      <td className='text-failure'>Failure</td>
                    ) : (
                            <td className='text-pending'>Pending</td>
                          )}
                  </tr>
                  {colToShow === complainList.id ? <tr>

                    <td colSpan="12" style={{ textAlign: 'left' }}>
                      {complainList.complainStatus === '1' ? (
                        <div>Success | Remark:- {complainList.complainResponse} </div>
                      ) : complainList.complainStatus === '2' ? (
                        <div>Failure | Remark:- {complainList.complainResponse}</div>
                      ) : (
                            <form method="POST">
                              <input type='text' id='responseMsg' name='responseMsg' className='inputEditbox' placeholder='Remark' value={responseMsg} onChange={e => setResponseMsg(e.target.value)} />

                              <select
                                className='inputEditbox'
                                onChange={e => setComplaintStatus(e.target.value)}
                                value={complaintStatus}
                              >
                                <option value=''>Select Request</option>
                                <option value='1'>Success</option>
                                <option value='2'>Reject</option>
                              </select>


                              <button className='btn btn-primary' style={{ margin: ' 0px 10px', padding: '8px 20px' }} onClick={(e) => { UpdateComplaintHandle(e, complainList); }}>Update</button>
                            </form>)

                      }
                    </td>

                  </tr> : ''}
                </tbody>
              ))}

            </table>
          ) : (

              <div className='heading p-1'>{error}</div>

            )}

        </div>
      </div>
    </div>
  )
}

