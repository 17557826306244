import axios from "axios";
import React, { useState, useEffect } from "react";
import "./apiConfig.css";

export default function ApiConfig(props) {
  var [openEditCallBackUrlBox, setEditCallBackUrlBox] = useState(false);
  var [openEditIpAddressBox, setEditIpAddressBox] = useState("");
  var [openEditApiKeyBox, setEditApiKeyBox] = useState("");
  var [callBackUrl, setCallBackUrl] = useState("");
  var [callBackInput, setCallBackInput] = useState("");
  var [ipAddress, setIpAddress] = useState("");
  var [ipAddressInput, setIpAddressInput] = useState("");
  var [generateApiKey, setGenerateApiKey] = useState("");
  const [callBackError, setCallBackError] = useState("");
  const [ipAddressError, setIpAddressError] = useState("");
  const [apiKeyError, setApiKeyError] = useState("");
  const [tab, setTabs] = useState("docs");
  const [isCallBackLoading, setCallBackLoading] = useState(false);
  const [isIpAddressLoading, setIpAddressLoading] = useState(false);
  const [isApiKeyLoading, setApiKeyLoading] = useState(false);

  function EditCallBackUrl() {
    setCallBackInput(callBackUrl);
    setCallBackUrl("");
    setEditCallBackUrlBox(!openEditCallBackUrlBox);
  }
  function EditIpAddress() {
    setIpAddressInput(ipAddress);
    setIpAddress("");
    setEditIpAddressBox(!openEditIpAddressBox);
  }
  function EditApiKey() {
    const conf = window.confirm("Are you sure want to re-generate Api key ?");
    if (conf) {
      setGenerateApiKey("");
      setEditApiKeyBox(!openEditApiKeyBox);
    } else {
      setGenerateApiKey(generateApiKey);
    }
  }

  const sample = {
    userId: "9000000000",
    apiKey: "189399jc9sj9aq3",
    service: "Prepaid/Postpaid/DTH",
    mobile: "mobile/DTH Id",
    optCode: "VI/Airtel/Jio/BSNL-TOPUP/BSNL-STV/Dish TV/Airtel Digital TV",
    amount: "10-5000",
    clientId: "RechargeId from database",
  };

  function CallBackUrlHandler(e) {
    e.preventDefault();
    if (callBackInput !== "" && callBackInput !== null) {
      setCallBackLoading(true);
      axios
        .post(
          "/api/client/callbackUrl/" + props.user.id,
          {
            callBackUrl: callBackInput,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            setCallBackLoading(false);
            if (data.status === 1) {
              setCallBackUrl(callBackInput);
              setCallBackInput("");
              setCallBackError(data.message);
            } else {
              setCallBackError(data.message);
            }
          }
        })
        .catch((error) => {
          setCallBackError(error);
        });
    } else {
      setCallBackError("Empty Details and fill all details properly");
    }
  }

  function IPAddressHandler(e) {
    e.preventDefault();
    if (ipAddressInput !== "" && ipAddressInput !== null) {
      setIpAddressLoading(true);
      axios
        .post(
          "/api/client/ip/" + props.user.id,
          {
            ipAddress: ipAddressInput,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            setIpAddressLoading(false);
            if (data.status === 1) {
              setIpAddressInput("");
              setIpAddress(ipAddressInput);
              setIpAddressError(data.message);
            } else {
              setIpAddressError(data.message);
            }
          }
        })
        .catch((error) => {
          setIpAddressError(error);
        });
    } else {
      setIpAddressError("Empty Details and fill all details properly");
    }
  }

  function GenerateApiKeyHandler(e) {
    e.preventDefault();
    setApiKeyLoading(true);
    axios
      .get("/api/client/generatekey/" + props.user.id, {
        headers: { Authorization: props.user.token },
      })
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          console.log(res);
          setApiKeyLoading(false);
          if (data.status === 1) {
            setGenerateApiKey(data.apiKey);
            setApiKeyError(data.message);
          } else {
            setApiKeyError(data.message);
          }
        }
      })
      .catch((error) => {
        setApiKeyError(error);
      });
  }

  useEffect(() => {
    if (props.user.id !== null) {
      axios
        .get("/api/client/details/" + props.user.id, {
          headers: { Authorization: props.user.token },
        })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            if (data.status === 1) {
              setCallBackUrl(data.callBackUrl);
              setIpAddress(data.ipAddress);
              setGenerateApiKey(data.generateApiKey);
            }
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [props]);

  return (
    <div className="container">
      <div className="Admin-wrap">
        <div className="Api_Config_Tab">
          <div
            onClick={() => setTabs("docs")}
            style={{ marginRight: "5px" }}
            className={tab !== "docs" ? "btn" : "btn btn-primary"}
          >
            API Document
          </div>
          <div
            className={tab === "docs" ? "btn" : "btn btn-primary"}
            onClick={() => setTabs("api-config")}
          >
            API Configuration
          </div>
        </div>
        {tab !== "docs" ? (
          <div className="box-shadow-6 p-1" style={{ borderRadius: "8px" }}>
            <div className="heading">Set Callback URL</div>
            <div className="p-1">
              <div className={callBackError ? "error" : ""}>
                {callBackError}
              </div>
              {callBackUrl !== "" && callBackUrl !== null ? (
                <div className="Api_callBackUrlbox">
                  <div className="api_callBackUrl_detail">{callBackUrl}</div>

                  <button
                    id="EditCallBackUrl_btn"
                    name="EditCallBackUrl_btn"
                    className="btn btn-primary"
                    style={{ margin: "0px 10px" }}
                    onClick={EditCallBackUrl}
                  >
                    Edit
                  </button>
                </div>
              ) : (
                <form className="Api_form">
                  <input
                    className="form-control"
                    id="callBackUrl"
                    name="callBackUrl"
                    placeholder="Set CallBack Url"
                    value={callBackInput}
                    onChange={(e) => setCallBackInput(e.target.value)}
                  />
                  <div className="form-group">
                    <button
                      type="submit"
                      id="urlSetBtn"
                      name="urlSetBtn"
                      className="btn btn-primary"
                      onClick={isCallBackLoading ? null : CallBackUrlHandler}
                    >
                      {isCallBackLoading ? "loading..." : "Set"}
                    </button>
                  </div>
                </form>
              )}
            </div>
            <div className="heading">Set IP Address</div>
            <div className="p-1">
              <div className={ipAddressError ? "error" : ""}>
                {ipAddressError}
              </div>
              {ipAddress !== "" && ipAddress !== null ? (
                <div className="Api_ipAddressbox">
                  <div className="api_ipAddress_detail">{ipAddress}</div>

                  <button
                    id="EditIpAddress_btn"
                    name="EditIpAddress_btn"
                    className="btn btn-primary"
                    style={{ margin: "0px 10px" }}
                    onClick={EditIpAddress}
                  >
                    Edit
                  </button>
                </div>
              ) : (
                <form className="Api_form">
                  <input
                    className="form-control"
                    id="IP_Address"
                    name="IP_Address"
                    placeholder="Set Api Address"
                    value={ipAddressInput}
                    onChange={(e) => setIpAddressInput(e.target.value)}
                  />
                  <div className="form-group">
                    <button
                      type="submit"
                      id="ipSetBtn"
                      name="ipSetBtn"
                      className="btn btn-primary"
                      onClick={isIpAddressLoading ? null : IPAddressHandler}
                    >
                      {isIpAddressLoading ? "loading..." : "Set"}
                    </button>
                  </div>
                </form>
              )}
            </div>
            <div className="heading">Generate API Key</div>
            <div className="p-1">
              <div className={apiKeyError ? "error" : ""}>{apiKeyError}</div>

              {generateApiKey !== "" && generateApiKey !== null ? (
                <div className="Api_generateApiKeybox">
                  <div className="api_generateApiKey_detail">
                    {generateApiKey}
                  </div>

                  <button
                    id="EditApiKey_btn"
                    name="EditApiKey_btn"
                    className="btn btn-primary"
                    style={{ margin: "0px 10px" }}
                    onClick={EditApiKey}
                  >
                    Re-generate API Key
                  </button>
                </div>
              ) : (
                <form className="Api_form">
                  <div className="form-group">
                    <button
                      type="submit"
                      id="keySetBtn"
                      name="keySetBtn"
                      className="btn btn-primary"
                      onClick={isApiKeyLoading ? null : GenerateApiKeyHandler}
                    >
                      {isApiKeyLoading ? "loading..." : "Generate API Key"}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        ) : (
          <div className="box-shadow-6 p-1" style={{ borderRadius: "8px" }}>
            <div className="heading">Recharge</div>
            <div className="Table-Card">
              <div className="tableRowBox">
                <div className="tableColumn">Method</div>
                <div className="tableColumn">URL</div>
              </div>
              <div className="tableRowBox">
                <div className="tableBodyColumn">POST</div>
                <div className="tableBodyColumn">
                  bsrecharge.in/api/client/transaction
                </div>
              </div>
            </div>
            <div className="heading">Request Parameter</div>
            <div className="Table-Card">
              <div className="tableRowBox">
                <div className="tableColumn">Name</div>
                <div className="tableBodyColumn">userId</div>
                <div className="tableBodyColumn">apiKey</div>
                <div className="tableBodyColumn">service</div>
                <div className="tableBodyColumn">mobile</div>
                <div className="tableBodyColumn">optCode</div>
                <div className="tableBodyColumn">amount</div>
                <div className="tableBodyColumn">clientId</div>
              </div>
              <div className="tableRowBox">
                <div className="tableColumn">Description</div>
                <div className="tableBodyColumn">
                  Bsrecharge user Id (mobile number)
                </div>
                <div className="tableBodyColumn">Your API Key</div>
                <div className="tableBodyColumn">
                  Service name (Prepaid, Postpaid, DTH)
                </div>
                <div className="tableBodyColumn">
                  Mobile number Or customer id
                </div>
                <div className="tableBodyColumn">Operator code</div>
                <div className="tableBodyColumn">Recharge amount</div>
                <div className="tableBodyColumn">
                  Recharge reference number of your database
                </div>
              </div>
            </div>

            <div
              style={{
                padding: "10px",
                paddingBottom: "20px",
                marginBottom: "20px",
                background: "#ffc107",
                borderRadius: "10px",
                wordBreak: "break-word",
              }}
            >
              <h5>Sample</h5>
              <code>{JSON.stringify(sample)}</code>
            </div>

            <div className="heading">Response Parameter</div>
            <div className="Table-Card">
              <div className="tableRowBox">
                <div className="tableColumn">Name</div>
                <div className="tableBodyColumn">status</div>
                <div className="tableBodyColumn">amount</div>
                <div className="tableBodyColumn">transId</div>
                <div className="tableBodyColumn">operatorId</div>
                <div className="tableBodyColumn">commission</div>
                <div className="tableBodyColumn">balance</div>
                <div className="tableBodyColumn">remark</div>
              </div>
              <div className="tableRowBox">
                <div className="tableColumn">Description</div>
                <div className="tableBodyColumn">
                  Recharge status (0, 1, 2), Pending, Success, Failure
                </div>
                <div className="tableBodyColumn">Recharge amount</div>
                <div className="tableBodyColumn">Recharge id </div>
                <div className="tableBodyColumn">Recharge operator id</div>
                <div className="tableBodyColumn">Recharge commission</div>
                <div className="tableBodyColumn">Your closing balance</div>
                <div className="tableBodyColumn">
                  Transaction description (Remark)
                </div>
              </div>
            </div>
            <div className="heading">Check Status</div>
            <div className="Table-Card">
              <div className="tableRowBox">
                <div className="tableColumn">Method</div>
                <div className="tableColumn">URL</div>
              </div>
              <div className="tableRowBox">
                <div className="tableBodyColumn">POST</div>
                <div className="tableBodyColumn">
                  bsrecharge.in/api/client/status
                </div>
              </div>
            </div>
            <div className="heading">Request Parameter</div>
            <div className="Table-Card">
              <div className="tableRowBox">
                <div className="tableColumn">Name</div>
                <div className="tableBodyColumn">userId</div>
                <div className="tableBodyColumn">apiKey</div>
                <div className="tableBodyColumn">clientId</div>
              </div>
              <div className="tableRowBox">
                <div className="tableColumn">Description</div>
                <div className="tableBodyColumn">
                  Bsrecharge user Id (mobile number)
                </div>
                <div className="tableBodyColumn">Your API Key</div>
                <div className="tableBodyColumn">
                  Recharge reference number of your database
                </div>
              </div>
            </div>
            <div className="heading">Response Parameter</div>
            <div className="Table-Card">
              <div className="tableRowBox">
                <div className="tableColumn">Name</div>
                <div className="tableBodyColumn">mobile</div>
                <div className="tableBodyColumn">operator</div>
                <div className="tableBodyColumn">amount</div>
                <div className="tableBodyColumn">status</div>
                <div className="tableBodyColumn">transId</div>
                <div className="tableBodyColumn">operatorId</div>
                <div className="tableBodyColumn">commission</div>
                <div className="tableBodyColumn">remark</div>
              </div>
              <div className="tableRowBox">
                <div className="tableColumn">Description</div>
                <div className="tableBodyColumn">
                  Mobile number Or customer id
                </div>
                <div className="tableBodyColumn">Recharge operator name</div>
                <div className="tableBodyColumn">Recharge amount</div>
                <div className="tableBodyColumn">
                  Recharge status (0, 1, 2), Pending, Success, Failure
                </div>
                <div className="tableBodyColumn">Recharge id </div>
                <div className="tableBodyColumn">Recharge operator id</div>
                <div className="tableBodyColumn">Recharge commission</div>
                <div className="tableBodyColumn">
                  Transaction description (Remark)
                </div>
              </div>
            </div>
            <div className="heading">Check Balance</div>
            <div className="Table-Card">
              <div className="tableRowBox">
                <div className="tableColumn">Method</div>
                <div className="tableColumn">URL</div>
              </div>
              <div className="tableRowBox">
                <div className="tableBodyColumn">POST</div>
                <div className="tableBodyColumn">
                  bsrecharge.in/api/client/balance
                </div>
              </div>
            </div>
            <div className="heading">Request Parameter</div>
            <div className="Table-Card">
              <div className="tableRowBox">
                <div className="tableColumn">Name</div>
                <div className="tableBodyColumn">userId</div>
                <div className="tableBodyColumn">apiKey</div>
              </div>
              <div className="tableRowBox">
                <div className="tableColumn">Description</div>
                <div className="tableBodyColumn">
                  Bsrecharge user Id (mobile number)
                </div>
                <div className="tableBodyColumn">Your API Key</div>
              </div>
            </div>
            <div className="heading">Response Parameter</div>
            <div className="Table-Card">
              <div className="tableRowBox">
                <div className="tableColumn">Name</div>
                <div className="tableBodyColumn">status</div>
                <div className="tableBodyColumn">balance</div>
                <div className="tableBodyColumn">remark</div>
              </div>
              <div className="tableRowBox">
                <div className="tableColumn">Description</div>
                <div className="tableBodyColumn">
                  Recharge status (0, 1, 2), Pending, Success, Failure
                </div>
                <div className="tableBodyColumn">Your closing balance</div>
                <div className="tableBodyColumn">
                  Transaction description (Remark)
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
