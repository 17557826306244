import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { CircularProgress } from '@mui/material';

export default function AccountStatement (props) {
  const [stDate, setStDate] = useState('')
  const [startDate, setStartDate] = useState('')
  const [lastDate, setLastDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [number, setNumber] = useState('')
  const [userId, setUserId] = useState('')
  const [closeBalance, setCloseBalance] = useState('')
  const [limit, setLimit] = useState('25')
  const [operator, setOperator] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [statementReportList, setStatementReportList] = useState([])

  function getAccountStatement (e) {
    e.preventDefault()
    setLoading(true)
    axios
      .get('/api/admin/report/statement/' + props.user.id, {
        params: {
          stDate: stDate,
          endDate: endDate,
          number: number,
          userId: userId,
          limit: limit,
          operator: operator
        },
        headers: { Authorization: props.user.token }
      })
      .then(res => {
        if (res.status === 200) {
          setLoading(false)
          const data = res.data
          console.log(res)
          if (data.status === 1) {
            setStatementReportList(data.statementReport)
            setCloseBalance(data.closeBalance)
            setStartDate(data.startDate)
            setLastDate(data.lastDate)
          } else {
            setStatementReportList([])
            setError(data.message)
            setStartDate(data.startDate)
            setLastDate(data.lastDate)
          }
        }
      })
      .catch(error => {
        setLoading(false)
        alert(error)
      })
  }

  useEffect(() => {
    if (props.user.id != null) {
      setLoading(true)
      axios
        .get('/api/admin/report/statement/' + props.user.id, {
          params: {
            stDate: '',
            endDate: '',
            userId: '',
            number: '',
            limit: '25',
            operator: ''
          },
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setStatementReportList(data.statementReport)
              setCloseBalance(data.closeBalance)
              setStartDate(data.startDate)
              setLastDate(data.lastDate)
            } else {
              setStatementReportList([])
              setError(data.message)
              setStartDate(data.startDate)
              setLastDate(data.lastDate)
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          setLoading(false)
          alert(error)
        })
    }
  }, [props])
  return (
    <div className='container'>
      <div className='wrap' style={{ flexDirection: 'column' }}>
      
        <div className='box-shadow-6 p-2'>
          <form className='trans-search-wrap'>
            <input
              type='date'
              className='form-control'
              id='fromDate'
              name='fromDate'
              onChange={e => setStDate(e.target.value)}
            />
            <input
              type='date'
              className='form-control'
              id='toDate'
              name='toDate'
              onChange={e => setEndDate(e.target.value)}
            />
            <input
              type='text'
              className='form-control'
              id='MobNum'
              maxLength='10'
              name='MobNum'
              placeholder='Mobile Number'
              onChange={e => setNumber(e.target.value)}
            />
            <input
              type='text'
              className='form-control'
              id='retailId'
              name='retailId'
              maxLength='10'
              placeholder='User Id'
              onChange={e => setUserId(e.target.value)}
            />
            <input
              type='text'
              className='form-control'
              id='OptrName'
              name='OptrName'
              placeholder='Operator Name'
              onChange={e => setOperator(e.target.value)}
            />
            <select
              className='form-control'
              onChange={e => setLimit(e.target.value)}
            >
              <option value=''>Limit</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='All'>All</option>
            </select>

            <div className='form-group'>
              <button
                type='submit'
                id='requestBtn'
                name='requestBtn'
                className='btn btn-primary'
                onClick={loading ? null : getAccountStatement}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className='heading' style={{ marginTop: '50px' }}>
          Account Statement
        </div>
        <div className='Count-Details'>
          <div className='DateShow'>
            {startDate !== '' ? startDate : 'YYYY-MM-DD'} to{' '}
            {lastDate !== '' ? lastDate : 'YYYY-MM-DD'}
          </div>
          <div className='AccStatementDetails'>
            <div className='ShowBalance' style={{ marginRight: '3px' }}>
              Opening Balance:{' '}
              {statementReportList.length > 0
                ? statementReportList.map((tranx, index) => (
                    <span>
                      {index === statementReportList.length - 1
                        ? tranx.rechargeStatus !== '0' &&
                          tranx.rechargeStatus !== '1'
                          ? tranx.balance
                          : parseInt(tranx.balance) +
                            parseInt(
                              tranx.debit !== null && tranx.debit !== ''
                                ? tranx.debit
                                : '0'
                            ) -
                            parseInt(
                              tranx.credit !== null && tranx.credit !== ''
                                ? tranx.credit
                                : '0'
                            )
                        : ''}
                    </span>
                  ))
                : 0}
            </div>
            <div className='ShowBalance'>
              Closing Balance: {closeBalance !== '' ? closeBalance : '0'}
            </div>
          </div>
        </div>
        <div className='Acc-Statement-Card'>
        {loading ? (
            <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
          ) : (
          statementReportList.length > 0 ? (
            <table className='Acc-statement-table'>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Username</th>
                <th>Date</th>
                <th>Recharge Id</th>
                <th>Transaction Type</th>
                <th>Operator</th>
                <th>Mobile No</th>
                <th>Amount</th>
                <th>Debit</th>
                <th>Commission</th>
                <th>Credit</th>
                <th>Balance</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              {statementReportList.map((transaction, index) => (
                <tr key={transaction.id.toString()}>
                  <td>{index + 1}</td>
                  <td>{transaction.name}</td>
                  <td>
                    {moment(transaction.rechargeAt).format(
                      'YYYY-MM-DD h:mm:ss a'
                    )}
                  </td>
                  <td>{transaction.id}</td>
                  <td>{transaction.transType}</td>
                  <td>{transaction.operatorName}</td>
                  <td>{transaction.rechargeMobile}</td>
                  {transaction.amount !== '' ? (
                    <td>₹{transaction.amount}</td>
                  ) : (
                    <td>0.00</td>
                  )}
                  {transaction.debit !== null ? (
                    <td>₹{transaction.debit}</td>
                  ) : (
                    <td>0.00</td>
                  )}
                  {transaction.commission !== null ? (
                    <td>₹{transaction.commission}</td>
                  ) : (
                    <td>0.00</td>
                  )}
                  {transaction.credit !== null ? (
                    <td>₹{transaction.credit}</td>
                  ) : (
                    <td>0.00</td>
                  )}
                  <td>₹{transaction.balance}</td>
                  {transaction.rechargeStatus === '1' ? (
                    <td className='text-success'>Success</td>
                  ) : transaction.rechargeStatus === '2' ? (
                    <td className='text-failure'>Refunded</td>
                  ) : transaction.rechargeStatus === '3' ? (
                    <td className='text-failure'>Failure</td>
                  ) : (
                    <td className='text-pending'>Pending</td>
                  )}
                </tr>
              ))}
              </tbody>
            </table>
          ) : (
            <div
              className={error !== '' ? 'heading' : ''}
              style={{ padding: '10px 20px' }}
            >
              {error}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
