import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material';

export default function PaymentRequest (props) {
  const [isLoading, setLoading] = useState(false)
  const [stDate, setStDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [limit, setLimit] = useState('')
  const [responseMsg, setResponseMsg] = useState('')
  const [status, setStatus] = useState('')
  const [paymentReport, setPaymentReport] = useState([])
  const [error, setError] = useState('')
  const [colToShow, setColToShow] = useState('')

  function UpdatePaymentReqHandle (e, request) {
    e.preventDefault()
    const confrm = window.confirm(
      'Are you sure want to transfer ' + request.amount + ' to ' + request.name
    )
    if (
      request.id !== null &&
      request.id !== undefined &&
      responseMsg !== '' &&
      confrm
    ) {
      axios
        .post(
          '/api/admin/users/payment/request/update/' + props.user.id,
          {
            requestId: request.id,
            responseMsg: responseMsg,
            requestStatus: status
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            setResponseMsg('')
            setStatus('')
            if (data.status === 1) {
              alert(data.message + '| Available Balance: ' + data.balance)
              searchPaymentReqHandler(e)
            } else {
              alert(data.message)
            }
          } else {
            alert(res.data.message)
          }
        })
        .catch(error => {
          alert(error)
        })
    } else if (confrm) {
      alert('Please fill the form correctly.')
    }
  }

  function searchPaymentReqHandler (e) {
    e.preventDefault()
    if (limit !== null && stDate !== null && endDate !== null) {
      setLoading(true)
      axios
        .get('/api/admin/users/payment/list/' + props.user.id, {
          params: {
            stDate: stDate,
            endDate: endDate,
            limit: limit
          },
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          setLoading(false)
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setPaymentReport(data.paymentList)
            } else {
              setPaymentReport([])
              setError(data.message)
            }
          }
        })
        .catch(error => {
          setLoading(false)
          setError(error)
        })
    } else {
      setError('Empty Details')
    }
  }

  useEffect(() => {
    if (props.user.id !== null) {
      setLoading(true)
      axios.get('/api/admin/users/payment/list/' + props.user.id, {
          params: {
            stDate: '',
            endDate: '',
            limit: '25'
          },
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setPaymentReport(data.paymentList)
            } else {
              setPaymentReport([])
              setError(data.message)
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          setLoading(false)
          setError(error)
        })
    }
  }, [props])

  return (
    <div>
      <div className='container'>
        <div className='content-wrap'>
          <div className='Payment-request-box'>
            <div className='heading'>Payment Request Search</div>
            <div className='Payment-request-card'>
              <form className='Payment-request-form'>
                <input
                  type='date'
                  className='form-control'
                  id='st-payment-date'
                  name='st-payment-date'
                  placeholder='Date'
                  value={stDate}
                  onChange={e => setStDate(e.target.value)}
                />
                <input
                  type='date'
                  className='form-control'
                  id='end-payment-date'
                  name='end-payment-date'
                  placeholder='Date'
                  value={endDate}
                  onChange={e => setEndDate(e.target.value)}
                />
                <select
                  className='form-control'
                  onChange={e => setLimit(e.target.value)}
                  value={limit}
                >
                  <option value=''>Select Limit</option>
                  <option value='25'>25</option>
                  <option value='100'>100</option>
                  <option value='All'>All</option>
                </select>
                <div className='form-group'>
                  <button
                    type='submit'
                    id='SearchBtn'
                    name='SearchBtn'
                    className='btn btn-primary'
                    onClick={isLoading ? null : searchPaymentReqHandler}
                  >
                    {isLoading ? 'Searching...' : 'Search'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='content-wrap'>
          <div className='Payment-Request-detail-box'>
            <div className='heading'>Payment Request List</div>
            {isLoading ? (
              <div className='Payment-Request-detail-card'>
                <div
                  className='p-3'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '100px'
                  }}
                >
                  <CircularProgress />
                </div>
              </div>
            ) : (
              <div className='Payment-Request-detail-card'>
                {paymentReport.length > 0 ? (
                  <table className='Payment-Request-detail-table'>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>user name</th>
                        <th>User Id</th>
                        <th>Date</th>
                        <th>Bank Name</th>
                        <th>Bank Ref(UTR NO)</th>
                        <th>Amount</th>
                        <th>Request Date</th>
                        <th>Update Date</th>
                        <th>Response</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    {paymentReport.map((transaction, index) => (
                      <tbody>
                        <tr
                          key={transaction.id.toString()}
                          onClick={() => {
                            colToShow === transaction.id
                              ? setColToShow('')
                              : setColToShow(transaction.id)
                          }}
                        >
                          <td>{index + 1}</td>
                          <td>{transaction.name}</td>
                          <td>{transaction.mobile}</td>
                          <td>
                            {moment(transaction.paymentDate).format(
                              'YYYY-MM-DD h:mm:ss a'
                            )}
                          </td>
                          <td>{transaction.bankName}</td>
                          <td>{transaction.bankRefNo}</td>
                          <td>{transaction.amount}</td>
                          <td>
                            {moment(transaction.createdAt).format(
                              'YYYY-MM-DD h:mm:ss a'
                            )}
                          </td>
                          {transaction.updatedAt !== null ? (
                            <td>
                              {moment(transaction.updatedAt).format(
                                'YYYY-MM-DD h:mm:ss a'
                              )}
                            </td>
                          ) : (
                            <td>{transaction.updatedAt}</td>
                          )}
                          <td>{transaction.response}</td>
                          {transaction.paymentStatus === '1' ? (
                            <td className='text-success'>Success</td>
                          ) : transaction.paymentStatus === '3' ? (
                            <td className='text-failure'>Refunded</td>
                          ) : transaction.paymentStatus === '2' ? (
                            <td className='text-failure'>Failure</td>
                          ) : (
                            <td className='text-pending'>Pending</td>
                          )}
                        </tr>

                        {colToShow === transaction.id ? (
                          <tr style={{ borderBottom: '1px solid #ccc' }}>
                            <td colSpan='11' style={{ textAlign: 'left' }}>
                              {transaction.paymentStatus === '1' ? (
                                <div>
                                  Success | Remark:- {transaction.response}{' '}
                                </div>
                              ) : transaction.paymentStatus === '2' ? (
                                <div>
                                  Failure | Remark:- {transaction.response}
                                </div>
                              ) : (
                                <form method='POST'>
                                  <input
                                    type='text'
                                    id='responseMsg'
                                    name='responseMsg'
                                    className='inputEditbox'
                                    placeholder='Remark'
                                    value={responseMsg}
                                    onChange={e =>
                                      setResponseMsg(e.target.value)
                                    }
                                  />

                                  <select
                                    className='inputEditbox'
                                    onChange={e => setStatus(e.target.value)}
                                    value={status}
                                  >
                                    <option value=''>Select Request</option>
                                    <option value='1'>Accept</option>
                                    <option value='2'>Reject</option>
                                  </select>

                                  <button
                                    className='btn btn-primary'
                                    style={{
                                      margin: ' 0px 10px',
                                      padding: '8px 20px'
                                    }}
                                    onClick={e => {
                                      UpdatePaymentReqHandle(e, transaction)
                                    }}
                                  >
                                    Update
                                  </button>
                                </form>
                              )}
                            </td>
                          </tr>
                        ) : (
                          ''
                        )}
                      </tbody>
                    ))}
                  </table>
                ) : (
                  <div className='heading p-2'>{error}</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
