import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Homepage.css'
import { Fingerprint, SwapHoriz } from '@mui/icons-material'

export default function UserHome (props) {
  const [totalRechargeSuccessAmount, setRechargeSuccessAmount] = useState(0)
  const [totalDmtSuccessAmount, setDmtSuccessAmount] = useState(0)
  const [totalRechargeSuccessCount, setRechargeSuccessCount] = useState(0)
  const [totalDmtSuccessCount, setDmtSuccessCount] = useState(0)

  const [totalRechargePendingAmount, setRechargePendingAmount] = useState(0)
  const [totalDmtPendingAmount, setDmtPendingAmount] = useState(0)

  const [totalRechargePendingCount, setRechargePendingCount] = useState(0)
  const [totalDmtPendingCount, setDmtPendingCount] = useState(0)

  const [totalRechargeFailedAmount, setRechargeFailedAmount] = useState(0)
  const [totalDmtFailedAmount, setDmtFailedAmount] = useState(0)

  const [totalRechargeFailedCount, setRechargeFailedCount] = useState(0)
  const [totalDmtFailedCount, setDmtFailedCount] = useState(0)

  const [totalCommission, setCommission] = useState(0)
  const [totalCharge, setCharge] = useState(0)

  const [totalRechargeSell, setTotalRechargeSell] = useState(0)
  const [totalDmtSell, setTotalDmtSell] = useState(0)

  const [trList, settrList] = useState([])

  useEffect(() => {
    if (props.user.id != null) {
      axios
        .get('/api/dashboard/' + props.user.id, {
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setRechargeSuccessAmount(data.rechargeTotal.toFixed(2))
              setDmtSuccessAmount(data.dmtTotal.toFixed(2))
              setRechargeSuccessCount(data.rechargeSuccessCount)
              setDmtSuccessCount(data.dmtSuccessCount)

              setRechargePendingAmount(data.rechargePendingAmount.toFixed(2))
              setDmtPendingAmount(data.dmtPendingAmount.toFixed(2))

              setRechargePendingCount(data.rechargePendingCount)
              setDmtPendingCount(data.dmtPendingCount)

              setRechargeFailedAmount(data.rechargeFailedAmount.toFixed(2))
              setDmtFailedAmount(data.dmtFailedAmount.toFixed(2))

              setRechargeFailedCount(data.rechargeFailedCount)
              setDmtFailedCount(data.dmtFailedCount)
              setCommission(
                data.rechargeCommissionEarned !== null
                  ? data.rechargeCommissionEarned.toFixed(2)
                  : 0.0
              )
              setCharge(
                data.dmtCharge !== null
                  ? data.dmtCharge.toFixed(2)
                  : 0.0
              )
              setTotalRechargeSell(data.rechargeTotal.toFixed(2))
              setTotalDmtSell(data.dmtTotal.toFixed(2))
              settrList(data.trReport)
            }
          }
        })
        .catch(error => {
          console.log(error.message)
        })
    }
  }, [props])

  return (
    <div>
      <div className='container'>
        <div className='wrap' style={{ flexDirection: 'column' }}>
          <div className='notificationBar'>
            <span style={{ fontSize: '16px', fontWeight: '500' }}>
              Hi, 
            </span>
             Welcome to Bs Recharge
          </div>
          {props.user.accountType === 'RT' ? (
            <div className='mobileViewMode' style={{marginBottom:'30px'}}>
             
                <div
                  className='p-2'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 'fit-content'
                  }}
                >
                  <Link
                    to='/recharge/mobile'
                    style={{ textDecoration: 'none', color: '#333' }}
                  >
                    <div className='iconButton'>
                      <i className='material-icons'>phone_android</i>
                    </div>
                    <small>
                      <p>Mobile Recharge</p>
                    </small>
                  </Link>
                </div>
                <div
                  className='p-2'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 'fit-content'
                  }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#333' }}
                    to='/recharge/DTH'
                  >
                    <div className='iconButton'>
                      <i className='material-icons'>settings_input_antenna</i>
                    </div>
                    <small>
                      <p>DTH Recharge</p>
                    </small>
                  </Link>
                </div>
              
             

                <div
                  className='p-2'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 'fit-content'
                  }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#333' }}
                    to='/dmt'
                  >
                    <div className='iconButton'>
                      <SwapHoriz
                        fontSize='inherit'
                        style={{ fontSize: '25px' }}
                      />
                    </div>
                    <small>
                      <p style={{ textAlign: 'center' }}>Money Transfer</p>
                    </small>
                  </Link>
                </div>
                <div
                  className='p-2'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 'fit-content'
                  }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#333' }}
                    to='/aeps'
                  >
                    <div className='iconButton'>
                      <Fingerprint
                        fontSize='inherit'
                        style={{ fontSize: '25px' }}
                      />
                    </div>
                    <small>
                      <p style={{ textAlign: 'center' }}>Aeps</p>
                    </small>
                  </Link>
                </div>


                <div
                  className='p-2'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 'fit-content'
                  }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#333' }}
                    to='/BillPay'
                  >
                    <div className='iconButton'>
                      <img src='/bbps_icon.png' alt='Bill Pay' width='25px' height='25px' />
                    </div>
                    <small>
                      <p style={{ textAlign: 'center' }}>Bill Payment</p>
                    </small>
                  </Link>
                </div>
                <div
                  className='p-2'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 'fit-content'
                  }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: '#333' }}
                    to='/fastag'
                  >
                    <div className='iconButton'>
                    <img src='/fastag_icon.png' alt='Bill Pay' width='25px' height='25px' />
                    </div>
                    <small>
                      <p style={{ textAlign: 'center' }}>Fastag</p>
                    </small>
                  </Link>
                </div>

            

            </div>
          ) : (
            ''
          )}
          <div className='subHeading p-1'>Recharge Dasboard</div>
          <div className='Dash-Flex-CardBox-1'>
            <div className='dash-report-card bg-success'>
              <div className='Titleheading'>SUCCESS ({totalRechargeSuccessCount})</div>
              <div className='SubtitleBox'>₹{totalRechargeSuccessAmount}</div>
            </div>
            <div className='dash-report-card bg-pending'>
              <div className='Titleheading'>PENDING ({totalRechargePendingCount})</div>
              <div className='SubtitleBox'>₹{totalRechargePendingAmount}</div>
            </div>
            <div className='dash-report-card bg-failure'>
              <div className='Titleheading'>FAILURE ({totalRechargeFailedCount})</div>
              <div className='SubtitleBox'>₹{totalRechargeFailedAmount}</div>
            </div>
            <div className='dash-report-card bg-info'>
              <div className='Titleheading'>Commission</div>
              <div className='SubtitleBox'>₹{totalCommission}</div>
            </div>
            <div className='dash-report-card'>
              <div className='Titleheading'>TOTAL SALE</div>
              <div className='SubtitleBox'>₹{totalRechargeSell}</div>
            </div>
          </div>
          <div className='subHeading p-1'>DMT Dasboard</div>
          <div className='Dash-Flex-CardBox-1'>
            <div className='dash-report-card bg-success'>
              <div className='Titleheading'>SUCCESS ({totalDmtSuccessCount})</div>
              <div className='SubtitleBox'>₹{totalDmtSuccessAmount}</div>
            </div>
            <div className='dash-report-card bg-pending'>
              <div className='Titleheading'>PENDING ({totalDmtPendingCount})</div>
              <div className='SubtitleBox'>₹{totalDmtPendingAmount}</div>
            </div>
            <div className='dash-report-card bg-failure'>
              <div className='Titleheading'>FAILURE ({totalDmtFailedCount})</div>
              <div className='SubtitleBox'>₹{totalDmtFailedAmount}</div>
            </div>
            <div className='dash-report-card bg-info'>
              <div className='Titleheading'>Charge</div>
              <div className='SubtitleBox'>₹{totalCharge}</div>
            </div>
            <div className='dash-report-card'>
              <div className='Titleheading'>TOTAL SALE</div>
              <div className='SubtitleBox'>₹{totalDmtSell}</div>
            </div>
          </div>
          <div className='Dash-sub-heading'>Recent Transaction</div>
          <div className='Dash-Flex-CardBox-2'>
            <div className='Dash-recent-transection'>
              {trList.length > 0 ? (
                <table className='Dash-rech-table-view'>
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Operation Name</th>
                      <th>Transaction Type</th>
                      <th>Mobile No</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trList.map((transaction, index) => (
                      <tr key={transaction.id.toString()}>
                        <td>{index + 1}</td>
                        <td>{transaction.operatorName}</td>
                        <td>{transaction.transType}</td>
                        <td>{transaction.rechargeMobile}</td>
                        <td>₹{transaction.amount}</td>
                        {transaction.rechargeStatus === '1' ? (
                          <td className='text-success'>Success</td>
                        ) : transaction.rechargeStatus === '3' ? (
                          <td className='text-failure'>Refunded</td>
                        ) : transaction.rechargeStatus === '2' ? (
                          <td className='text-failure'>Failure</td>
                        ) : (
                          <td className='text-pending'>Pending</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='heading p-1'>No Transaction available</div>
              )}
            </div>
            <div className='AgentsDetailsTilesBox'>
              <div className='Agent-profile-box'>
                <span
                  className='material-icons'
                  style={{ fontSize: '100px', width: '100px' }}
                >
                  account_circle
                </span>
              </div>
              <div className='TilesColumns'>{props.user.name}</div>
              <div className='TilesColumns'>{props.user.accountType}</div>
              <div className='TilesColumns'>{props.user.businessName}</div>
              <div className='TilesColumns'>{props.user.mobile}</div>
              <div className='TilesColumns'>{props.user.email}</div>
              <Link className='Pass-btn' to='/change_password'>
                Settings
              </Link>
              {props.user.accountType === 'API' ? (
                <Link className='setting-btn' to='/configApi'>
                  API Configuration
                </Link>
              ) : (
                <Link className='setting-btn' to='/change_mpin'>
                  Change Mpin
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
