import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { CircularProgress } from "@mui/material";

export default function AepsHistory(props) {
  const [trxnsHistoryList, setTrxnsHistoryList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");

  var lastTxnId = "";

  function searchAepsHistory(e) {
    e.preventDefault();
    if (userId !== null && userId !== "" && userId.length === 10) {
      setLoading(true);
      axios
        .post(
          "/api/admin/aeps/history/" + props.user.id,
          {
            lastId: lastTxnId,
            userId: userId,
          },
          { headers: { Authorization: props.user.token } }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const data = res.data;
            console.log(res);
            if (data.status !== 1) {
              setError(data.message);
            }
            if (
              res.data.response !== undefined &&
              res.data.response != null &&
              res.data.response.status === 1
            ) {
              setTrxnsHistoryList(res.data.response.list);
              setUsername(data.username);
            }

            if (
              res.data.status === 3 ||
              res.data.message === "Session Expire"
            ) {
              localStorage.clear();
              props.setLoggedIn(false);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(error.message);
        });
    } else {
      setError("Invalid details");
    }
  }
  useEffect(() => {
    
    window.removeEventListener("scroll", searchAepsHistory);
    window.addEventListener("scroll", searchAepsHistory, { passive: true });
    return () => window.removeEventListener("scroll", searchAepsHistory);
  }, [searchAepsHistory]);
  // window.onscroll(searchAepsHistory);

  return isLoading ? (
    <div
      className="p-3"
      style={{ display: "flex", justifyContent: "center", paddingTop: "100px" }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div>
      <div className="container">
        <div className="wrap" style={{ flexDirection: "column" }}>
          <div className="box-shadow-6 p-2">
            <form className="status-search-wrap">
              <input
                type="number"
                className="form-control"
                id="userId"
                name="userId"
                value={userId}
                placeholder="Enter user id (Mobile No)"
                onChange={(e) => {
                  e.target.value.length < 11 && setUserId(e.target.value);
                }}
              />
              <div className="form-group">
                <button
                  type="submit"
                  id="requestBtn"
                  name="requestBtn"
                  className="btn btn-primary"
                  onClick={isLoading ? null : searchAepsHistory}
                >
                  Search
                </button>
              </div>
            </form>
          </div>
          {error !== null ? <div className="subHeading p-2">{error}</div> : ""}
          {trxnsHistoryList !== null && trxnsHistoryList.length > 0 ? (
            <>
              <div className="heading">Aeps History</div>
              <div className="trxns-report-card">
                <table className="trans-report-table">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>transaction Id</th>
                      <th>Username</th>
                      <th>Type</th>
                      <th>UTR No</th>
                      <th>Operator Name</th>
                      <th>Aadhar Number</th>
                      <th>Date</th>
                      <th>Mobile No</th>
                      <th>Amount</th>
                      <th>Commission</th>
                      <th>Charge</th>
                      <th>TDS</th>
                      <th>GST</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trxnsHistoryList.map((transaction, index) => {
                      lastTxnId = transaction.id;
                      return (
                        <tr key={transaction.id.toString()}>
                          <td>{index + 1}</td>
                          <td>{transaction.id}</td>
                          <td>{username}</td>
                          <td>
                            {transaction.transType === "CW"
                              ? "Withdrawal"
                              : transaction.transType === "BE"
                              ? "Balance Equiry"
                              : "Settlement"}
                          </td>
                          <td>{transaction.ref_no}</td>
                          <td>{transaction.BankName}</td>
                          <td>{transaction.aadharNo}</td>
                          <td>
                            {moment(transaction.rechargeAt).format(
                              "YYYY-MM-DD h:mm:ss a"
                            )}
                          </td>
                          <td>{transaction.mobile}</td>
                          <td>{transaction.amount}</td>
                          <td>
                            {transaction.commission !== null &&
                            transaction.commission !== ""
                              ? transaction.commission
                              : "0.00"}
                          </td>
                          <td>
                            {transaction.charge !== null &&
                            transaction.charge !== ""
                              ? transaction.charge
                              : "0.00"}
                          </td>
                          <td>
                            {transaction.tds !== "" && transaction.tds !== null
                              ? transaction.tds
                              : "0.00"}
                          </td>
                          <td>
                            {transaction.gst !== "" && transaction.gst !== null
                              ? transaction.gst
                              : "0.00"}
                          </td>
                          {transaction.status === "1" ? (
                            <td className="text-success">Success</td>
                          ) : transaction.status === "3" ? (
                            <td className="text-failure">Refunded</td>
                          ) : transaction.status === "2" ? (
                            <td className="text-failure">Failure</td>
                          ) : (
                            <td className="text-pending">Pending</td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
