import { Box, Paper, Typography } from '@mui/material';
import React from 'react'

export default function ServiceType(props) {
    const { icon, label, setServiceType, getOperator } = props;
    const serviceHandler = ()=>{
        setServiceType(label);
        getOperator(label);
    }
    return (
      <Box sx={{ my: 1, mx:1, width: "80px", cursor:'pointer' }} onClick={serviceHandler}>
        <Paper
          sx={{
            height: "80px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            borderRadius: "16px",
          }}
        >
          {icon}
        </Paper>
        <Typography sx={{ py:1, textAlign: "center", fontSize: "13px" }}>
          {label}
        </Typography>
      </Box>
    );
}
