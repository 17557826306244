import React, { useState } from "react";
import axios from "axios";
import "./LoginPage.css";
import { Redirect } from "react-router-dom";

export default function Login() {
  // let history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");

  const isLoaggedIn = localStorage.getItem("user");

  const handlerLogin = (event) => {
    event.preventDefault();

    if (mobile !== "" && mobile.length === 10 && password !== "") {
      setLoading(true);
      axios({
        method: "post",
        url: "/api/login",
        data: {
          mobile: mobile,
          password: password,
        },
      })
        .then((res) => {
          console.log(res);
          setLoading(false);

          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            if (data.status === 1) {
              const user = data.user;

              localStorage.setItem("user", JSON.stringify(user));

              if (
                localStorage.getItem("user") !== null &&
                localStorage.getItem("user") !== undefined
              ) {
                window.location = "/";
              } else {
                setError(
                  "Unable to login. Please refresh the page and try again"
                );
              }
            } else {
              setError(res.data.message);
            }
          } else {
            setError("res.data.message");
          }
        })
        .catch((error) => {
          setLoading(false);

          setError(error.message);
        });
    } else {
      alert("Empty request");
    }
  };

  return isLoaggedIn === null ? (
    <div>
      <div className="Container">
        <div className="Box">
          <div className="logoBox">
            <div className="LogoImage">BS</div>
          </div>
          <form method="post" onSubmit={handlerLogin}>
            <div className="FormWrap">
              <div className={error !== "" ? "errorClass" : ""}>{error}</div>
              <input
                type="text"
                onChange={(e) => setMobile(e.target.value)}
                className="InputText"
                id="emailId"
                placeholder="Enter Agent Id"
              />
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="InputText"
                id="pwd"
                placeholder="Enter Password"
              />
              <button type="submit" className="btn SubmitBtn">
                {isLoading ? "Loading..." : "Sign In"}
              </button>

              <div>
                <a className="link" href="/privacy_policy.html">
                  Privacy Policy
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to="/"></Redirect>
  );
}
