import React from 'react'
import SettleToWallet from './SettleToWallet'
import SettleToBank from './SettleToBank/SettleToBank'
import AepsSettlementHistory from './AepsSettlementHistory'

export default function AepsTabs (props) {
  const {getBeneficiary, beneficiarylist, settlementlist, getBalance} = props;
  const [tab, setTab] = React.useState('settleTobank')
  return (
    <>
      <div className=' mt-1 aepsTabs_card'>
        <div style={{ display: 'flex'}}>
          <div>
            <button
              onClick={e => {
                setTab('settleTobank')
                getBeneficiary();
              }}
              className={
                tab === 'settleTobank'
                  ? 'btnTransparent p-1 activeTab'
                  : ' btnTransparent p-1 '
              }
            >
              Settle To Bank
            </button>
          </div>
          <div>
            <button
              onClick={e => {
                setTab('settleToWallet')
              }}
              className={
                tab === 'settleToWallet'
                  ? 'btnTransparent p-1 activeTab'
                  : ' btnTransparent p-1 '
              }
            >
              Settle To Wallet
            </button>
          </div>
          <div>
            <button
              onClick={e => {
                setTab('settlementHistory')
                getBeneficiary()
              }}
              className={
                tab === 'settlementHistory'
                  ? 'btnTransparent p-1 activeTab'
                  : ' btnTransparent p-1 '
              }
            >
              Settlement History
            </button>
          </div>
        </div>
      </div>
      {tab === 'settleToWallet' ? (
        <SettleToWallet {...props}  getBalance={getBalance} />
      ) : tab === 'settleTobank' ? (
        <SettleToBank {...props} aepsBeneficiaryList ={beneficiarylist} getBeneficiaryList = {getBeneficiary} getBalance={getBalance} />
      ) : tab === 'settlementHistory' ? (
        <AepsSettlementHistory {...props} AepsSettlementList={settlementlist} />
      ) : (
        ''
      )}
    </>
  )
}
