import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

export default function AddOperator(props) {
  const location = useLocation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState("");
  const [apiId, setApiId] = useState("");
  const [apiListReport, setApiListReport] = useState([]);
  const [operatorName, setOperatorName] = useState("");
  const [operatorCode, setOperatorCode] = useState("");
  const [vendorComm, setVendorComm] = useState("");
  const [agentComm, setAgentComm] = useState("");
  const [aboveLimit, setAboveLimit] = useState("");
  const [lowerLimit, setLowerLimit] = useState("");
  const [dailyLimit, setDailyLimit] = useState("");
  const [vendorSlab, setVendorSlab] = useState("");
  const [agentSlab, setAgentSlab] = useState("");
  const [commission, setCommission] = useState("");
  const [circle, setCircle] = useState("");
  const [packageName, setPackageName] = useState(location.state.packageName);
  const [isLoading, setLoading] = useState(false);

  console.log(packageName);

  function addOperatorFormHandler(e) {
    e.preventDefault();
    setApiListReport([]);
    if (
      apiId !== null &&
      apiId !== "" &&
      operatorName !== null &&
      operatorName !== "" &&
      packageName !== null &&
      packageName !== "" &&
      operatorCode !== null &&
      operatorCode !== "" &&
      vendorComm !== null &&
      vendorComm !== "" &&
      agentComm !== null &&
      agentComm !== "" &&
      aboveLimit !== null &&
      aboveLimit !== "" &&
      lowerLimit !== null &&
      lowerLimit !== "" &&
      dailyLimit !== null &&
      dailyLimit !== "" &&
      vendorSlab !== null &&
      vendorSlab !== "" &&
      agentSlab !== null &&
      agentSlab !== "" &&
      commission !== null &&
      commission !== "" &&
      circle !== null &&
      circle !== ""
    ) {
      setLoading(true);

      axios
        .post(
          "/api/admin/package/add/operator/" + props.user.id,
          {
            apiId: apiId,
            operatorName: operatorName,
            operatorCode: operatorCode,
            vendorComm: vendorComm,
            agentComm: agentComm,
            aboveLimit: aboveLimit,
            lowerLimit: lowerLimit,
            dailyLimit: dailyLimit,
            vendorSlab: vendorSlab,
            agentSlab: agentSlab,
            commission: commission,
            circle: circle,
            packageName: packageName,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            setLoading(false);
            if (data.status === 1) {
              setApiId("");
              setOperatorName("");
              setOperatorCode("");
              setVendorComm("");
              setAgentComm("");
              setAboveLimit("");
              setLowerLimit("");
              setDailyLimit("");
              setVendorSlab("");
              setAgentSlab("");
              setCommission("");
              setCircle("");
              setErrorMessage(data.message);
            } else {
              setErrorMessage(data.message);
              if (data.message === "Session Expire") {
                //window.location.reload();
              }
            }
          }
        })
        .catch((e) => {
          setErrorMessage(e);
        });
    } else {
      setErrorMessage("Please fill the form correctly");
    }
  }

  useEffect(() => {
    if (props.user.id !== null) {
      setLoading(true);
      axios
        .get("/api/admin/package/apiList/" + props.user.id, {
          headers: { Authorization: props.user.token },
        })
        .then((res) => {
          if (res.status === 200) {
            const resData = res.data;
            console.log(res);
            setLoading(false);
            if (resData.status === 1) {
              setApiListReport(resData.apiList);
            } else {
              setApiListReport([]);
              setError(resData.message);
            }
          }
        })
        .catch((e) => {
          setError(e);
        });
    }
  }, [props]);

  return (
    <div className="container" style={{ paddingTop: "80px" }}>
      <div className="Admin-wrap">
        <div className="box-shadow-6 p-1">
          <div className="heading p-2">
            {packageName === "" ? "Create New Package" : "Add Operator"}
          </div>
          {errorMessage !== "" ? (
            <div className="pl-2 pr-2 pb-2">
              <div className="alert alert-default">{errorMessage}</div>
            </div>
          ) : (
            ""
          )}
          {isLoading ? (
            <div className="pl-2 pr-2 pb-2">
              <div className="heading">Loading....</div>
            </div>
          ) : (
            <div className="pl-2 pr-2 pb-2">
              <form
                method="POST"
                onSubmit={(e) => {
                  addOperatorFormHandler(e);
                }}
              >
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Package Name"
                    value={packageName}
                    disabled={location.state.packageName === "" ? false : true}
                    onChange={(e) => setPackageName(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  {apiListReport.length > 0 ? (
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setApiId(e.target.value);
                      }}
                    >
                      <option value="">Select API Id</option>
                      {apiListReport.map((list, index) => (
                        <option key={list.id} value={list.id}>
                          {list.vendorName}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div>{error}</div>
                  )}
                </div>

                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Operator Name"
                    value={operatorName}
                    onChange={(e) => setOperatorName(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Operator Code"
                    value={operatorCode}
                    onChange={(e) => setOperatorCode(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Vendor Commission"
                    value={vendorComm}
                    onChange={(e) => setVendorComm(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Agent Commission"
                    value={agentComm}
                    onChange={(e) => setAgentComm(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Upper Limit"
                    value={aboveLimit}
                    onChange={(e) => setAboveLimit(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Lower Limit"
                    value={lowerLimit}
                    onChange={(e) => setLowerLimit(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Daily Limit"
                    value={dailyLimit}
                    onChange={(e) => setDailyLimit(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <select
                    className="form-control"
                    onChange={(e) => setCircle(e.target.value)}
                  >
                    <option value="">Select Cirle</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar Jharkhand">Bihar Jharkhand</option>
                    <option value="Delhi NCR">Delhi NCR</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu Kashmir">Jammu Kashmir</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Kolkata">Kolkata</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Mumbait">Mumbait</option>
                    <option value="North East">North East</option>
                    <option value="Orissa">Orissa</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Up East">Up East</option>
                    <option value="Up West">UP West</option>
                    <option value="West Bengalt">West Bengal</option>
                    <option value="all">All</option>
                  </select>
                </div>
                <div className="form-group">
                  <select
                    className="form-control"
                    onChange={(e) => setVendorSlab(e.target.value)}
                  >
                    <option value="">Select Vendor Commission Type</option>
                    <option value="percentage">Percentage</option>
                    <option value="flat">Flat</option>
                  </select>
                </div>
                <div className="form-group">
                  <select
                    className="form-control"
                    onChange={(e) => setAgentSlab(e.target.value)}
                  >
                    <option value="">Select Agent Commission Type</option>
                    <option value="percentage">Percentage</option>
                    <option value="flat">Flat</option>
                  </select>
                </div>
                <div className="form-group">
                  <select
                    className="form-control"
                    onChange={(e) => setCommission(e.target.value)}
                  >
                    <option value="">Select Slab Type</option>
                    <option value="Commission">Commission</option>
                    <option value="Charge">Charge</option>
                  </select>
                </div>
                <div className="form-group pl-1">
                  {!isLoading ? (
                    <button className="btn btn-primary">Submit</button>
                  ) : (
                    <div className="p-2" style={{ color: "blue" }}>
                      Processing...
                    </div>
                  )}
                  <button className="btn btn-danger" style={{marginLeft:'10px'}} onClick={history.goBack}>Back</button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
