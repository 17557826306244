import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import "./paymentRequest.css";

export default function AddFund(props) {
  const [isLoading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [postUrl, setPostUrl] = useState("");
  const [orderId, setOrderId] = useState("");
  const [txnToken, setTxnToken] = useState("");
  const [mid, setMid] = useState("");
  const [isError, setError] = useState("");

  const getAddFund = (e) => {
    e.preventDefault();
    if (amount !== null && amount !== "") {
      setLoading(true);
      axios
        .post(
          "/api/addfund/initiate/" + props.user.id,
          {
            amount: amount,
            source: 'web',
          },
          { headers: { Authorization: props.user.token } }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.status === 1) {
            setPostUrl(
              "https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=" +
                res.data.mid +
                "&orderId=" +
                res.data.orderId
            );
            setMid(res.data.mid);
            setOrderId(res.data.orderId);
            setTxnToken(res.data.txnToken);
            document.querySelector('[name="paytm"]').submit();
          } else {
            setError(res.message);
          }
          console.log(res);
        })
        .catch((e) => {
          setError(e);
        });
    } else {
      setError("Please filled the amount.");
    }
  };
  return (
    <div>
      {isLoading ? (
        <div
          className="p-4"
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "100px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className="container">
          <div className="content-wrap">
            <div className="heading">Add Fund</div>
            <div className="box-shadow-6 p-2">
              {isError !== "" ? (
                <div className="heading p-2">{isError}</div>
              ) : (
                ""
              )}
              <form
                className="Payment-request-form"
                style={{ justifyContent: "flex-start" }}
              >
                <input
                  type="text"
                  className="form-control"
                  id="amount"
                  name="amount"
                  placeholder="Enter Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />

                <div className="form-group">
                  <button
                    type="submit"
                    id="requestBtn"
                    name="requestBtn"
                    className="btn btn-primary"
                    onClick={isLoading ? null : getAddFund}
                  >
                    {isLoading ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>

              <form hidden method="post" action={postUrl} target="_blank" name="paytm">
                <input type="hidden" name="mid" value={mid} />
                <input type="hidden" name="orderId" value={orderId} />
                <input type="hidden" name="txnToken" value={txnToken} />
                <input type="submit" />
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
