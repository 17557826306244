import React from 'react'

export default function Page404() {
    return (
        <div>
            <div className="Page404Card">
                <div className="Page404Heading">404</div>
                <div className="Page404SubHeading">PAGE NOT FOUND</div>
            </div>
        </div>
    )
}
