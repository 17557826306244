import Axios from "axios";
import { Link, Redirect } from "react-router-dom";
import "./Header.css";
import React from "react";

export default function Header(props) {
  const balance = props.balance;
  const accountType = props.accountType;
  const logoutHandler = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (
      user.id !== null &&
      user.token !== null &&
      user.id !== undefined &&
      user.token !== undefined
    ) {
      Axios.get("/api/user/" + user.id, {
        headers: { Authorization: user.token },
      })
        .then((res) => {
          if (res.status === 200) {
            localStorage.clear();
            props.setLoggedIn(false);
          } else {
            localStorage.clear();
            props.setLoggedIn(false);
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      localStorage.clear();
      props.setLoggedIn(false);
    }
  };

  return accountType !== "Admin" ? (
    <div className="headerBox">
      <div className="wrap">
        <div className="logo">
          <Link to="/" style={{ textDecoration: "none", color: "#680e7a" }}>
            BS
          </Link>
        </div>
        <div className="NavBarMenu">
          <ul>
            {accountType === "RT" ? (
              <li>
                <Link to="#" className="linkTextDecorate">
                  Recharge
                </Link>
                <div className="Sub-menu-1">
                  <ul>
                    <li>
                      <Link to="/recharge/mobile" className="linkTextDecorate">
                        Mobile Recharge
                      </Link>
                    </li>
                    <li>
                      <Link className="linkTextDecorate" to="/recharge/DTH">
                        DTH Recharge
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}
            {accountType === "RT" ? (
              <li>
                <Link to="#" className="linkTextDecorate">
                  Utility Services
                </Link>
                <div className="Sub-menu-3">
                  <ul>
                    <li>
                      <Link to="/BillPay" className="linkTextDecorate">
                        Bill Payment
                      </Link>
                    </li>
                    <li>
                      <Link className="linkTextDecorate" to="/fastag">
                        Fastag Recharge
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}
            {accountType === "RT" ? (
              <li>
                <Link to="/dmt" className="linkTextDecorate">
                  DMT
                </Link>
              </li>
            ) : (
              ""
            )}
            {accountType === "RT" ? (
              <li>
                <Link to="/aeps" className="linkTextDecorate">
                  AEPS
                </Link>
              </li>
            ) : (
              ""
            )}

            <li>
              <Link to="#" className="linkTextDecorate">
                Report
              </Link>
              <div className="Sub-menu-2">
                <ul>
                  <li>
                    <Link
                      className="linkTextDecorate"
                      to="/report/recharge_history"
                    >
                      Transaction History
                    </Link>
                  </li>
                 
                  {accountType === "RT" ? (
                    <li>
                      <Link
                        className="linkTextDecorate"
                        to="/report/aeps_history"
                      >
                        AEPS History
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <Link
                      className="linkTextDecorate"
                      to="/report/recharge_status"
                    >
                      Recharge Status
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="linkTextDecorate"
                      to="/report/account_statement"
                    >
                      Account Statement
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="linkTextDecorate"
                      to="/report/topup_history"
                    >
                      TopUp History
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="linkTextDecorate"
                      to="/report/operator_view"
                    >
                      Operator Report
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link to="#" className="linkTextDecorate">
                Fund Request
              </Link>
              <div className="Sub-menu-3">
                <ul>
                  <li>
                    <Link to="/addfund" className="linkTextDecorate">
                      Add Fund
                    </Link>
                  </li>
                  <li>
                    <Link className="linkTextDecorate" to="/payment_request">
                      Payment Request
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <Link className="linkTextDecorate" to="/commission">
                My Commission
              </Link>
            </li>
            <li>
              <Link className="linkTextDecorate" to="/complain">
                Complain
              </Link>
            </li>
          </ul>
        </div>
        <div className="NavBarMenuBtn">
          <ul>
            <li style={{ paddingRight: "15px" }}>
              <span style={{ marginRight: "5px" }}>
                ₹{parseFloat(balance).toFixed(2)}
              </span>
              <i
                className="material-icons"
                onClick={props.getUser}
                style={{
                  verticalAlign: "middle",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                autorenew
              </i>
            </li>
            <li
              style={{ paddingLeft: "15px", cursor: "pointer" }}
              onClick={logoutHandler}
            >
              Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to="/login"></Redirect>
  );
}
