import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { CircularProgress } from '@mui/material';

export default function OperatorReport (props) {
  const [startDate, setStartDate] = useState('')
  const [lastDate, setLastDate] = useState('')
  const [userId, setUserId] = useState('')
  const [totalAirtelAmount, setTotalAirtelAmount] = useState(0)
  const [totalJioAmount, setTotalJioAmount] = useState(0)
  const [totalVIAmount, setTotalVIAmount] = useState(0)
  const [totalBSNLAmount, setTotalBSNLAmount] = useState(0)
  const [successAmount, setSuccessAmount] = useState(0)

  const [totalCommissionEarned, setTotalCommissionEarned] = useState(0)
  const [
    totalAirtelCommissionEarned,
    setTotalAirtelCommissionEarned
  ] = useState(0)
  const [totalJioCommissionEarned, setTotalJioCommissionEarned] = useState(0)
  const [totalVICommissionEarned, setTotalVICommissionEarned] = useState(0)
  const [totalBSNLCommissionEarned, setTotalBSNLCommissionEarned] = useState(0)

  const [airtelSuccessCount, setAirtelSuccessCount] = useState(0)
  const [jioSuccessCount, setJioSuccessCount] = useState(0)
  const [VISuccessCount, setVISuccessCount] = useState(0)
  const [BSNLSuccessCount, setBSNLSuccessCount] = useState(0)

  const [airtelPendingCount, setAirtelPendingCount] = useState(0)
  const [jioPendingCount, setJioPendingCount] = useState(0)
  const [VIPendingCount, setVIPendingCount] = useState(0)
  const [BSNLPendingCount, setBSNLPendingCount] = useState(0)

  const [airtelFailureCount, setAirtelFailureCount] = useState(0)
  const [jioFailureCount, setJioFailureCount] = useState(0)
  const [VIFailureCount, setVIFailureCount] = useState(0)
  const [BSNLFailureCount, setBSNLFailureCount] = useState(0)

  const [stDate, setStDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [operator, setOperator] = useState('')
  const [isLoading, setLoading] = useState(false)

  function getOperatorView (e) {
    e.preventDefault()
    setLoading(true)
    axios
      .get('/api/dt/report/operator/view/' + props.user.id, {
        params: {
          stDate: stDate,
          endDate: endDate,
          userId: userId,
          operator: operator
        },
        headers: { Authorization: props.user.token }
      })
      .then(res => {
        if (res.status === 200) {
          setLoading(false)
          const data = res.data
          console.log(res)
          if (data.status === 1) {
            setTotalAirtelAmount(
              data.totalAirtelAmount !== null
                ? data.totalAirtelAmount.toFixed(2)
                : 0.0
            )
            setTotalJioAmount(
              data.totalJioAmount !== null
                ? data.totalJioAmount.toFixed(2)
                : 0.0
            )
            setTotalVIAmount(
              data.totalVIAmount !== null ? data.totalVIAmount.toFixed(2) : 0.0
            )
            setTotalBSNLAmount(
              data.totalBSNLAmount !== null
                ? data.totalBSNLAmount.toFixed(2)
                : 0.0
            )
            setSuccessAmount(
              data.successAmount !== null ? data.successAmount.toFixed(2) : 0.0
            )

            setTotalCommissionEarned(
              data.totalCommissionEarned !== null
                ? data.totalCommissionEarned.toFixed(2)
                : 0.0
            )
            setTotalAirtelCommissionEarned(
              data.totalAirtelCommissionEarned !== null
                ? data.totalAirtelCommissionEarned.toFixed(2)
                : 0.0
            )
            setTotalJioCommissionEarned(
              data.totalJioCommissionEarned !== null
                ? data.totalJioCommissionEarned.toFixed(2)
                : 0.0
            )
            setTotalVICommissionEarned(
              data.totalVICommissionEarned !== null
                ? data.totalVICommissionEarned.toFixed(2)
                : 0.0
            )
            setTotalBSNLCommissionEarned(
              data.totalBSNLCommissionEarned !== null
                ? data.totalBSNLCommissionEarned.toFixed(2)
                : 0.0
            )
            setAirtelSuccessCount(data.airtelSuccessCount)
            setJioSuccessCount(data.jioSuccessCount)
            setVISuccessCount(data.VISuccessCount)
            setBSNLSuccessCount(data.BSNLSuccessCount)

            setAirtelFailureCount(data.airtelFailureCount)
            setJioFailureCount(data.jioFailureCount)
            setVIFailureCount(data.VIFailureCount)
            setBSNLFailureCount(data.BSNLFailureCount)

            setAirtelPendingCount(data.airtelPendingCount)
            setJioPendingCount(data.jioPendingCount)
            setVIPendingCount(data.VIPendingCount)
            setBSNLPendingCount(data.BSNLPendingCount)
            setStartDate(data.startDate)
            setLastDate(data.endDate)
          }
        }
      })
      .catch(error => {
        setLoading(false)
        alert(error)
      })
  }

  useEffect(() => {
    if (props.user.id != null) {
      setLoading(true)
      axios
        .get('/api/dt/report/operator/view/' + props.user.id, {
          params: {
            stDate: '',
            endDate: '',
            userId: '',
            operator: ''
          },
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setTotalAirtelAmount(
                data.totalAirtelAmount !== null
                  ? data.totalAirtelAmount.toFixed(2)
                  : 0.0
              )
              setTotalJioAmount(
                data.totalJioAmount !== null
                  ? data.totalJioAmount.toFixed(2)
                  : 0.0
              )
              setTotalVIAmount(
                data.totalVIAmount !== null
                  ? data.totalVIAmount.toFixed(2)
                  : 0.0
              )
              setTotalBSNLAmount(
                data.totalBSNLAmount !== null
                  ? data.totalBSNLAmount.toFixed(2)
                  : 0.0
              )
              setSuccessAmount(
                data.successAmount !== null
                  ? data.successAmount.toFixed(2)
                  : 0.0
              )

              setTotalCommissionEarned(
                data.totalCommissionEarned !== null
                  ? data.totalCommissionEarned.toFixed(2)
                  : 0.0
              )
              setTotalAirtelCommissionEarned(
                data.totalAirtelCommissionEarned !== null
                  ? data.totalAirtelCommissionEarned.toFixed(2)
                  : 0.0
              )
              setTotalJioCommissionEarned(
                data.totalJioCommissionEarned !== null
                  ? data.totalJioCommissionEarned.toFixed(2)
                  : 0.0
              )
              setTotalVICommissionEarned(
                data.totalVICommissionEarned !== null
                  ? data.totalVICommissionEarned.toFixed(2)
                  : 0.0
              )
              setTotalBSNLCommissionEarned(
                data.totalBSNLCommissionEarned !== null
                  ? data.totalBSNLCommissionEarned.toFixed(2)
                  : 0.0
              )
              setAirtelSuccessCount(data.airtelSuccessCount)
              setJioSuccessCount(data.jioSuccessCount)
              setVISuccessCount(data.VISuccessCount)
              setBSNLSuccessCount(data.BSNLSuccessCount)

              setAirtelFailureCount(data.airtelFailureCount)
              setJioFailureCount(data.jioFailureCount)
              setVIFailureCount(data.VIFailureCount)
              setBSNLFailureCount(data.BSNLFailureCount)

              setAirtelPendingCount(data.airtelPendingCount)
              setJioPendingCount(data.jioPendingCount)
              setVIPendingCount(data.VIPendingCount)
              setBSNLPendingCount(data.BSNLPendingCount)
              setStartDate(data.startDate)
              setLastDate(data.endDate)
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === 'Session Expire'
          ) {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          setLoading(false)
          alert(error)
        })
    }
  }, [props])
  return (
    <div className='container'>
      <div className='wrap' style={{ flexDirection: 'column' }}>
        <div className='box-shadow-6 p-2'>
          <form className='trans-search-wrap'>
            <input
              type='date'
              className='form-control'
              id='fromDate'
              name='fromDate'
              onChange={e => setStDate(e.target.value)}
            />
            <input
              type='date'
              className='form-control'
              id='toDate'
              name='toDate'
              onChange={e => setEndDate(e.target.value)}
            />

            <input
              type='text'
              className='form-control'
              id='OptrName'
              name='OptrName'
              placeholder='Operator Name'
              onChange={e => setOperator(e.target.value)}
            />
            <input
              type='text'
              className='form-control'
              maxLength='10'
              id='userId'
              name='userId'
              placeholder='Enter User Id'
              onChange={e => setUserId(e.target.value)}
            />
            <div className='form-group'>
              <button
                type='submit'
                id='requestBtn'
                name='requestBtn'
                className='btn btn-primary'
                onClick={isLoading ? null : getOperatorView}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className='heading' style={{ marginTop: '50px' }}>
          Operators Report
        </div>
        {isLoading ? (
          <div
            className='p-2'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className='Count-Details'>
              <div className='DateShow'>
                {startDate} to {lastDate}
              </div>
              <div className='RechargeAmountCount'>
                <div className='RechargeSuccessCommission'>
                  Commission: {totalCommissionEarned}
                </div>
                <div className='RechargeSuccessAmount'>
                  Total Sale: {successAmount}
                </div>
              </div>
            </div>
            <div className='Operator-Views-Card'>
              <table className='Operator-Views-table'>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Operator</th>
                    <th>Success Recharge</th>
                    <th>Failure Recharge</th>
                    <th>Pending Recharge</th>
                    <th>Total Commission</th>
                    <th>Operator Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Airtel</td>
                    <td>{airtelSuccessCount}</td>
                    <td>{airtelFailureCount}</td>
                    <td>{airtelPendingCount}</td>
                    <td>{totalAirtelCommissionEarned}</td>
                    <td>{totalAirtelAmount}</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Jio</td>
                    <td>{jioSuccessCount}</td>
                    <td>{jioFailureCount}</td>
                    <td>{jioPendingCount}</td>
                    <td>{totalJioCommissionEarned}</td>
                    <td>{totalJioAmount}</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>VI</td>
                    <td>{VISuccessCount}</td>
                    <td>{VIFailureCount}</td>
                    <td>{VIPendingCount}</td>
                    <td>{totalVICommissionEarned}</td>
                    <td>{totalVIAmount}</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>BSNL</td>
                    <td>{BSNLSuccessCount}</td>
                    <td>{BSNLFailureCount}</td>
                    <td>{BSNLPendingCount}</td>
                    <td>{totalBSNLCommissionEarned}</td>
                    <td>{totalBSNLAmount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
