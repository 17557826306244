import React from "react";
import { Route, Switch } from "react-router-dom";
import Commission from "../Components/Users/Commission_Slab/Commission";
import Complain from "../Components/Users/Complain/Complain";
import Login from "../Components/LoginPage/Login";
import PaymentRequest from "../Components/Users/Payment_Request/PaymentRequest";
import ProtectedPage from "../Components/Users/ProtectedPage.js";
import MobileRecharge from "../Components/Users/Recharge/MobileRecharge";
import dthRecharge from "../Components/Users/Recharge/dthRecharge";
import RechargeHistory from "../Components/Users/Report/RechargeHistory";
import TopUpHistory from "../Components/Users/Report/TopUpHistory";
import Page404 from "../Page404";
import AccountStatement from "../Components/Users/Report/AccountStatement";
import OperatorViews from "../Components/Users/Report/OperatorViews";
import RechargeStatus from "../Components/Users/Report/RechargeStatus";
import ConfrmRecharge from "../Components/Users/Recharge/ConfrmRecharge";
import Response from "../Components/Users/Recharge/Response";
import ChangePassword from "../Components/Users/Change_security/ChangePassword";
import UserHome from "../Components/Users/UserHomePage/UserHome";
import ChangeMpin from "../Components/Users/Change_security/ChangeMpin";
import ApiConfig from "../Components/Users/API_Config/ApiConfig";
import DMT from "../Components/Users/DMT/DMT";
import ConfirmTransaction from "../Components/Users/DMT/Transaction/ConfirmTransaction";
import ResponseTransaction from "../Components/Users/DMT/Transaction/ResponseTransaction";
import Aeps from "../Components/Users/Aeps/Aeps";
import DmtHistory from "../Components/Users/Report/DmtHistory";
import AepsHistory from "../Components/Users/Report/AepsHistory";
import AepsResponseTransaction from "../Components/Users/Aeps/Aeps_Tabs/SettleToBank/AepsResponseTransaction";
import UPIResponseTransaction from "../Components/Users/Aeps/Aeps_Tabs/TransferToUPI/UPIResponseTransaction";
import FastagRechargeForm from "../Components/Users/Utility_Services/Fastag/FastagRechargeForm";
import AddFund from "../Components/Users/Payment_Request/AddFund";
import FastagCustomerConfirmDetails from "../Components/Users/Utility_Services/Fastag/FastagCustomerConfirmDetails";
import FastagResponse from "../Components/Users/Utility_Services/Fastag/FastagResponse";
import BillPayForm from "../Components/Users/Utility_Services/Bill_Payment/BillPayForm";
import BillPayConfirmDetails from "../Components/Users/Utility_Services/Bill_Payment/BillPayConfirmDetails";
import BillPayResponse from "../Components/Users/Utility_Services/Bill_Payment/BillPayResponse";
import RedirectHomePage from "../Components/LoginPage/RedirectHomePage";

export default function Router() {
  const [user, setUser] = React.useState(
    localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : ""
  );

  React.useEffect(() => {
    setUser(user);
  }, [user]);

  return (
    <Switch>
      <ProtectedPage exact path="/" page={UserHome} />
      {user.accountType === "RT" ? (
        <ProtectedPage exact path="/recharge/mobile" page={MobileRecharge} />
      ) : (
        <ProtectedPage exact path="/recharge/mobile" page={Page404} />
      )}
      {user.accountType === "RT" ? (
        <ProtectedPage exact path="/recharge/confirm" page={ConfrmRecharge} />
      ) : (
        <ProtectedPage exact path="/recharge/confirm" page={Page404} />
      )}
      {user.accountType === "RT" ? (
        <ProtectedPage exact path="/recharge/response" page={Response} />
      ) : (
        <ProtectedPage exact path="/recharge/response" page={Page404} />
      )}
      <ProtectedPage exact path="/addfund" page={AddFund} />
      {user.accountType === "RT" ? (
        <ProtectedPage exact path="/fastag" page={FastagRechargeForm} />
      ) : (
        <ProtectedPage exact path="/fastag" page={Page404} />
      )}
      {user.accountType === "RT" ? (
        <ProtectedPage
          exact
          path="/fastag/confirm"
          page={FastagCustomerConfirmDetails}
        />
      ) : (
        <ProtectedPage exact path="/fastag/confirm" page={Page404} />
      )}
      {user.accountType === "RT" ? (
        <ProtectedPage exact path="/fastag/response" page={FastagResponse} />
      ) : (
        <ProtectedPage exact path="/fastag/response" page={Page404} />
      )}

      {user.accountType === "RT" ? (
        <ProtectedPage
          exact
          path="/BillPay"
          page={BillPayForm}
        />
      ) : (
        <ProtectedPage exact path="/BillPay" page={Page404} />
      )}
      {user.accountType === "RT" ? (
        <ProtectedPage
          exact
          path="/BillPay/confirm"
          page={BillPayConfirmDetails}
        />
      ) : (
        <ProtectedPage exact path="/BillPay/confirm" page={Page404} />
      )}
      {user.accountType === "RT" ? (
        <ProtectedPage
          exact
          path="/BillPay/response"
          page={BillPayResponse}
        />
      ) : (
        <ProtectedPage exact path="/BillPay/response" page={Page404} />
      )}

      {user.accountType === "RT" ? (
        <ProtectedPage exact path="/recharge/DTH" page={dthRecharge} />
      ) : (
        <ProtectedPage exact path="/recharge/DTH" page={Page404} />
      )}

      {user.accountType === "RT" ? (
        <ProtectedPage exact path="/dmt" page={DMT} />
      ) : (
        <ProtectedPage exact path="/dmt" page={Page404} />
      )}

      {user.accountType === "RT" ? (
        <ProtectedPage exact path="/dmt/confirm" page={ConfirmTransaction} />
      ) : (
        <ProtectedPage exact path="/dmt/confirm" page={Page404} />
      )}
      {user.accountType === "RT" ? (
        <ProtectedPage exact path="/dmt/response" page={ResponseTransaction} />
      ) : (
        <ProtectedPage exact path="/dmt/response" page={Page404} />
      )}

      {user.accountType === "RT" ? (
        <ProtectedPage exact path="/aeps" page={Aeps} />
      ) : (
        <ProtectedPage exact path="/aeps" page={Page404} />
      )}
      {user.accountType === "RT" ? (
        <ProtectedPage
          exact
          path="/aeps/response"
          page={AepsResponseTransaction}
        />
      ) : (
        <ProtectedPage exact path="/aeps/response" page={Page404} />
      )}
      {user.accountType === "RT" ? (
        <ProtectedPage
          exact
          path="/aeps/upi/response"
          page={UPIResponseTransaction}
        />
      ) : (
        <ProtectedPage exact path="/aeps/upi/response" page={Page404} />
      )}

      <ProtectedPage exact path="/commission" page={Commission} />
      <ProtectedPage exact path="/payment_request" page={PaymentRequest} />

      <ProtectedPage
        exact
        path="/report/recharge_history"
        page={RechargeHistory}
      />
      <ProtectedPage exact path="/report/dmt_history" page={DmtHistory} />
      <ProtectedPage exact path="/report/aeps_history" page={AepsHistory} />
      <ProtectedPage
        exact
        path="/report/recharge_status"
        page={RechargeStatus}
      />
      <ProtectedPage exact path="/report/topup_history" page={TopUpHistory} />
      <ProtectedPage
        exact
        path="/report/account_statement"
        page={AccountStatement}
      />
      <ProtectedPage exact path="/report/operator_view" page={OperatorViews} />
      <ProtectedPage exact path="/complain" page={Complain} />
      <ProtectedPage exact path="/change_password" page={ChangePassword} />
      <ProtectedPage exact path="/change_mpin" page={ChangeMpin} />
      <ProtectedPage exact path="/configApi" page={ApiConfig} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={RedirectHomePage} />
      <Route path="*" component={Page404} />
    </Switch>
  );
}
