import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function MobileHeader (props) {
  const logoutMobileHandler = () => {
    const user = JSON.parse(localStorage.getItem('user'))

    if (
      user.id !== null &&
      user.token !== null &&
      user.id !== undefined &&
      user.token !== undefined
    ) {
      axios
        .get('/api/user/' + user.id, { headers: { Authorization: user.token } })
        .then(res => {
          if (res.status === 200) {
            localStorage.clear()
            props.setLoggedIn(false)
          } else {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          alert(error)
        })
    } else {
      localStorage.clear()
      props.setLoggedIn(false)
    }
  }
  const balance = props.balance
  const accountType = props.accountType
  const drawer = React.useRef(null)
  const [openDropdown, setOpenDropdown] = useState(false)

  const [openReportDropdown, setOpenReportDropdown] = useState(false)

  const reportDropdownhandler = () => {
    setOpenReportDropdown(!openReportDropdown)
  }

  const Dropdownhandler = () => {
    setOpenDropdown(!openDropdown)

    drawer.current.classList.toggle('isOpen')
  }

  return (
    <div>
      <div className='Mobile_HeaderBox'>
        <div className='wrap'>
          <div className='NavManuBar' onClick={Dropdownhandler}>
            <div></div>
            <div style={{ margin: '4px 0px' }}></div>
            <div></div>
          </div>

          <div className='logo'>
            <Link to='/' style={{ textDecoration: 'none', color: '#680e7a' }}>
              BS
            </Link>
          </div>
          <div className='Agent-balanceBox'>
            <span style={{ marginRight: '5px' }}>
              ₹{parseFloat(balance).toFixed(2)}
            </span>
            <i
              className='material-icons'
              style={{ verticalAlign: 'middle', fontSize: '20px' }}
            >
              autorenew
            </i>
          </div>
        </div>
      </div>

      <div className='drawer' ref={drawer}>
        <div style={{ flexDirection: 'column' }}>
          <div className='MobileDropdownMenu'>
            <ul>
              <Link
                className='linkTextDecorate'
                to='/'
                onClick={Dropdownhandler}
              >
                Home
              </Link>

              <li className='p1'>
                <Link
                  className='linkTextDecorate '
                  to='/addfund'
                  onClick={Dropdownhandler}
                >
                  Add Fund
                </Link>
              </li>
              <li className='linkTextDecorate' onClick={reportDropdownhandler}>
                Report
              </li>
              <div className={openReportDropdown ? 'ReportDropdown' : 'hidden'}>
                <Link
                  className='linkTextDecorate'
                  to='/report/recharge_history'
                  onClick={Dropdownhandler}
                >
                  Transaction History
                </Link>

                {accountType === 'RT' ? (
                  <Link className='linkTextDecorate' to='/report/aeps_history'>
                    AEPS History
                  </Link>
                ) : (
                  ''
                )}
                <Link
                  className='linkTextDecorate'
                  to='/report/recharge_status'
                  onClick={Dropdownhandler}
                >
                  Recharge Status
                </Link>

                <Link
                  className='linkTextDecorate'
                  to='/report/account_statement'
                  onClick={Dropdownhandler}
                >
                  Account Statement
                </Link>

                <Link
                  className='linkTextDecorate'
                  to='/report/topup_history'
                  onClick={Dropdownhandler}
                >
                  TopUp History
                </Link>

                <Link
                  className='linkTextDecorate'
                  to='/report/operator_view'
                  onClick={Dropdownhandler}
                >
                  Operator Report
                </Link>
              </div>


              
              <li className='p1'>
                <Link
                  className='linkTextDecorate '
                  to='/payment_request'
                  onClick={Dropdownhandler}
                >
                  Payment Request
                </Link>
              </li>
              <li className='p1'>
                <Link
                  className='linkTextDecorate'
                  to='/commission'
                  onClick={Dropdownhandler}
                >
                  My Commission
                </Link>
              </li>
              <li className='p1'>
                <Link
                  className='linkTextDecorate'
                  to='/complain'
                  onClick={Dropdownhandler}
                >
                  Complain
                </Link>
              </li>
              <li className='linkTextDecorate' onClick={logoutMobileHandler}>
                Logout
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className={openDropdown ? 'bg-full-blank' : 'hidden'}
        onClick={Dropdownhandler}
      ></div>
    </div>
  )
}
