import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function FastagCustomerConfirmDetails(props) {
  const location = useLocation();
  const history = useHistory();
  const [mpin, setMpin] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isloading, setLoading] = React.useState(false);

  const fastagResponseHandler = (e) => {
    e.preventDefault();
    if (
      longitude === "" &&
      longitude === null &&
      latitude === "" &&
      latitude === null
    ) {
      setError(
        "Please firstly allow the location of the software, after then its continues the transaction of fastag."
      );
    } else if (mpin !== "" && mpin !== null && mpin !== undefined) {
      setLoading(true);
      axios
        .post(
          "/api/fastag/fastag_recharge/" + props.user.id,
          {
            operatorId: location.state.operatorId,
            number: location.state.vehicleNumber,
            latitude: latitude,
            longitude: longitude,
            amount: amount,
            trxnpin: mpin,
            operator: location.state.operatorName,
          },
          { headers:{Authorization:props.user.token} }
        )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setLoading(false);
            const data = res.data;
            console.log(res);
            if (data.status === 3 && data.message === "Session Expire") {
              localStorage.clear();
              props.setLoggedIn(false);
            } else {
              history.replace("response", {
                user_name: location.state.user_name,
                vehicleNumber: location.state.vehicleNumber,
                fastag_amount: location.state.fastag_amount,
                operatorName: location.state.operatorName,
                amount: amount,
                trxnId: res.data.tid,
                date: res.data.payDate,
                optId: res.data.operatorTxnId,
                refId: res.data.refId,
                status: res.data.status,
                message: res.data.message,
              });
            }
          }
        })
        .catch((e) => {
          setError(e);
        });
    } else {
      setError("Please filled mpin field");
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, []);
  return props.user.accountType === "RT" ? (
    <div>
      <div className="container">
        <div className="p-2">
          <div className="bg-white box-shadow-6 p-2">
            {isloading ? (
              <div
                className="p-2"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="heading text-center">
                  Fastag Confirm Details
                </div>
                <div className="confirmWrap">
                  {error !== "" ? (
                    <div className="errorClass text-center">{error}</div>
                  ) : (
                    ""
                  )}
                  <div className="utilityDetailsWrap">
                    <div className="confirmTile text-center">
                      <div>Operator Name</div>
                      <div className="textHeadingCol text-center">
                        {location.state.operatorName}
                      </div>
                    </div>

                    <div className="confirmTile text-center">
                      <div>Username</div>
                      {location.state.user_name !== null &&
                      location.state.user_name !== "" ? (
                        <div className="textHeadingCol">
                          {location.state.user_name}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="confirmTile text-center">
                      <div>Vehicle Number</div>
                      <div className="textHeadingCol">
                        {location.state.vehicleNumber}
                      </div>
                    </div>
                    <div className="confirmTile text-center">
                      <div>Fastag Balance</div>
                      <div className="textHeadingCol">
                        {location.state.fastag_amount}
                      </div>
                    </div>
                    {location.state.partPay === true ? (
                      <div className="confirmTile text-center">
                        <div>Amount</div>
                        <div>
                          <input
                            type="text"
                            className="mpin"
                            placeholder="Enter Amount"
                            value={amount}
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="confirmTile text-center">
                      <div>Transaction MPIN</div>
                      <div>
                        <input
                          type="password"
                          className="mpin"
                          placeholder="MPIN (4 Digit)"
                          value={mpin}
                          onChange={(e) => {
                            e.target.value.length < 5 &&
                              setMpin(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="confirmTile text-center">
                      <button
                        className="btn btn-primary"
                        onClick={fastagResponseHandler}
                        style={{ marginRight: "10px" }}
                      >
                        {"Confirm & Pay"}
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={history.goBack}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="container">
        <div className="p-2">
          <div className="bg-white box-shadow-6 p-2">
            <h1>Unautherise Access</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
