import axios from "axios";
import React, { useEffect } from "react";

export default function RequestParam(props) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [requestParam, setRequestParam] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);

  function deleteParam(paramId) {
    const conf = window.confirm("Are your sure want to delete this parameter");
    if (conf) {
      setLoading(true);
      axios
        .post(
          "/api/admin/settings/api/delete/param/" + props.user.id,
          {
            apiId: props.apiId,
            paramId: paramId,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            if (data.status === 1) {
              alert(data.message);
            } else {
              setErrorMessage(data.message);
            }
          }
        })
        .catch((e) => {
          setErrorMessage(e);
        });
    }
  }

  useEffect(() => {
    if (props.user.id !== null) {
      axios
        .get("/api/admin/settings/api/request/param/" + props.user.id, {
          params: {
            apiId: props.apiId,
          },
          headers: { Authorization: props.user.token },
        })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            if (data.status === 1) {
              setRequestParam(data.requestParamList);
            } else {
              setRequestParam([]);
              setErrorMessage(data.message);
            }
          }
        })
        .catch((e) => {
          setErrorMessage(e);
        });
    }
  }, [props]);
  return (
    <div className="box-shadow-6">
      <div className="heading p-2">Request Parameter</div>
      {errorMessage !== "" ? (
        <div className="pl-2 pr-2 pb-2">
          <div className="alert alert-default">{errorMessage}</div>
        </div>
      ) : (
        ""
      )}

      {requestParam.length > 0 ? (
        <div>
          <table className="trans-report-table">
          <thead>
            <tr>
              <th>S.N</th>
              <th>API ID</th>
              <th>Name</th>
              <th>Default Value</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {requestParam.map((list, index) => (
              <tr key={list.id}>
                <td>{index + 1}</td>
                <td>{list.api_id}</td>
                <td>{list.param_name}</td>
                <td>{list.paramDefaultValue}</td>
                <td>{list.paramType}</td>
                <td>
                  {isLoading ? (
                    <div>Deleting...</div>
                  ) : (
                    <div
                      className="btn btn-danger"
                      onClick={() => deleteParam(list.id)}
                    >
                      Delete
                    </div>
                  )}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
