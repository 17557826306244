import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

export default function RechargesHistory(props) {
  const [trxnsHistoryList, setTrxnsHistoryList] = useState([]);
  const [stDate, setStDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [totalSale, setTotalSale] = useState("");
  const [number, setNumber] = useState("");
  const [txStatus, setStatusFilter] = useState("");
  const [userId, setUserId] = useState("");
  const [limit, setLimit] = useState("25");
  const [error, setError] = useState("");
  const [transType, setTransType] = useState("");
  const [colToShow, setColToShow] = useState("");
  const [operator, setOperator] = useState("");
  const [optId, setOptId] = useState("");
  const [remark, setRemark] = useState("");
  const [status, setStatus] = useState("");
  const [isLoading, setLoading] = useState(false);

  function UpdateRechargeTransactionHandle(e, request) {
    e.preventDefault();
    if (request.id !== null && status !== "") {
      setLoading(true);
      axios
        .post(
          "/api/admin/report/recharge/history/update/" + props.user.id,
          {
            userId: request.id,
            remark: remark,
            optId: optId,
            status: status,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setLoading(false);
            const data = res.data;
            if (data.status === 1) {
              alert(data.message);
              getHistory(e);
            } else {
              alert(data.message);
            }
          }
        })
        .catch((e) => {
          alert(e);
        });
    } else {
      alert("Please fill the form correctly");
    }
  }

  function getHistory(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .get("/api/admin/report/recharge/history/" + props.user.id, {
        params: {
          stDate: stDate,
          endDate: endDate,
          number: number,
          transType: transType,
          txStatus: txStatus,
          userId: userId,
          limit: limit,
          operator: operator,
        },
        headers: { Authorization: props.user.token },
      })
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          console.log(res);
          setLoading(false);

          if (data.status === 1) {
            setTrxnsHistoryList(data.rechargesHistoryList);
            setTotalSale(data.totalSale);
            setStartDate(data.startDate);
            setLastDate(data.lastDate);
          } else {
            setTrxnsHistoryList([]);
            setError(data.message);
            setStartDate(data.startDate);
            setLastDate(data.lastDate);
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  useEffect(() => {
    if (props.user.id != null) {
      setLoading(true);
      axios
        .get("/api/admin/report/recharge/history/" + props.user.id, {
          params: {
            stDate: "",
            endDate: "",
            number: "",
            userId: "",
            limit: "25",
            operator: "",
          },
          headers: { Authorization: props.user.token },
        })
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            if (data.status === 1) {
              setTrxnsHistoryList(data.rechargesHistoryList);
              setTotalSale(data.totalSale);
              setStartDate(data.startDate);
              setLastDate(data.lastDate);
            } else {
              setTrxnsHistoryList([]);
              setError(data.message);
              setStartDate(data.startDate);
              setLastDate(data.lastDate);
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === "Session Expire"
          ) {
            localStorage.clear();
            props.setLoggedIn(false);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [props]);

  return (
    <div>
      <div className="container">
        <div className="wrap" style={{ flexDirection: "column" }}>
          <div className="box-shadow-6 p-2">
            <form className="trans-search-wrap">
              <input
                type="date"
                className="form-control"
                id="fromDate"
                name="fromDate"
                onChange={(e) => setStDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control"
                id="toDate"
                name="toDate"
                onChange={(e) => setEndDate(e.target.value)}
              />
              <select
                className="form-control"
                value={transType}
                onChange={(e) => setTransType(e.target.value)}
              >
                <option value="">Transaction Type</option>
                <option value="Recharge">Recharge</option>
                <option value="DMT">DMT</option>
                <option value="Bill Pay">Bill Payment</option>
                <option value="FAStag">FAStag</option>
              </select>
              <input
                type="text"
                className="form-control"
                id="MobNum"
                name="MobNum"
                placeholder="Mobile Number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
              <input
                type="text"
                className="form-control"
                id="userId"
                name="userId"
                placeholder="Enter User Id"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
              <input
                type="text"
                className="form-control"
                id="OptrName"
                name="OptrName"
                placeholder="Operator Name"
                value={operator}
                onChange={(e) => setOperator(e.target.value)}
              />
              <select
                className="form-control"
                value={limit}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="">Status (All)</option>
                <option value="1">Success</option>
                <option value="0">Pending</option>
                <option value="2">Failure</option>
              </select>
              <select
                className="form-control"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value="">Limit</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="All">All</option>
              </select>

              <div className="form-group">
                <button
                  type="submit"
                  id="requestBtn"
                  name="requestBtn"
                  className="btn btn-primary"
                  onClick={isLoading ? null : getHistory}
                >
                  {isLoading ? "loading..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
          <div className="p-2"></div>
          <div className="heading">Transaction History</div>
          <div className="Count-Details">
            <div className="DateShow">
              {startDate !== "" ? startDate : "YYYY-MM-DD"} to{" "}
              {lastDate !== "" ? lastDate : "YYYY-MM-DD"}
            </div>
            <div className="AccStatementDetails">
              <div className="ShowBalance">
                Total Sale: {totalSale !== "" ? totalSale : 0}
              </div>
            </div>
          </div>
          <div className="trxns-report-card">
            {isLoading ? (
              <div
                className="p-2"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <CircularProgress />
              </div>
            ) : trxnsHistoryList.length > 0 ? (
              <table className="trans-report-table">
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Recharge Id</th>
                    <th>Username</th>
                    <th>Type</th>
                    <th>Operator Id</th>
                    <th>Vender Id</th>
                    <th>Operator Name</th>
                    <th>Circle</th>
                    <th>Date</th>
                    <th>Mobile No</th>
                    <th>Amount</th>
                    <th>Debit</th>
                    <th>Commission</th>
                    <th>Status</th>
                    <th>API Vender</th>
                  </tr>
                </thead>
                {trxnsHistoryList.map((transaction, index) => (
                  <tbody>
                    <tr
                      key={index}
                      onClick={() => {
                        colToShow === transaction.id
                          ? setColToShow("")
                          : setColToShow(transaction.id);
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{transaction.id}</td>
                      <td>{transaction.name}</td>
                      <td>{transaction.serviceType}</td>
                      <td>{transaction.operatorId}</td>
                      <td>{transaction.venderId}</td>
                      <td>{transaction.operatorName}</td>
                      <td>{transaction.circle}</td>
                      <td>
                        {moment(transaction.rechargeAt).format(
                          "YYYY-MM-DD h:mm:ss a"
                        )}
                      </td>
                      <td>{transaction.rechargeMobile}</td>
                      {transaction.amount !== null ? (
                        <td>₹{transaction.amount}</td>
                      ) : (
                        <td>0.00</td>
                      )}
                      {transaction.debit !== null ? (
                        <td>₹{transaction.debit}</td>
                      ) : (
                        <td>0.00</td>
                      )}
                      {transaction.commission !== null ? (
                        <td>₹{transaction.commission}</td>
                      ) : (
                        <td>0.00</td>
                      )}
                      {transaction.rechargeStatus === "1" ? (
                        <td className="text-success">Success</td>
                      ) : transaction.rechargeStatus === "3" ? (
                        <td className="text-failure">Refunded</td>
                      ) : transaction.rechargeStatus === "2" ? (
                        <td className="text-failure">Failure</td>
                      ) : (
                        <td className="text-pending">Pending</td>
                      )}
                      <td>{transaction.vendorName}</td>
                    </tr>
                    {colToShow === transaction.id ? (
                      <tr>
                        <td colSpan="15" style={{ textAlign: "left" }}>
                          <form method="POST">
                            <div style={{display:"flex", flexDirection:"column"}}>
                              <div style={{paddingBottom:"5px", lineHeight:'20px'}}><span style={{fontSize:"16px", fontWeight:"500"}}>Request From Agent/API Partner :- </span> {transaction.requestLog}</div>
                              <div style={{paddingBottom:"5px", lineHeight:'20px'}}><span style={{fontSize:"16px", fontWeight:"500"}}>Response to Agent/API Partner :- </span> {transaction.responseLog}</div>
                              <div style={{paddingBottom:"5px", lineHeight:'20px'}}><span style={{fontSize:"16px", fontWeight:"500"}}>Request to API Vender :- </span> {transaction.apiRequestLog}</div>
                              <div style={{paddingBottom:"20px", lineHeight:'20px'}}><span style={{fontSize:"16px", fontWeight:"500"}}>Response from API Vender :- </span> {transaction.apiResponseLog} </div>
                            </div>
                            <span>Remark :- {transaction.remark}</span>
                            &nbsp;&nbsp;
                            <input
                              type="text"
                              id="remarkMsg"
                              name="remarkMsg"
                              className="inputEditbox"
                              placeholder="Remark"
                              value={remark}
                              onChange={(e) => setRemark(e.target.value)}
                            />
                            <input
                              type="text"
                              id="optId"
                              name="optId"
                              className="inputEditbox"
                              placeholder="Operator id"
                              value={optId}
                              onChange={(e) => setOptId(e.target.value)}
                            />
                            <select
                              className="inputEditbox"
                              onChange={(e) => setStatus(e.target.value)}
                              value={status}
                            >
                              <option value="">Select Request</option>
                              <option value="1">Success</option>
                              <option value="2">Failure</option>
                            </select>
                            <button
                              className="btn btn-primary"
                              style={{
                                margin: " 0px 10px",
                                padding: "8px 20px",
                              }}
                              onClick={(e) => {
                                UpdateRechargeTransactionHandle(e, transaction);
                              }}
                            >
                              Update
                            </button>
                          </form>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                ))}
              </table>
            ) : (
              <div className="p-1">
                <div className="heading">No Transaction Available</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
