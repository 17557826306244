import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";
import axios from "axios";
import EkoAEPSGateway from "./aeps_lib";
import AepsTabs from "../Aeps_Tabs/AepsTabs";
export default function ShowAepsBalance(props) {
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState("0.0");
  const [beneficiaryList, setBeneficiaryList] = useState(null);
  const [settlementList, setEttlementList] = useState([]);

  const loginAeps = () => {
    setLoading(true);

    axios
      .post(
        "/api/aeps/aepslogin/" + props.user.id,
        {},
        {
          headers: { Authorization: props.user.token },
        }
      )
      .then((res) => {
        setLoading(false);

        if (res.status === 200) {
          const data = res.data.response;
          console.log(res);
          setTimeout(() => {
            var aeps = new EkoAEPSGateway();

            aeps.config({
              partner_name: "BS Recharge",
              initiator_logo_url: "https://bsrecharge.in/logo.png",
              initiator_id: "" + data.initiatorId,
              developer_key: data.developerKey,
              secret_key: data.secretKey,
              secret_key_timestamp: "" + data.secretTimeStamp,
              user_code: data.AEPSID,
              environment: data.environment === "test" ? "uat" : "live",
            });

            // Configure callback URL for transaction-confirmation and for getting final result...
            aeps.setCallbackURL(data.callbackUrl);

            aeps.open();
          }, 500);
          if (res.data.status === 3 || res.data.message === "Session Expire") {
            setLoading(false);
            localStorage.clear();
            props.setLoggedIn(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function getBalance() {
    axios
      .post(
        "/api/aeps/getbalance/" + props.user.id,
        {},
        {
          headers: { Authorization: props.user.token },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          console.log(res);
          if (data.status === 1) {
            setBalance(data.balance);
          } else if (
            res.data.status === 3 &&
            res.data.message === "Session Expire"
          ) {
            localStorage.clear();
            props.setLoggedIn(false);
          } else {
            alert(data.message);
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function getBeneficiaryHandler() {
    axios
      .post(
        "/api/aeps/getbeneficiary/" + props.user.id,
        {},
        {
          headers: { Authorization: props.user.token },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          console.log(res);
          if (data.status === 1) {
            setBalance(data.balance);
            setBeneficiaryList(data.list != null ? data.list : []);
            setEttlementList(data.trList);
          } else if (
            res.data.status === 3 &&
            res.data.message === "Session Expire"
          ) {
            localStorage.clear();
            props.setLoggedIn(false);
          } else {
            alert(data.message);
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  React.useEffect(() => {
    function getBeneficiaryHandler() {
      axios
        .post(
          "/api/aeps/getbeneficiary/" + props.user.id,
          {},
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            if (data.status === 1) {
              setBalance(data.balance);
              setBeneficiaryList(data.list);
              setEttlementList(data.trList);
            } else if (
              res.data.status === 3 &&
              res.data.message === "Session Expire"
            ) {
              localStorage.clear();
              props.setLoggedIn(false);
            } else {
              alert(data.message);
            }
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
    getBeneficiaryHandler();
  }, [props]);

  return (
    <>
      <div className="aeps_card" style={{ padding: "30px 0px" }}>
        <div className="subHeading text-center ">Withdrawal Balance</div>
        <div
          className="text-center"
          style={{ paddingTop: "10px", paddingBottom: "20px" }}
        >
          <span>₹{balance}</span>
          <i
            className="material-icons"
            onClick={getBalance}
            style={{
              verticalAlign: "middle",
              fontSize: "20px",
              cursor: "pointer",
              paddingLeft: "5px",
            }}
          >
            autorenew
          </i>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" color="primary" onClick={loginAeps}>
              Withdrawal / Balance Enquiry
            </Button>
          )}
        </div>
      </div>
      <AepsTabs
        {...props}
        getBeneficiary={getBeneficiaryHandler}
        getBalance={getBalance}
        beneficiarylist={beneficiaryList}
        settlementlist={settlementList}
      />
    </>
  );
}
