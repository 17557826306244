import axios from 'axios'
import React, { useState } from 'react'
import moment from 'moment'
import { CircularProgress } from '@mui/material';

export default function RechargeStatus (props) {
  const [mobileNum, setMobileNum] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [rechargeStatusReport, setRechargeStatusReport] = useState([])

  function getRechargeStatus (e) {
    e.preventDefault()
    setLoading(true)
    if (mobileNum !== null && mobileNum !== undefined && mobileNum !== '') {
      axios
        .post(
          '/api/recharge/status/' + props.user.id,
          {
            number: mobileNum
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setRechargeStatusReport(data.rechargeStatusReport)
            } else if (data.status === 2) {
              setRechargeStatusReport([])
              setError(data.message)
            }
          } else {
            setError(res.data.message)
          }
        })
        .catch(error => {
          setError(error.message)
        })
    } else {
      setError('Empty Details')
    }
  }

  return (
    <div className='container'>
      <div className='wrap' style={{ flexDirection: 'column' }}>
        <div className='box-shadow-6 p-2'>
          <div className='heading' style={{ margin: '10px 0px 10px 10px' }}>
            Recharge Status
          </div>

          <form className='status-search-wrap'>
            <input
              type='text'
              className='form-control'
              id='searchMobNo'
              name='searchMobNo'
              placeholder='Mobile Number'
              onChange={e => setMobileNum(e.target.value)}
            />
            <div className='form-group'>
              <button
                type='submit'
                id='requestBtn'
                name='requestBtn'
                className='btn btn-primary'
                onClick={getRechargeStatus}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        {isLoading ? (
          <div
            className='p-2'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <CircularProgress />
          </div>
        ) : (
          rechargeStatusReport.length > 0 ? (
        <div className='Rech-status-view-card'>
          
            <table className='Rech-status-view-table'>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Recharge Date</th>
                  <th>Service Type</th>
                  <th>Operator</th>
                  <th>Mobile Number</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {rechargeStatusReport.map((transaction, index) => (
                  <tr key={transaction.id.toString()}>
                    <td>{index + 1}</td>
                    <td>
                      {moment(transaction.rechargeAt).format(
                        'YYYY-MM-DD h:mm:ss a'
                      )}
                    </td>
                    <td>{transaction.serviceType}</td>
                    <td>{transaction.operatorName}</td>
                    <td>{transaction.rechargeMobile}</td>
                    <td>{transaction.amount}</td>
                    {transaction.rechargeStatus === '1' ? (
                      <td className='text-success'>Success</td>
                    ) : transaction.rechargeStatus === '3' ? (
                      <td className='text-failure'>Refunded</td>
                    ) : transaction.rechargeStatus === '2' ? (
                      <td className='text-failure'>Failure</td>
                    ) : (
                      <td className='text-pending'>Pending</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          
        </div>
        ) : (
            <div className={error !== '' ? ' box-shadow-6 heading p-1' : ''}>
              {error}
            </div>
          ))}
      </div>
    </div>
  )
}
