import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../Components/LoginPage/Login";
import AdminProtected from "../Components/Admin/AdminProtected.js";
import Page404 from "../Page404";
import UserInfo from "../Components/Admin/users/UserInfo";
import usersList from "../Components/Admin/users/usersList";
import FundTransfer from "../Components/Admin/users/FundTransfer";
import PaymentRequest from "../Components/Admin/Payment_Request/PaymentRequest";
import CreateUsers from "../Components/Admin/users/CreateUsers";
import Complaints from "../Components/Admin/Complaints/Complaints";
import RechargesHistory from "../Components/Admin/Report/RechargesHistory";
import AdminHome from "../Components/Admin/HomePage/AdminHome.js";
import AccountStatement from "../Components/Admin/Report/AccountStatement";
import TopUpHistory from "../Components/Admin/Report/TopUpHistory";
import OperatorReport from "../Components/Admin/Report/OperatorReport";
import ApiReport from "../Components/Admin/Report/ApiReport";
import Setting from "../Components/Admin/Security/Setting";
import Package from "../Components/Admin/Package/Package";
import ApiConfig from "../Components/Admin/Api_Configuration/ApiConfig";
import ApiParamter from "../Components/Admin/Api_Configuration/ApiParamter";
import AddOperator from "../Components/Admin/Package/AddOperator";
import DMTHistory from '../Components/Admin/Report/DmtHistory';
import AepsHistory from "../Components/Admin/Report/AepsHIstory";
import AepsSettlementHistory from "../Components/Admin/Report/AepsSettlementHistory";
import RedirectHomePage from "../Components/LoginPage/RedirectHomePage";

export default function AdminRouter() {
  return (
    <Switch>
      <AdminProtected exact path="/" page={AdminHome} />
      <AdminProtected exact path="/new_user" page={CreateUsers} />
      <AdminProtected exact path="/users" page={usersList} />
      <AdminProtected exact path="/user/info" page={UserInfo} />
      <AdminProtected exact path="/user/fund-transfer" page={FundTransfer} />
      <AdminProtected
        exact
        path="/report/transaction_history"
        page={RechargesHistory}
      />
      <AdminProtected
        exact
        path="/report/dmt_history"
        page={DMTHistory}
      />
      <AdminProtected
        exact
        path="/report/aeps_history"
        page={AepsHistory}
      />
      <AdminProtected
        exact
        path="/report/settlement_history"
        page={AepsSettlementHistory}
      />
      <AdminProtected
        exact
        path="/report/account_statement"
        page={AccountStatement}
      />
      <AdminProtected exact path="/report/topup_history" page={TopUpHistory} />
      <AdminProtected
        exact
        path="/report/operator_report"
        page={OperatorReport}
      />
      <AdminProtected exact path="/report/api_report" page={ApiReport} />
      <AdminProtected exact path="/payment_request" page={PaymentRequest} />
      <AdminProtected exact path="/complain" page={Complaints} />
      <AdminProtected exact path="/settings" page={Setting} />
      <AdminProtected exact path="/packageList" page={Package} />
      <AdminProtected exact path="/configApi" page={ApiConfig} />
      <AdminProtected exact path="/configApi/requestParam" page={ApiParamter} />
      <AdminProtected exact path="/package/add_operator" page={AddOperator} />
      <Route exact path="/logout" component={RedirectHomePage} />
      <Route exact path="/login" component={Login} />
      <Route path="*" component={Page404} />
    </Switch>
  );
}
