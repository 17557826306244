import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "../utility.css";

export default function FastagRechargeForm(props) {
  const [isLoading, setLoading] = useState(false);
  const [operatorList, setOperatorList] = useState([]);
  const [detail, setDetail] = useState(null);
  const [operatorName, setOperatorName] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [isError, setError] = useState("");
  const history = useHistory();

  const fetchFastagDetail = (e) => {
    e.preventDefault();
    if (operatorName !== "" && vehicleNumber !== "") {
      setLoading(true);
      axios
        .post("/api/fastag/fetch_customer_detail/" + props.user.id, 
        {
          'operatorId': detail.id,
          'number': vehicleNumber,
        }, 
        {
          headers:{Authorization:props.user.token}
        })
        .then((res) => {
          if(res.status === 200){
            console.log(res);
              const data = res.data;
              if(data.status === 1){
                setLoading(false);
                history.push('/fastag/confirm', {
                  user_name: data.consumerName,
                  vehicleNumber: data.vehicleNumber,
                  fastag_amount: data.fastagAmount,
                  partPay: data.partPay,
                  operatorId: detail.id,
                  operatorName: operatorName
                });
              }else if (
              res.data.status === 3 &&
              res.data.message === "Session Expire"
            ) {
              localStorage.clear();
              props.setLoggedIn(false);
            } else {
              setLoading(false);
              setError(data.message);
            }
          }
        })
        .catch((e) => {
          setError(e);
        });
    } else {
      setError("Please filled all text fields.");
    }
  };

  useEffect(() => {
    if (props.user.id !== null) {
      setLoading(true);
      axios
        .post(
          "/api/fastag/getoperators/" + props.user.id,
          {},
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            const data = res.data;
            setLoading(false);
            if (data.status === 1) {
              setOperatorList(data.list);
            } else if (
              res.data.status === 3 &&
              res.data.message === "Session Expire"
            ) {
              localStorage.clear();
              props.setLoggedIn(false);
            } else {
              setOperatorList([]);
              setError(data.message);
            }
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [props]);

  return (
    <div>
      {isLoading ? (
        <div
          className="p-4"
          style={{ display: "flex", justifyContent: "center", paddingTop:"100px"}}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className="container">
          <div className="content-wrap">
            <div className="heading">Fastag Recharge</div>
            <div className="box-shadow-6 p-2">
            {isError !== '' ? (
                <div className='heading p-2'>{isError}</div>
              ) : (
                ''
              )}
              <form className="utility-services-form">
                <select
                  className="form-control"
                  onChange={(e) => {
                    setOperatorName(operatorList[e.target.value].name);
                    console.log(e.target.value);
                    setDetail(operatorList[e.target.value]);
                    console.log(detail);
                  }}
                >
                  <option>Select Operator List</option>
                  {operatorList.map((h, i) => {
                    return (
                      <option key={i} value={i}>
                        {h.name}
                      </option>
                    );
                  })}
                </select>
               
                {detail !== "" && detail !== null && detail !== undefined ? (
                  <input
                    type="text"
                    className="form-control"
                    id="vehicleNumber"
                    name="vehicleNumber"
                    placeholder={"Enter " + detail.displayname}
                    value={vehicleNumber}
                    onChange={(e) => setVehicleNumber(e.target.value)}
                  />
                ) : (
                  ""
                )}
                <div className="form-group">
                  <button
                    type="submit"
                    id="requestBtn"
                    name="requestBtn"
                    className="btn btn-primary"
                    onClick={isLoading ? null : fetchFastagDetail}
                  >
                    {isLoading ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
