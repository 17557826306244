import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./recharge.css";

export default function MobileRecharge(props) {
  const [operator, setOperator] = useState("");
  const [dishNum, setDishNum] = useState("");
  const [circle, setCircle] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [isloading, setLoading] = useState(false);
  const history = useHistory();
  function DTHRechargeHandle(e) {
    e.preventDefault();

    if (
      operator !== null &&
      operator !== undefined &&
      dishNum !== null &&
      dishNum !== undefined &&
      circle !== null &&
      circle !== undefined &&
      amount !== null &&
      amount !== undefined &&
      amount >= "10"
    ) {
      setLoading(false);
      history.push("confirm", {
        rechargeType: "DTH",
        operator: operator,
        num: dishNum,
        circle: circle,
        amount: amount,
      });
    } else {
      setLoading(false);
      setError("Empty Details");
    }
  }
  return props.user.accountType === "RT" ? (
    <div>
      <div className="container">
        <div className="p-2">
          <div className="bg-white box-shadow-6 p-2">
            <div className="heading">DTH Recharge</div>
            <form>
              <div className={error !== "" ? "errorClass" : ""}>{error}</div>
              <div className="input-form-group">
                <select
                  className="Mob-form-control"
                  onChange={(e) => setOperator(e.target.value)}
                >
                  <option value="">Select Operator</option>
                  <option value="Airtel Digital TV">Airtel Digital TV</option>
                  <option value="Dish TV">Dish TV</option>
                  <option value="D2H">D2H</option>
                  <option value="Sun Direct">Sun Direct</option>
                  <option value="Tata Sky">Tata Sky</option>
                </select>

                <input
                  type="text"
                  className="Mob-form-control"
                  id="DishNum"
                  name="DishNum"
                  placeholder="Subscriber Number"
                  onChange={(e) => setDishNum(e.target.value)}
                />
                <select
                  className="Mob-form-control"
                  onChange={(e) => setCircle(e.target.value)}
                >
                  <option value="">Select Cirle</option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar Jharkhand">Bihar Jharkhand</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Delhi NCR">Delhi NCR</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jammu Kashmir">Jammu Kashmir</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Kolkata">Kolkata</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Mumbai">Mumbai</option>
                  <option value="North East">North East</option>
                  <option value="Orissa">Orissa</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Up East">Up East</option>
                  <option value="Up West">UP West</option>
                  <option value="West Bengal">West Bengal</option>
                </select>
                <input
                  type="text"
                  className="Mob-form-control"
                  id="RechAmount"
                  name="RechAmount"
                  placeholder="Amount"
                  onChange={(e) => setAmount(e.target.value)}
                />

                <div className="form-group" style={{ margin: "0" }}>
                  <button
                    type="submit"
                    id="requestBtn"
                    name="requestBtn"
                    className="btn btn-primary"
                    onClick={DTHRechargeHandle}
                  >
                    {isloading ? "Loading..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="container">
        <div className="p-2">
          <div className="bg-white box-shadow-6 p-2">
            <h1>Unautherise Access</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
