import React from 'react'
import axios from 'axios'

export default function AddBeneficiary (props) {
  const { setTab, wallet, getBeneficiaryHandler } = props
  const [error, setError] = React.useState('')
  const [name, setName] = React.useState('')
  const [accountNum, setAccountNum] = React.useState('')
  const [bankName, setBankName] = React.useState('')
  const [bankIfscCode, setBankIfscCode] = React.useState('')
  const [beneficiaryMobileNumber, setBeneficiaryMobileNumber] = React.useState(
    ''
  )
  const bankNameList = [
    'Axis Bank',
    'State bank of India',
    'Bank of Baroda',
    'HDFC Bank Ltd',
    'ICICI Bank',
    'Canara Bank',
    'Punjab National Bank',
    'Yes Bank',
    'A.P.Mahesh Co-Op Urban Bank',
    'Abhyudaya Co-Op Bank',
    'ABN Amro Bank Credit Card',
    'Abu Dhabi Commercial Bank',
    'Adarsh Co-Op Bank, Rajasthan',
    'Adarsh Mahila Mercantile Co-Op Bank',
    'Adarsh Urban Co-Op Bank, Hyderabad',
    'Ahmedabad District Central Co-Op Bank',
    'Ahmedabad Mercantile Co-Op Bank',
    'Ahmednagar Mer Co-Op Bank',
    'Airtel Payments Bank',
    'Akhand AnandÂ Co-Op Bank',
    'Akola District Central Co-Op Bank',
    'Akola Janata Commercial Co-Op Bank',
    'Allahabad Bank',
    'Allahabad UP Gramin Bank',
    'Almora Urban Co-Op Bank',
    'Ambarnath Jai-Hind Co-Op Bank Limited',
    'American Express Credit Card',
    'Andhra Bank',
    'Andhra Bank Credit Card',
    'Andhra Pradesh Grameena Vikas Bank',
    'Andhra Pradesh State Co-Op Bank',
    'Andhra Pragathi Grameena Bank',
    'Apna Sahakari Bank',
    'Arunachal Pradesh Rural Bank',
    'Aryavart Gramin Bank',
    'Assam Co-Op Apex Bank',
    'Assam Gramin Vikash Bank',
    'AU Small Finance Bank',
    'Baitarani Gramin Bank',
    'Ballia Etawah Gramin Bank',
    'Bandhan Bank',
    'Bangiya Gramin Bank',
    'Bank of America',
    'Bank of Bahrain and Kuwait',
    'Bank Of Baroda Credit Card',
    'Bank of Ceylon',
    'Bank of India',
    'Bank Of India Credit Card',
    'Bank of Maharashtra',
    'Bank of Nova Scotia',
    'Bank of Tokyo-Mitsubishi UFJ',
    'Baramati Sahakari Bank',
    'Barclays Bank',
    'Barclays Credit Card',
    'Baroda Gujarat Gramin Bank',
    'Baroda Rajasthan Gramin Bank',
    'Baroda Uttar Pradesh Gramin Bank',
    'Bassein Catholic Co-Op Bank',
    'Bhagalpur Central Co-Op Bank',
    'Bharat Co-Op Bank, Mumbai',
    'Bhartiya Mahila Bank',
    'Bihar Kshetriya Gramin Bank',
    'Bijnor Urban Co-Op Bank',
    'BNP Paribas',
    'Bombay Mercantile Co-Op Bank',
    'Canara Bank Credit Card',
    'Capital Small Finance Bank',
    'Catholic Syrian Bank',
    'Central Bank of India',
    'Chaitanya Godavari Grameena Bank',
    'CHARTERED SAHAKARI BANK NIYAMITHA',
    'Chhattisgarh Gramin Bank',
    'Chickmangalur Kodagu Gramin Bank',
    'Chikhli Urban Co-Op Bank',
    'Chinatrust Commercial Bank',
    'Citibank',
    'Citibank Credit Card',
    'Citizen Co-Op Bank, Noida',
    'Citizen Credit Co-Op Bank',
    'City Union Bank',
    'Corporation Bank',
    'Cosmos Co-Op Bank',
    'Credit Agricole Corp and Investment Bank',
    'Dapoli Urban Co-Op Bank',
    'DBS BANK',
    'Deccan Merchants Co-Op Bank',
    'Delhi State Co-Op Bank',
    'Dena Bank',
    'Dena Gujarat Gramin Bank',
    'Deposit Insurance and Credit Guarantee Corporation - DICGC',
    'Deutsche Bank AG',
    'Development Credit Bank',
    'Dhanlaxmi Bank',
    'District Co-Op Bank, Agra',
    'Dombivli East',
    'Dombivli Nagari Sahakari Bank',
    'Dr. Annasaheb Chougule Urban Co-Op Bank',
    'Durg Rajnandgaon Gramin Bank',
    'Ellaqui Dehati Bank',
    'Equitas Small Finance Bank',
    'ESAF Small Finance Bank',
    'Etah District Co-Op bank',
    'Federal Bank',
    'FINO Payments Bank',
    'Firstrand Bank',
    'Gayatri Bank',
    'Greater Bombay Co-Op Bank',
    'Gujarat State Co-Op Bank',
    'Gurgaon Gramin Bank',
    'Hadoti Kshetriya Gramin Bank',
    'Hamirpur District Co-Op Bank, Mahoba',
    'Hasti Co-Op Bank',
    'HDFC Bank Credit Card',
    'Himachal Gramin Bank',
    'Himachal Pradesh State Co-Op Bank',
    'Hindusthan Co-Op Bank',
    'HSBC',
    'HSBC Credit Card',
    'Hutatma Sahakari Bank',
    'ICICI Bank Credit Card',
    'IDBI Bank',
    'IDBI Bank Credit Card',
    'IDFC Bank',
    'India Post Payments Bank',
    'Indian Bank',
    'Indian Overseas Bank',
    'IndusInd Bank',
    'IndusInd Bank Credit Card',
    'ING Vysya Bank',
    'Integral Urban Co-Op Bank',
    'Irinjalakuda Town Co-Op Bank',
    'J&K Grameen Bank',
    'Jaipur Thar Gramin Bank',
    'Jalaun District Co-Op Bank',
    'Jalgaon Peoples Co-Op Bank',
    'Jalna Merchant Co-Op Bank',
    'Jalore Nagrik Sahakari Bank',
    'Jamia Co-Op Bank',
    'Jammu and Kashmir Bank',
    'Janakalyan Sahakari Bank',
    'Janaseva Sahakari Bank',
    'Janata Co-Op Bank, Malegaon',
    'Janata Sahakari Bank, Osmanabad',
    'Janata Sahakari Bank, Pune',
    'Jhabua Dhar Kshetriya Gramin Bank',
    'Jharkhand Gramin Bank',
    'Jharkhand State Co-Op Bank',
    'Jijamata Mahila Sah Bank Ltd Pune',
    'Jila Sahakari Kendriya Bank Mydt Tikamgarh',
    'Jila Sahkari Kendriya Bank Maryadit',
    'JP Morgan Chase Bank',
    'Kaira District Central Co-Op Bank',
    'Kalinga Gramya Bank',
    'Kallappanna Awade Ichalkaranji Janata Sahkari Bank',
    'Kalupur Commercial Co-Op Bank',
    'Kalyan Janata Sahakari Bank',
    'Kanaka Mahalakshmi Co-Op Bank',
    'Kangra Central Co-Op Bank',
    'Kangra Co-Op Bank',
    'Kapole Co-Op Bank',
    'Karad Urban Co-Op Bank',
    'Karnataka Bank',
    'Karnataka State Apex Co-Op',
    'Karnataka State Co-Op Apex Bank',
    'Karnataka Vikas Grameena Bank',
    'Karur Vysya Bank',
    'Kashi Gomati Samyut Gramin Bank',
    'Kaveri Grameena Bank',
    'Kerala Gramin Bank',
    'Kotak Mahindra Bank',
    'Kotak Mahindra Credit Card',
    'Kottayam Co-Op Urban Bank',
    'Krishna Gramin Bank',
    'Kurmanchal Nagar Sahkari Bank',
    'Lakshmi Vilas Bank',
    'Langpi Dehangi Rural Bank',
    'Lokmangal Co-Op Bank',
    'Madhya Bharat Gramin Bank',
    'Madhya Bihar Gramin Bank',
    'Madhyanchal Gramin Bank, Sagar',
    'Mahakaushal Kshetriya Gramin Bank',
    'Mahanagar Co-Op Bank',
    'Maharashtra Gramin Bank',
    'Maharashtra State Co-Op Bank',
    'Malad Sahkari Bank',
    'Malda District Central Co-Op Bank',
    'Malwa Gramin Bank',
    'Manipur Rural ANK',
    'Manvi Pattana Souharda Sahakari Bank',
    'Maratha Co-Op Bank',
    'Markandey Nagari Sahakari Bank',
    'Mashreq Bank PSC',
    'Mayani Urban Co-Op Bank',
    'Meghalaya Rural Bank',
    'Mehsana Urban Co-Op Bank',
    'Mewar Anchalik Gramin Bank',
    'MG Baroda Gramin Bank',
    'MGCB Main',
    'Mizoram Rural Bank',
    'Mizuho Corporate Bank',
    'Mogaveera Co-Op Bank',
    'Moradabad Zila Sahkari Bank',
    'Mumbai District Central Co-Op Bank',
    'Municipal Co-Op Bank',
    'Murshidabad District Central Co-Op Bank',
    'Nagar Sahkari Bank',
    'Nainital Almora Kshetriya Gramin Bank',
    'Nainital Bank',
    'Nanded Disctrict Central Co-Op Bank',
    'Narmada Malwa Gramin Bank',
    'Nashik Merchants Co-Op Bank',
    'National Co-Op Bank',
    'Neelachal Gramya Bank',
    'NEFT Malwa Gramin Bank',
    'New India Co-Op Bank',
    'NKGSB Co-Op Bank',
    'Noble Co-Op Bank',
    'North Malabar Gramin Bank',
    'Nutan Nagarik Sahakari Bank',
    'Odisha Gramya Bank',
    'Odisha State Co-Op Bank',
    'Oman International Bank Saog',
    'Oriental Bank of Commerce',
    'Pachora Peoples Co-Op Bank',
    'Pallavan Grama Bank',
    'Pandharpur Merchant Co-Op Bank',
    'Pandharpur Urban Co-Op Bank',
    'Pandyan Gramin Bank',
    'Panipat Urban Co-Op Bank',
    'Parshwanath Co-Op Bank',
    'Parsik Janata Sahakari Bank',
    'Parvatiya Gramin Bank',
    'Paschim Banga Gramin Bank',
    'Pavana Sahakari Bank',
    'Paytm Payments Bank',
    'Pithoragarh Jila Sahkari Bank',
    'Pochampally Co-Op Urban Bank',
    'Poornawadi Nagrik Sahakari Bank',
    'Pragathi Gramin Bank',
    'Pragathi Krishna Gramin Bank',
    'Prathama Bank',
    'Prime Co-Op Bank',
    'Priyadarshani Nagari Sahakari Bank',
    'Puduvai Bharathiar Grama Bank',
    'Pune Cantonment Sahakari Bank',
    'PUNE DISTRICT CENTRAL CO-OP BANK',
    'Pune Peoples Co-Op Bank',
    'Punjab and Maharashtra Co-Op Bank',
    'Punjab and Sind Bank',
    'Punjab Gramin Bank',
    'Punjab National Bank Credit Card',
    'Purvanchal Gramin Bank',
    'Raigad District Central Co-Op Bank',
    'Raipur Urban Mercantile Co-Op Bank',
    'Rajapur Urban Co-Op Bank',
    'Rajarshi Shahu Sah Bank, Pune',
    'Rajasthan Gramin Bank',
    'Rajasthan Marudhara Gramin Bank',
    'Rajgurunagar Sahakari Bank',
    'Rajkot Nagarik Sahakari Bank',
    'Ratnagiri District Central Co Operative Bank Ltd',
    'Ratnakar Bank',
    'RBL Bank Credit Card',
    'Reserve Bank of India',
    'Rewa-Sidhi Gramin Bank',
    'Royal Bank of Scotland',
    'Rushikulya Gramin Bank',
    'Sabarkantha District Central Co-Op Bank',
    'Sadhana Sahakari Bank',
    'Sahebrao Deshmukh Co-Op Bank',
    'Samastipur Kshetriya Gramin Bank',
    'Sangamner Merchant Co-Op Bank',
    'Sangli District Central Co-Op Bank',
    'Sant Sopankaka Sahkari Bank, Saswad',
    'Sapthagiri Grameena Bank',
    'Saraswat Co-Op Bank',
    'Sardar Bhiladwala Pardi Peoples Co-Op Bank',
    'Sarva Haryana Gramin Bank',
    'Sarva UP Gramin Bank',
    'Satpura Narmada Kshetriya Gramin Bank',
    'Saurashtra Co-Op Bank',
    'Saurashtra Gramin Bank',
    'Seva Vikas Co-Op Bank',
    'Shamrao Vithal Co-Op Bank',
    'Sharad Sahakari Bank, Manchar',
    'Sharda Gramin Bank',
    'Shinhan Bank',
    'Shirpur Peoples Co-Op Bank',
    'Shivajirao Bhosale Sahakari Bank',
    'Shivalik Mercantile Co-Op Bank',
    'Shree Mahalaxmi Co-Op Bank',
    'Shree Sharada Sahakari Bank',
    'Shree Veershaiv Co-Op Bank',
    'Shreyas Gramin Bank',
    'Shri Arihant Co-Op Bank',
    'Shri Basaveshwar Sahakari Bank Niyamit Bagalkot',
    'Shri Chhatrapati Rajarshi Shahu Urban Co-Op Bank',
    'Sindhudurg District Central Co-Op Bank',
    'Siwan Central Co-Op Bank',
    'Societe Generale',
    'Solapur Janata Sahakari Bank',
    'South Indian Bank',
    'South Malabar Gramin Bank',
    'Standard Chartered Bank',
    'Standard Chartered Credit Card',
    'State Bank of India Credit Card',
    'State Bank of Mauritius',
    'Suco Souharda Sahakari Bank',
    'Surat District Co-Op Bank',
    'Surat National Co-Op Bank',
    'Surat Peoples Co-Op Bank',
    'Surguja Kshetriya Gramin Bank',
    'Suryoday Small Finance Bank',
    'Sutex Co-Op Bank',
    'Sutlej Gramin Bank',
    'Suvarnayug Sahakari Bank',
    'Swarna Bharat Trust Cyber Grameen',
    'Syndicate Bank',
    'Tamilnad Mercantile Bank',
    'Tamilnadu State Apex Co-Op Bank',
    'Telangana Grameena Bank',
    'Telangana State Co-Op Apex Bank',
    'Thane Bharat Sahakari Bank',
    'Thane Janata Sahakari Bank',
    'The Gandhinagar Nagrik Cooperative Bank Ltd',
    'THE GANDHINAGAR URBAN CO. BANK LTD',
    'The Khamgaon Urban Co-Operative Bank Ltd',
    'The Muslim Co Operative Bank Ltd',
    'The Rajasthan State Cooperative Bank Ltd',
    'THE SATARA DISTRICT CENTRAL CO-OPERATIVE BANK LTD',
    'The Thane District Central Co-Op Bank',
    'The Udaipur Mahila Samridhi Urban Cooperative Bank Ltd',
    'Thrissur District Central Co-Op Bank',
    'Titwala',
    'Tripura Gramin Bank',
    'Triveni Kshetriya Gramin Bank',
    'UBS AG',
    'UCO Bank',
    'Udaipur Urban Co-Op Bank',
    'Ujjivan Small Finance Bank',
    'Union Bank of India',
    'United Bank of India',
    'Urban Co-Op Bank',
    'Urban Co-Op Bank, Siddharthanagar',
    'UTI Axis Bank Credit Card',
    'Utkal Gramya Bank',
    'Uttar Banga Kshetriya Gramin Bank',
    'Uttar Bihar Gramin Bank',
    'Uttarakhand Gramin Bank',
    'Uttarakhand Gramin Bank',
    'Vaidyanath Urban Co-Op Bank',
    'VALSAD DISTRICT CENTRAL CO-OPERATIVE BANK LTD',
    'Vananchal Gramin Bank',
    'Varachha Co-Op Bank',
    'Vasai Vikas Co-Op Bank',
    'Vidharbha Kshetriya Gramin Bank',
    'Vidisha Bhopal Kshetriya Gramin Bank',
    'Vijaya Bank',
    'Vijaya Credit Card',
    'Vikas Souharda Co-Op Bank',
    'Vishweshwar Co-Op Bank',
    'Visveshwaraya Gramin Bank',
    'Wainganga Krishna Gramin Bank',
    'West Bengal State Co-Op Bank',
    'Yadagiri Lakshmi Narasimha Swamy Co-Op Urban Bank',
    'Zila Sahakari Bank Haridwar',
    'Zila Sahkari Bank',
    'Zila Sahkari Bank, Lucknow',
    'Zoroastrian Co-Op Bank'
  ]
  function mobileNumberMaxLength (object) {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      )
    }
  }
  function addBeneficiaryHandler (e) {
    e.preventDefault()
    if (
      name !== null &&
      name !== '' &&
      bankName !== undefined &&
      bankName !== null &&
      accountNum !== null &&
      accountNum !== '' &&
      bankIfscCode !== '' &&
      bankIfscCode !== null &&
      wallet !== undefined
    ) {
      axios
        .post(
          '/api/sendmoney/addBeneficiary/' + props.user.id,
          {
            number: wallet,
            name: name,
            account: accountNum,
            ifsc: bankIfscCode,
            bank: bankName,
            beneMobile: beneficiaryMobileNumber
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setAccountNum('')
              setBeneficiaryMobileNumber('')
              setBankIfscCode('')
              setName('')
              setBankName('')
              getBeneficiaryHandler()
              alert(data.message)
              setTab('list')
            } else {
              setError(data.message)
            }
          }
        })
        .catch(error => {
          setError(error)
        })

      // var beneId = '23242342342' //from server
      // var bene = beneficiary
      // bene.push({
      //   id: beneId,
      //   name: name,
      //   account: accountNum,
      //   bank: bankName,
      //   ifscCode: bankIfscCode,
      //   isVerified: false,
      //   accountName: '',
      //   status: '1'
      // })

      // setBeneficiary(bene)
    } else {
      setError('Please fill empty fields.')
    }
  }

  return (
    <>
      <div className='mt-1 wallet_card'>
        <div className='subHeading p-1'>Add Beneficiary</div>
        {error ? <div className='subHeading p-1'>{error}</div> : ''}
        <form method='POST' className='wallet_create_form'>
          <input
            type='text'
            className='form-control'
            id='name'
            name='name'
            value={name}
            placeholder='Name'
            onChange={e => setName(e.target.value)}
          />
          <input
            type='number'
            className='form-control'
            id='accountNum'
            name='accountNum'
            value={accountNum}
            placeholder='Account Number'
            onChange={e => setAccountNum(e.target.value)}
          />
          <select
            className='form-control'
            onChange={e => setBankName(e.target.value)}
            value={bankName}
          >
            <option>Select Bank Name</option>
            {bankNameList.map((name, i) => (
              <option key={i} value={name}>
                {name}
              </option>
            ))}
          </select>
          <input
            type='text'
            className='form-control'
            id='bankIfscCode'
            name='bankIfscCode'
            value={bankIfscCode}
            placeholder='Ifsc Code'
            onChange={e => setBankIfscCode(e.target.value)}
          />
          <input
            type='number'
            className='form-control'
            id='beneficiaryMobileNumber'
            name='beneficiaryMobileNumber'
            value={beneficiaryMobileNumber}
            placeholder='Mobile Number (Optional)'
            onInput={mobileNumberMaxLength}
            maxLength='10'
            onChange={e => setBeneficiaryMobileNumber(e.target.value)}
          />
          <div className='form-group'>
            <button
              type='submit'
              id='beneficiary-add-btn'
              name='beneficiary-add-btn'
              className='btn btn-primary'
              onClick={addBeneficiaryHandler}
            >
              Submit
            </button>
            <button
              type='submit'
              id='beneficiary-add-btn'
              name='beneficiary-add-btn'
              className='btn btn-danger'
              onClick={e => setTab('list')}
              style={{ marginLeft: '10px' }}
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
