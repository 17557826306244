import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { CircularProgress } from '@mui/material';

export default function ApiReport (props) {
  const [startDate, setStartDate] = useState('')
  const [lastDate, setLastDate] = useState('')
  const [userId, setUserId] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [apiNames, setApiName] = useState([])
  const [successAmount, setSuccessAmount] = useState([])
  const [totalSuccess, setTotalSuccess] = useState([])
  const [totalFailure, setTotalFailure] = useState([])
  const [totalPending, setTotalPending] = useState([])

  const [stDate, setStDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [operator, setOperator] = useState('')

  function getOperatorView (e) {
    setLoading(true)
    e.preventDefault()
    axios
      .get('/api/admin/report/api/operator/' + props.user.id, {
        params: {
          stDate: stDate,
          endDate: endDate,
          userId: userId,
          operator: operator
        },
        headers: { Authorization: props.user.token }
      })
      .then(res => {
        if (res.status === 200) {
          setLoading(false)
          const data = res.data
          console.log(res)
          if (data.status === 1) {
            setApiName(data.unique)
            setTotalSuccess(data.totalSuccess)
            setTotalFailure(data.totalFailure)
            setTotalPending(data.totalPending)

            setSuccessAmount(data.successAmount.toFixed(2))

            setStartDate(data.startDate)
            setLastDate(data.lastDate)
          }
        }
      })
      .catch(error => {
        setLoading(false)
        alert(error)
      })
  }

  useEffect(() => {
    if (props.user.id != null) {
      setLoading(true)
      axios
        .get('/api/admin/report/api/operator/' + props.user.id, {
          params: {
            stDate: '',
            endDate: '',
            userId: '',
            operator: ''
          },
          headers: { Authorization: props.user.token }
        })
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setApiName(data.unique)
              setTotalSuccess(data.totalSuccess)
              setTotalFailure(data.totalFailure)
              setTotalPending(data.totalPending)

              setSuccessAmount(data.successAmount.toFixed(2))

              setStartDate(data.startDate)
              setLastDate(data.lastDate)
            }
          }
        })
        .catch(error => {
          setLoading(false)
          alert(error)
        })
    }
  }, [props])
  return (
    <div className='container'>
      <div className='wrap' style={{ flexDirection: 'column' }}>
        <div className='box-shadow-6 p-2'>
          <form className='trans-search-wrap'>
            <input
              type='date'
              className='form-control'
              id='fromDate'
              name='fromDate'
              onChange={e => setStDate(e.target.value)}
            />
            <input
              type='date'
              className='form-control'
              id='toDate'
              name='toDate'
              onChange={e => setEndDate(e.target.value)}
            />

            <input
              type='text'
              className='form-control'
              id='OptrName'
              name='OptrName'
              placeholder='Operator Name'
              onChange={e => setOperator(e.target.value)}
            />
            <input
              type='text'
              className='form-control'
              maxLength='10'
              id='userId'
              name='userId'
              placeholder='Enter User Id'
              onChange={e => setUserId(e.target.value)}
            />
            <div className='form-group'>
              <button
                type='submit'
                id='requestBtn'
                name='requestBtn'
                className='btn btn-primary'
                onClick={isLoading ? null : getOperatorView}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className='heading' style={{ marginTop: '50px' }}>
          API Operator Report
        </div>
        <div className='Count-Details'>
          <div className='DateShow'>
            {startDate} to {lastDate}
          </div>
          <div className='RechargeAmountCount'>
            <div className='RechargeSuccessAmount'>
              Total Sale: {successAmount}
            </div>
          </div>
        </div>
        <div className='Operator-Views-Card'>
          {isLoading ? (
            <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
          ) : (
            <table className='Operator-Views-table'>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>API Vendor</th>
                  <th>Success Recharge</th>
                  <th>Failure Recharge</th>
                  <th>Pending Recharge</th>
                </tr>
              </thead>
              <tbody>
                {apiNames.map((APIName, index) => (
                  <tr key={APIName}>
                    <td>{index + 1}</td>
                    <td>{APIName}</td>
                    <td>
                      {totalSuccess
                        .filter(transaction =>
                          transaction.vendorName.includes(APIName)
                        )
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + parseInt(currentValue.amount),
                          0
                        )}
                    </td>
                    <td>
                      {totalFailure
                        .filter(transaction =>
                          transaction.vendorName.includes(APIName)
                        )
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + parseInt(currentValue.amount),
                          0
                        )}
                    </td>
                    <td>
                      {totalPending
                        .filter(transaction =>
                          transaction.vendorName.includes(APIName)
                        )
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + parseInt(currentValue.amount),
                          0
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div style={{ padding: '20px' }}></div>
      </div>
    </div>
  )
}
