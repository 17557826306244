import axios from 'axios'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@mui/material';

export default function FundTransfer (props) {
  const location = useLocation()
  const [amount, setAmount] = useState('')
  const [transPassword, setTransPassword] = useState('')
  const [error, setError] = useState('')
  const [remark, setRemark] = useState('')
  const [isLoading, setLoading] = useState(false)

  function fundUpdateHandler (e) {
    e.preventDefault()
    const conf = window.confirm(
      'Are you sure want to transfer ' + amount + ' to ' + location.state.name
    )
    if (
      remark !== null &&
      transPassword !== null &&
      amount !== null &&
      location.state.mobile !== null &&
      conf
    ) {
      setLoading(true)

      axios
        .post(
          '/api/dt/payment/transfer/' + props.user.id,
          {
            amount: amount,
            remark: remark,
            transPassword: transPassword,
            userId: location.state.mobile
          },
          {
            headers: { Authorization: props.user.token }
          }
        )
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            setAmount('')
            setTransPassword('')
            setRemark('')
            console.log(res)

            setLoading(false)
            if (data.status === 1) {
              setError(data.message + ' | Available Balance: ' + data.balance)
            } else {
              setError(data.message)
            }
          }
        })
        .catch(error => {
          setLoading(false)
          setError(error)
        })
    } else if (conf) {
      setError('Empty Details')
    }
  }
  return (
    <div>
      <div className='container'>
        <div className='Admin-wrap'>
          <div className='heading'>Fund Transfer</div>
          {isLoading ? (
            <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
          ) : (
          <div className='user-info-box'>
            <div className={error !== '' ? 'AdminError' : ''}>{error}</div>
            <div className='user-info-card' style={{ margin: '0px auto' }}>
              <div className='User-Details-wrap'>
                <label className='user-label'>Username</label>
                <div className='user-label'>{location.state.name}</div>
              </div>
              <div className='User-Details-wrap'>
                <label className='user-label'>Mobile Number</label>
                <div className='user-label'>{location.state.mobile}</div>
              </div>
              <div className='User-Details-wrap'>
                <label className='user-label'>Account Status</label>
                {location.state.status === '1' ? (
                  <div className='user-label'>Active</div>
                ) : location.state.status === '2' ? (
                  <div className='user-label'>Block</div>
                ) : (
                  <div className='user-label'>Inactive</div>
                )}
              </div>
            </div>
            <form className='user-info-card' style={{ margin: '0px auto' }}>
              <input
                type='text'
                name='amount'
                className='fund-input-box'
                placeholder='Enter Amount'
                value={amount}
                onChange={e => setAmount(e.target.value)}
              />
              <input
                type='text'
                name='remark'
                className='fund-input-box'
                placeholder='Enter remark'
                value={remark}
                onChange={e => setRemark(e.target.value)}
              />
              <input
                type='password'
                name='transPassword'
                maxLength='4'
                className='fund-input-box'
                placeholder='Enter Transaction Password'
                value={transPassword}
                onChange={e => setTransPassword(e.target.value)}
              />
              <button
                id='FundTransferBtn'
                name='FundTransferBtn'
                className='btn btn-primary'
                onClick={isLoading ? null : fundUpdateHandler}
              >
                Transfer
              </button>
            </form>
          </div>
          )}
        </div>
      </div>
    </div>
  )
}
