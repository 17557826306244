import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import DTHeader from './header/DTHeader'
import DTMobileHeader from './header/DTMobileHeader'

export default function AdminProtected (props) {
  const Page = props.page
  const [balance, setBalance] = useState(0)
  const [isLoggedIn, setLoggedIn] = useState(
    localStorage.getItem('user') !== null ? true : false
  )
  const [user, setUser] = useState(
    localStorage.getItem('user') !== null
      ? JSON.parse(localStorage.getItem('user'))
      : ''
  )

  const getUser = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const token = user.token

    if (token !== null && token !== undefined && token !== '') {
      axios
        .get('/api/dt/' + user.id, { headers: { Authorization: token } })
        .then(res => {
          if (res.status === 200) {
            const data = res.data
            console.log(res)
            if (data.status === 1) {
              setUser(data.user)
              setBalance(data.balance)
            } else {
              alert(data.message)
              localStorage.clear()
              setLoggedIn(false)
            }
          } else {
            localStorage.clear()
            setLoggedIn(false)
          }
        })
        .catch(error => {
          alert(error)
        })
    } else {
      setLoggedIn(false)
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      getUser()
    }
  }, [isLoggedIn])

  return isLoggedIn ? (
    <div>
      <DTHeader getUser={getUser} setLoggedIn={setLoggedIn} balance={balance} />
      <DTMobileHeader getUser={getUser} setLoggedIn={setLoggedIn} balance={balance} />
      <Page user={user} />
    </div>
  ) : (
    
   <Redirect to='/logout'></Redirect>
  )
}
