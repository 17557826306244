import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";

export default function ConfirmTransaction(props) {
  const location = useLocation();
  const history = useHistory();
  const [mpin, setMpin] = React.useState("");
  const [error, setError] = React.useState("");
  const [accountName, setAccountName] = React.useState("");
  const [isloading, setLoading] = React.useState(false);

  function dmtTransactionHandler(e) {
    e.preventDefault();
    if (mpin !== null && mpin !== "" && location.state !== undefined) {
      setLoading(true);
      axios
        .post(
          "/api/sendmoney/transaction/" + props.user.id,
          {
            number: location.state.wallet,
            beneId: location.state.beneId,
            amount: location.state.amount,
            mode: location.state.payMode,
            account: location.state.accountNumber,
            bank: location.state.bankName,
            ifsc: location.state.bankIfscCode,
            txnpin: mpin,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setLoading(false);
            const data = res.data;
            console.log(res);
            if (data.status === 3 && data.message === "Session Expire") {
              localStorage.clear();
              props.setLoggedIn(false);
            } else {
              if (
                location.state.accountName === "" &&
                location.state.accountName === null
              ) {
                setAccountName(data.Name);
              } else {
                setAccountName(location.state.accountName);
              }
              history.replace("response", {
                bank: location.state.bankName,
                accountNumber: location.state.accountNumber,
                accountName: accountName,
                beneficiaryName: location.state.beneName,
                bankIfscCode: location.state.bankIfscCode,
                amount: location.state.amount,
                payMode: location.state.payMode,
                response: data.message,
                status: data.status,
                trxnId: data.clientId,
                utrNo: data.optId,
              });
              props.getUser();
            }
          }
        })
        .catch((e) => {
          setLoading(false);
          setError(e.message);
        });
    } else {
      setError("Mpin is invalid");
    }
  }

  return props.user.accountType === "RT" ? (
    <div>
      <div className="container">
        <div className="p-2">
          <div className="bg-white box-shadow-6 p-2">
            {isloading ? (
              <div
                className="p-2"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="heading text-center">Confirm Details</div>
                <div className="confirmWrap">
                  {error !== "" ? (
                    <div className="errorClass text-center">{error}</div>
                  ) : (
                    ""
                  )}
                  <div className="BeneficiaryDetailsWrap">
                    <div className="confirmTile text-center">
                      <div>Bank Name</div>
                      <div className="rechargeHeadingTextCol text-center">
                        {location.state.bankName}
                      </div>
                    </div>

                    <div className="confirmTile text-center">
                      <div>Account Name</div>
                      {location.state.accountName !== null &&
                      location.state.accountName !== "" ? (
                        <div className="rechargeHeadingTextCol">
                          {location.state.accountName}
                        </div>
                      ) : (
                        <div className="rechargeHeadingTextCol">
                          {location.state.beneName}
                        </div>
                      )}
                    </div>
                    <div className="confirmTile text-center">
                      <div>Account Number</div>
                      <div className="rechargeHeadingTextCol">
                        {location.state.accountNumber}
                      </div>
                    </div>
                    <div className="confirmTile text-center">
                      <div>Ifsc Code</div>
                      <div className="rechargeHeadingTextCol">
                        {location.state.bankIfscCode}
                      </div>
                    </div>
                    <div className="confirmTile text-center">
                      <div>Mode</div>
                      <div className="rechargeHeadingTextCol">
                        {location.state.payMode}
                      </div>
                    </div>
                    <div className="confirmTile text-center">
                      <div>Amount</div>
                      <div className="rechargeHeadingTextCol">
                        {location.state.amount}
                      </div>
                    </div>
                    <div className="confirmTile text-center">
                      <div>Transaction MPIN</div>
                      <div>
                        <input
                          type="password"
                          className="mpin"
                          placeholder="MPIN (4 Digit)"
                          value={mpin}
                          onChange={(e) => {
                            e.target.value.length < 5 &&
                              setMpin(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="confirmTile text-center">
                      <button
                        className="btn btn-primary"
                        onClick={dmtTransactionHandler}
                        style={{ marginRight: "10px" }}
                      >
                        Confirm
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={history.goBack}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="container">
        <div className="p-2">
          <div className="bg-white box-shadow-6 p-2">
            <h1>Unautherise Access</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
