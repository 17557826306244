import axios from "axios";
import React, { useState } from "react";

export default function AddParam(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [paramName, setParamName] = useState("");
  const [paramValue, setParamValue] = useState("");
  const [paramType, setParamType] = useState("");
  const [paramUse, setParamUse] = useState("");

  function addParamFormHandler() {
    if (
      paramName !== "" &&
      paramName !== null &&
      props.apiId !== "" &&
      props.apiId !== null &&
      paramType !== "" &&
      paramType !== null &&
      paramUse !== "" &&
      paramUse !== null
    ) {
      setLoading(true);
      setErrorMessage("");
      axios
        .post(
          "/api/admin/settings/api/param/add/" + props.user.id,
          {
            paramName: paramName,
            paramValue: paramValue,
            apiId: props.apiId,
            paramType: paramType,
            paramSet: paramUse,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            if (data.status === 1) {
              setParamName("");
              setParamValue("");
              setParamType("");
              setParamUse("");
              setErrorMessage(data.message);
            }
          } else {
            setErrorMessage(res.statusText);
          }
        });
    } else {
      setErrorMessage("Please fill the form correctly");
    }
  }
  return (
    <div className="box-shadow-6">
      <div className="heading p-2">Add Parameter</div>
      {errorMessage !== "" ? (
        <div className="pl-2 pr-2 pb-2">
          <div className="alert alert-default">{errorMessage}</div>
        </div>
      ) : (
        ""
      )}
      <div className="pl-2 pr-2 pb-2">
        <form
          method="POST"
          onSubmit={(e) => {
            e.preventDefault();
            addParamFormHandler();
          }}
        >
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Parameter Name"
              value={paramName}
              onChange={(e) => setParamName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Parameter Default Value (Optional)"
              value={paramValue}
              onChange={(e) => setParamValue(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="API Id"
              value={props.apiId}
            />
          </div>

          <div className="form-group">
            <select
              className="form-control"
              onChange={(e) => setParamType(e.target.value)}
            >
              <option value="">Select Method</option>
              <option value="transaction">Transaction</option>
              <option value="status">Status</option>
              <option value="callBack">CallBack</option>
            </select>
          </div>
          <div className="form-group">
            <select
              className="form-control"
              onChange={(e) => setParamUse(e.target.value)}
            >
              <option value="">Select Method</option>
              <option value="request">Request</option>
              <option value="response">Response</option>
            </select>
          </div>
          <div className="form-group pl-1">
            {!isLoading ? (
              <button className="btn btn-primary">Submit</button>
            ) : (
              <div className="p-2" style={{ color: "blue" }}>
                Processing...
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
