import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export default function TransferToUPI(props) {
  const { getBalance } = props;
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [upiName, setUpiName] = useState("");
  const [amount, setAmount] = useState("");
  const [upiId, setUpiId] = useState("");
  const [trxnpin, setTrxnpin] = useState("");
  const history = useHistory();

  const transferToUPIPaymentHandler = (e) => {
    e.preventDefault();
    if (
      upiName !== "" &&
      upiName !== null &&
      amount !== "" &&
      amount !== null &&
      upiId !== "" &&
      upiId !== null &&
      trxnpin !== "" &&
      trxnpin != null
    ) {
      setLoading(true);
      axios
        .post(
          "/api/aeps/settlement/" + props.user.id,
          {
            beneId: "",
            amount: amount,
            upiId: upiId,
            upi_name: upiName,
            type: "ToUPI",
            txnpin: trxnpin,
          },
          { headers: { Authorization: props.user.token } }
        )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            setLoading(false);
            if (data.status === 3 || data.message === "Session Expire") {
              localStorage.clear();
              props.setLoggedIn(false);
            } else {
              history.push("/aeps/upi/response", {
                upiId: upiId,
                upi_name: upiName,
                amount: amount,
                response: data.message,
                status: data.status,
                trxnId: data.trxnId,
                utrNo: data.rrn,
              });
            }
          } else {
            setAmount("");
            setTrxnpin("");
            setUpiName("");
          }
          console.log(res);
        })
        .catch((e) => {
          alert(e.message);
        });
      getBalance();
    } else {
      setError("Please fill empty filled..");
    }
  };

  return (
    <>
      <div className="subHeading p-1">Transfer to UPI</div>
      {isLoading ? (
        <div
          className="p-2"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {error !== "" && error !== undefined && error !== null ? (
            <div className="subHeading p-1" style={{ color: "red" }}>
              {error}
            </div>
          ) : (
            ""
          )}
          <form method="POST" className="wallet_create_form">
            <input
              type="text"
              className="form-control"
              id="upi_name"
              name="name"
              value={upiName}
              placeholder="Enter UPI Name"
              onChange={(e) => setUpiName(e.target.value)}
            />
            <input
              type="text"
              className="form-control"
              id="upiId"
              name="upiId"
              value={upiId}
              placeholder="Enter UPI Id"
              onChange={(e) => setUpiId(e.target.value)}
            />
            <input
              type="number"
              className="form-control"
              id="amount"
              name="amount"
              value={amount}
              placeholder="Enter Amount"
              onChange={(e) => setAmount(e.target.value)}
            />
            <input
              type="text"
              className="form-control"
              name="trxnpin"
              id="trxnpin"
              value={trxnpin}
              placeholder=" Enter Transaction Pin"
              onChange={(e) => setTrxnpin(e.target.value)}
            />
            <div className="form-group" style={{ margin: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={transferToUPIPaymentHandler}
              >
                Transfer To UPI
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  );
}
