import axios from 'axios'
import { Link } from 'react-router-dom'
import React from 'react'

export default function DTHeader (props) {
  const balance = props.balance
  const logoutHandler = () => {
    const user = JSON.parse(localStorage.getItem('user'))

    if (
      user.id !== null &&
      user.token !== null &&
      user.id !== undefined &&
      user.token !== undefined
    ) {
      axios.get('/api/user/' + user.id, { headers: { Authorization: user.token } })
        .then(res => {
          if (res.status === 200) {
            localStorage.clear()
            props.setLoggedIn(false)
          } else {
            localStorage.clear()
            props.setLoggedIn(false)
          }
        })
        .catch(error => {
          alert(error)
        })
    } else {
      localStorage.clear()
      props.setLoggedIn(false)
    }
  }

  return (
    <div className='headerBox'>
      <div className='wrap'>
        <div className='logo'>
          <Link to='/' style={{ textDecoration: 'none', color: '#680e7a' }}>
            BS
          </Link>
        </div>
        <div className='NavBarMenu'>
          <ul>
            <li>
              <Link className='linkTextDecorate' to='/new_user'>
                New User
              </Link>
            </li>
            <li>
              <Link className='linkTextDecorate' to='/users'>
                User
              </Link>
            </li>
            <li>
              <Link className='linkTextDecorate' to='#'>
                Report
              </Link>
              <div className='Sub-menu-2'>
                <ul>
                  <li>
                    <Link
                      className='linkTextDecorate'
                      to='/report/recharge_history'
                    >
                      Tranaction History
                    </Link>
                  </li>
                 
                  <li>
                    <Link
                      className='linkTextDecorate'
                      to='/report/account_statement'
                    >
                      Account Statement
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='linkTextDecorate'
                      to='/report/topup_history'
                    >
                      TopUp History
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='linkTextDecorate'
                      to='/report/operator_report'
                    >
                      Operator Report
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link className='linkTextDecorate' to='/payment_request'>
                Payment Requests
              </Link>
            </li>

            <li>
              <Link className='linkTextDecorate' to='/complaint'>
                Complaints
              </Link>
            </li>
            <li>
              <Link className='linkTextDecorate' to='/setting'>
                Setting
              </Link>
            </li>
          </ul>
        </div>
        <div className='NavBarMenuBtn'>
          <ul>
            <li style={{ paddingRight: '15px' }}>
              <span style={{ marginRight: '5px' }}>
                ₹{parseFloat(balance).toFixed(2)}
              </span>
              <i
                className='material-icons'
                onClick={props.getUser}
                style={{
                  verticalAlign: 'middle',
                  fontSize: '20px',
                  cursor: 'pointer'
                }}
              >
                autorenew
              </i>
            </li>
            <li
              style={{ paddingLeft: '15px', cursor: 'pointer' }}
              onClick={logoutHandler}
            >
              Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
