import axios from 'axios'
import React, { useState } from 'react'

export default function Setting(props) {
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [currentMpin, setCurrentMpin] = useState('')
    const [newMpin, setNewMpin] = useState('')
    const [confirmMpin, setConfirmMpin] = useState('')
    const [tab, setTabs] = useState('password')
    const [isPwdLoading, setPwdLoading] = useState(false)
    const [isMpinLoading, setMpinLoading] = useState(false)
    const [error, setError] = useState(false)
    const [mpinError, setMpinError] = useState(false)

    function changePasswordHandler(e) {
        e.preventDefault();
        if (currentPassword !== '' && newPassword !== '' && confirmPassword !== '') {
            if (newPassword === confirmPassword) {
                setPwdLoading(true)
                axios.post('/api/admin/password/change/' + props.user.id,
                    {
                        currentPassword: currentPassword,
                        newPassword: newPassword
                    },
                    {
                        headers: { Authorization: props.user.token }
                    }).then(res => {
                        if (res.status === 200) {
                            const data = res.data
                            console.log(res)
                            setCurrentPassword('')
                            setNewPassword('')
                            setConfirmPassword('')
                            setPwdLoading(false)
                            if (data.status === 1) {
                                setError(data.message)
                            } else {
                                setError(data.message)
                            }
                        }
                    }).catch(error => {
                        setError(error)
                    })

            } else {
                setError('Password do not match ')
            }
        } else {
            setError('Empty details and fill all input properly')
        }
    }

    function changeMpinHandler(e) {
        e.preventDefault();
        if (currentMpin !== '' && newMpin !== '' && confirmMpin !== '') {
            if (newMpin === confirmMpin) {
                setMpinLoading(true)
                axios.post('/api/admin/mpin/change/' + props.user.id,
                    {
                        currentMpin: currentMpin,
                        newMpin: newMpin
                    },
                    {
                        headers: { Authorization: props.user.token }
                    }).then(res => {
                        if (res.status === 200) {
                            const data = res.data
                            console.log(res)
                            setCurrentMpin('')
                            setNewMpin('')
                            setConfirmMpin('')
                            setMpinLoading(false)
                            if (data.status === 1) {
                                setMpinError(data.message)
                            } else {
                                setCurrentMpin('')
                                setNewMpin('')
                                setConfirmMpin('')
                                setMpinError(data.message)
                            }
                        }
                    }).catch(error => {
                        setMpinError(error)
                    })

            } else {
                setMpinError('Mpin do not match ')
            }
        } else {
            setMpinError('Empty details and fill all input properly')
        }
    }
    return (
        <div>
            <div className="container">
                <div className="content-wrap">
                    <div className='NavBarBtnWrap'>
                        <div
                            style={{ marginRight: '5px' }}
                            className={tab !== 'password' ? 'btn' : 'btn btn-primary'}
                            onClick={() => setTabs('password')}
                        >
                            Password
                        </div>
                        <div
                            className={tab === 'password' ? 'btn' : 'btn btn-primary'}
                            onClick={() => setTabs('')}
                        >
                            Mpin
                        </div>
                    </div>
                    { tab === 'password' ? (
                    <div className="change_password_card p-1">
                        <div className="heading">Change Password</div>
                        {error ? <div className='error p-1'>{error}</div> : ''}
                        <form method="POST" className="change_password_form">
                            <input type="password" className="form-control" id="currentPassword" name="currentPassword" value={currentPassword} placeholder="Enter current password" onChange={(e) => setCurrentPassword(e.target.value)} />
                            <input type="password" className="form-control" id="newPassword" name="newPassword" value={newPassword} placeholder="Enter new password" onChange={(e) => setNewPassword(e.target.value)} />
                            <input type="password" className="form-control" id="confirmPassword" name="confirmPassword" value={confirmPassword} placeholder="Enter confirm password" onChange={(e) => setConfirmPassword(e.target.value)} />
                            <div className='form-group'>
                                <button
                                    type='submit'
                                    id='changePwdBtn'
                                    name='changePwdBtn'
                                    className='btn btn-primary'
                                    onClick={isPwdLoading ? null : changePasswordHandler}
                                >
                                    {isPwdLoading ? 'Loading...' : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                    ):(
                    <div className="change_mpin_card p-1">
                        <div className="heading">Change Mpin</div>
                        {mpinError ? <div className='error p-1'>{mpinError}</div> : ''}
                        <form method="POST" className="change_mpin_form">
                            <input type="password" maxLength='4' className="form-control" id="currentMpin" name="currentMpin" value={currentMpin} placeholder="Enter current mpin" onChange={(e) => setCurrentMpin(e.target.value)} />
                            <input type="password" maxLength='4' className="form-control" id="newMpin" name="newMpin" value={newMpin} placeholder="Enter new mpin" onChange={(e) => setNewMpin(e.target.value)} />
                            <input type="password" maxLength='4' className="form-control" id="confirmMpin" name="confirmMpin" value={confirmMpin} placeholder="Enter confirm mpin" onChange={(e) => setConfirmMpin(e.target.value)} />
                            <div className='form-group'>
                                <button
                                    type='submit'
                                    id='changeMpinBtn'
                                    name='changeMpinBtn'
                                    className='btn btn-primary'
                                    onClick={isMpinLoading ? null : changeMpinHandler}
                                >
                                    {isMpinLoading ? 'Loading...' : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}
