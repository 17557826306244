import React from "react";

const DMTResponsePrint = React.forwardRef((props, ref) => {
  const { location } = props;
  return (
    <div ref={ref}>
      <div className="heading text-center">Transaction Reciept</div>
      <div className="justify-center p-2">
        {location.state.status === 1 || location.state.status === "1" ? (
          <div className="statusCard" style={{ backgroundColor: "blue" }}>
            <i className="material-icons">check</i>
          </div>
        ) : location.state.status === 2 || location.state.status === "2" ? (
          <div className="statusCard" style={{ backgroundColor: "red" }}>
            <i className="material-icons">close</i>
          </div>
        ) : (
          <div className="statusCard" style={{ backgroundColor: "orange" }}>
            <i className="material-icons">info</i>
          </div>
        )}
      </div>
      <div className="text-center" style={{ fontSize: "18px" }}>
        {location.state.response}
      </div>

      <div style={{ paddingTop: "2rem" }}>
        <table style={{ margin: "auto" }}>
          <tr>
            <td>Status</td>
            <td>
              {location.state.status === "1" || location.state.status === 1
                ? "Successful"
                : location.state.status === "2" || location.state.status === 2
                ? "Failure"
                : "Pending"}
            </td>
          </tr>
          <tr>
            <td>Bank</td>
            <td>{location.state.bank}</td>
          </tr>
          <tr>
            <td>Account Number</td>
            <td>{location.state.accountNumber}</td>
          </tr>
          <tr>
            <td>Account Name</td>
            {location.state.accountName !== null &&
            location.state.accountName !== "" ? (
              <td>{location.state.accountName}</td>
            ) : (
              <td>{location.state.beneficiaryName}</td>
            )}
          </tr>
          <tr>
            <td>Ifsc Code</td>
            <td>{location.state.bankIfscCode}</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>₹{location.state.amount}</td>
          </tr>
          {location.state.trxnId !== null &&
          location.state.trxnId !== "" &&
          location.state.trxnId !== undefined ? (
            <tr>
              <td>Transaction Id</td>
              <td>#{location.state.trxnId}</td>
            </tr>
          ) : (
            ""
          )}

          {location.state.utrNo !== null &&
          location.state.utrNo !== "" &&
          location.state.utrNo !== undefined ? (
            <tr>
              <td>UTR Number</td>
              <td>#{location.state.utrNo}</td>
            </tr>
          ) : (
            ""
          )}
        </table>
      </div>
    </div>
  );
});
export default DMTResponsePrint;
