import axios from "axios";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function ConfrmRecharge(props) {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mpin, setMpin] = useState("");

  function transctionHandler() {
    if (mpin !== "" && location.state !== undefined && mpin.length === 4) {
      setLoading(true);
      axios
        .post(
          "/api/recharge/transaction/" + props.user.id,
          {
            rechargeType: location.state.rechargeType,
            operator: location.state.operator,
            number: location.state.num,
            circle: location.state.circle,
            amount: location.state.amount,
            mpin: mpin,
          },
          {
            headers: { Authorization: props.user.token },
          }
        )
        .then((res) => {
          setLoading(false);

          if (res.status === 200) {
            const data = res.data;
            console.log(data);
            props.getUser();
            history.replace("response", {
              status: data.status,
              txnId: data.vendorId,
              optId: data.optId,
              rechargeType: location.state.rechargeType,
              operator: location.state.operator,
              num: location.state.num,
              circle: location.state.circle,
              amount: location.state.amount,
              response: data.message,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else if (mpin === "" || mpin.length !== 4) {
      setError("Please enter MPIN");
    }
  }

  return props.user.accountType === "RT" ? (
    <div>
      <div className="container">
        <div className="p-2">
          <div className="bg-white box-shadow-6 p-2">
            <div className="heading">Confirm Details</div>
            <div className="confirmWrap">
              <div className="errorClass">{error}</div>
              <div className="input-form-group">
                <div className="rechargeConfirmTile">
                  <div>Subcriber Number</div>
                  <div className="rechargeHeadingTextCol">
                    {location.state.num}
                  </div>
                </div>

                <div className="rechargeConfirmTile">
                  <div>Type</div>
                  <div className="rechargeHeadingTextCol">
                    {location.state.rechargeType} Recharge
                  </div>
                </div>
                <div className="rechargeConfirmTile">
                  <div>Operator</div>
                  <div className="rechargeHeadingTextCol">
                    {location.state.operator} (
                    {location.state.circle != null &&
                    location.state.circle !== undefined
                      ? location.state.circle
                      : ""}
                    )
                  </div>
                </div>

                <div className="rechargeConfirmTile">
                  <div>Amount</div>
                  <div className="rechargeHeadingTextCol">
                    {location.state.amount}
                  </div>
                </div>
                <div className="rechargeConfirmTile">
                  <div>Enter Transaction MPIN</div>
                  <div>
                    <input
                      type="password"
                      className="mpin"
                      maxLength="4"
                      placeholder="MPIN (4 Digit)"
                      onChange={(e) => setMpin(e.target.value)}
                    />
                  </div>
                </div>
                <div className="rechargeConfirmTile">
                  <div></div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={loading ? null : transctionHandler}
                    >
                      {loading ? "Processing.." : "Confirm"}
                    </button>
                  </div>
                </div>
                <div className="rechargeConfirmTile">
                  <div>
                    <button className="btn btn-danger" onClick={history.goBack}>
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="container">
        <div className="p-2">
          <div className="bg-white box-shadow-6 p-2">
            <h1>Unautherise Access</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
