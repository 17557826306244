/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./usersDetails.css";
import { CircularProgress } from '@mui/material';

export default function usersList(props) {
  const history = useHistory();
  const [error, setError] = useState("");
  const [accType, setAccType] = useState("RT");
  const [loading, setLoading] = useState(false);
  const [isChecking, setChecking] = useState("");
  const [usersDetails, setUserDetails] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [balance, setBalance] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [isSearching, setisSearching] = useState(false);

  function UserInfoHandler(users) {
    history.push("user/info", {
      mobile: users.mobile,
    });
  }
  function FundTransferHandler(users) {
    history.push("user/fund-transfer", {
      mobile: users.mobile,
      name: users.name,
      status: users.status,
    });
  }
  function CheckBalance(mobile) {
    if (mobile !== null && mobile !== undefined) {
      setChecking(mobile);
      setCurrentUser(mobile);
      axios
        .get("/api/admin/user/balance/" + props.user.id, {
          params: {
            userId: mobile,
          },
          headers: { Authorization: props.user.token },
        })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            setChecking("");
            if (data.status === 1) {
              setBalance(data.balance);
            } else {
              alert(data.message);
            }
          }
        })
        .catch((error) => {
          setChecking("");
          setError(error);
        });
    }
  }

  useEffect(() => {
    if (props.user.id !== null) {
      setLoading(true);
      axios
        .get("/api/admin/users/list/" + accType + "/" + props.user.id, {
          headers: { Authorization: props.user.token },
        })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            setLoading(false);
            if (data.status === 1) {
              setUserDetails(data.userDetails);
            } else {
              setUserDetails([]);
              setError(data.message);
            }
          } else if (
            res.data.status === 3 &&
            res.data.message === "Session Expire"
          ) {
            localStorage.clear();
            props.setLoggedIn(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
        });
    }
  }, [props, accType]);

  const searchQuery = (query) => {
    if (query.length === 10) {
      setisSearching(true);
      axios
        .get("/api/admin/users/search/" + query + "/" + props.user.id, {
          headers: { Authorization: props.user.token },
        })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            console.log(res);
            setLoading(false);
            if (data.status === 1) {
              setSearchResult(data.result);
            } else {
              setSearchResult([]);
              setError(data.message);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
        });
    } else if (query !== "") {
      setisSearching(true);
      const result = usersDetails.filter((element) =>
        element.mobile.startsWith(query)
      );

      if (result !== null) {
        setSearchResult(result);
      } else {
        setSearchResult([]);
      }
    } else {
      setisSearching(false);
    }
  };

  return (
    <div>
      <div className="container">
        <div className="Admin-wrap">
          <div className="heading">Users Details</div>
          <div className="user-details-box">
            <div className="UsersNavBarMenu">
              <div className="NavBarBtnWrap">
                <div
                  className={accType === "RT" ? "btn btn-primary" : "btn"}
                  onClick={() => setAccType("RT")}
                >
                  Retailers
                </div>
                <div
                  className={accType === "DT" ? "btn btn-primary" : "btn"}
                  onClick={() => setAccType("DT")}
                >
                  Distributors
                </div>
                <div
                  className={accType === "API" ? "btn btn-primary" : "btn"}
                  onClick={() => setAccType("API")}
                >
                  API Users
                </div>
              </div>
              <div className="SearchBoxWrap">
                <input
                  type="text"
                  maxLength="10"
                  className="SearchInput"
                  placeholder="Enter Mobile Number"
                  onChange={(e) => searchQuery(e.target.value)}
                />
              </div>
            </div>
            {loading ? (
              <div className="users-details-card">
              <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
              </div>
            ) : (
              <div className="users-details-card">
                {searchResult.length < 1 && !isSearching ? (
                  <div>
                    {usersDetails.length > 0 ? (
                      <table className="users-details-table">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Mobile Number</th>
                          <th>Create At</th>
                          <th>Last Login</th>
                          <th>Account Type</th>
                          <th>Check Balance</th>
                          <th>View Details</th>
                          <th>Fund Transfer</th>
                        </tr>
                        </thead>
                        <tbody>
                        {usersDetails.map((users, index) => (
                          <tr key={users.id.toString()}>
                            <td>{index + 1}</td>
                            <td>{users.name}</td>
                            <td>{users.mobile}</td>
                            <td>
                              {moment(users.createAt).format(
                                "YYYY-MM-DD, h:mm:ss a"
                              )}
                            </td>
                            <td>
                              {moment(users.lastLogin).format(
                                "YYYY-MM-DD, h:mm:ss a"
                              )}
                            </td>
                            {users.accountType === "RT" ? (
                              <td>Retailer</td>
                            ) : users.accountType === "DT" ? (
                              <td>Distributor</td>
                            ) : (
                              <td>API</td>
                            )}
                            <td>
                              {isChecking === users.mobile ? (
                                <div>Checking..</div>
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  style={{ margin: "0px" }}
                                  onClick={() => CheckBalance(users.mobile)}
                                >
                                  {balance !== "" &&
                                  currentUser === users.mobile
                                    ? "₹" + parseInt(balance).toFixed(2)
                                    : "Check"}
                                </button>
                              )}
                            </td>
                            <td>
                              <button
                                className="btn btn-primary"
                                style={{ margin: "0px" }}
                                onClick={() => UserInfoHandler(users)}
                              >
                                View
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-primary"
                                style={{ margin: "0px" }}
                                onClick={() => FundTransferHandler(users)}
                              >
                                Transfer
                              </button>
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="heading p-1">{error}</div>
                    )}
                  </div>
                ) : (
                  <div>
                    <table className="users-details-table">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>Create At</th>
                        <th>Last Login</th>
                        <th>Account Type</th>
                        <th>Check Balance</th>
                        <th>View Details</th>
                        <th>Fund Transfer</th>
                      </tr>
                      </thead>
                      <tbody>
                      {searchResult.map((users, index) => (
                        <tr key={users.id.toString()}>
                          <td>{index + 1}</td>
                          <td>{users.name}</td>
                          <td>{users.mobile}</td>
                          <td>
                            {moment(users.createAt).format(
                              "YYYY-MM-DD, h:mm:ss a"
                            )}
                          </td>
                          <td>
                            {moment(users.lastLogin).format(
                              "YYYY-MM-DD, h:mm:ss a"
                            )}
                          </td>
                          {users.accountType === "RT" ? (
                            <td>Retailer</td>
                          ) : users.accountType === "DT" ? (
                            <td>Distributor</td>
                          ) : (
                            <td>API</td>
                          )}
                          <td>
                            {isChecking === users.mobile ? (
                              <div>Checking..</div>
                            ) : (
                              <button
                                className="btn btn-primary"
                                style={{ margin: "0px" }}
                                onClick={() => CheckBalance(users.mobile)}
                              >
                                {balance !== "" &&
                                  currentUser === users.mobile
                                    ? "₹" + parseInt(balance).toFixed(2)
                                    : "Check"}
                              </button>
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary"
                              style={{ margin: "0px" }}
                              onClick={() => UserInfoHandler(users)}
                            >
                              View
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-primary"
                              style={{ margin: "0px" }}
                              onClick={() => FundTransferHandler(users)}
                            >
                              Transfer
                            </button>
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
