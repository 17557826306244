import React from 'react'

export default function AepsSettlementHistory (props) {
  const {AepsSettlementList} = props;
  return (
    <>
      <div className='mt-1 aeps_card'>
        <div className='subHeading p-1'>Settle History</div>
        <div style={{ overflow: 'hidden', overflowX: 'auto' }}>
          {AepsSettlementList.length > 0 ? (
            <table className='table-collapse'>
            <thead>
              <tr>
                <th>S No.</th>
                <th>Tranction Id</th>
                <th>UTR No</th>
                <th>Account Name</th>
                <th>Bank Name</th>
                <th>Account Number</th>
                <th>Ifsc Code</th>
                <th>Amount</th>
                <th>Request Date</th>
                <th>Update Date</th>
                <th>Balance</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
                {AepsSettlementList.map((transaction, index) => (
                  <tr key={transaction.id.toString()}>
                    <td>{index + 1}</td>
                    <td>{transaction.id}</td>
                    <td>{transaction.bank_ref_no}</td>
                    <td>{transaction.account_name}</td>
                    <td>{transaction.bankname}</td>
                    <td>{transaction.account_number}</td>
                    <td>{transaction.IFSCCode}</td>
                    <td>{transaction.amount}</td>
                    <td>{transaction.request_date}</td>
                    <td>{transaction.updateTime}</td>
                    <td>{transaction.balance}</td>
                      {transaction.status === '1' ? (
                        <td className='text-success'>Success</td>
                      ) : transaction.status === '3' ? (
                        <td className='text-failure'>Refunded</td>
                      ) : transaction.status === '2' ? (
                        <td className='text-failure'>Failure</td>
                      ) : (
                        <td className='text-pending'>Pending</td>
                      )}
                  
                  </tr>
                ))}
                </tbody>
            </table>
          ) : (
            <div className='p-1'>
              <div className='subHeading text-center'>
                No Settlement History
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
