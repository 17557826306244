import axios from 'axios'
import React, { useState, useEffect } from 'react'
import './commission.css'
import { CircularProgress } from '@mui/material';

export default function Commission(props) {
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [commissionSlab, setCommissionSlab] = useState([])


  useEffect(() => {
    if (props.user.id !== null) {
      setLoading(true)
      axios.get('/api/commission/slab/' + props.user.id, {
        headers: { Authorization: props.user.token }
      }).then(res => {
        if (res.status === 200) {
          setLoading(false)
          const data = res.data
          console.log(res)
          if (data.status === 1) {
            setCommissionSlab(data.commissionSlabReport)
          } else {
            setCommissionSlab([])
            setError(data.message)
          }
        }else if(res.data.status === 3 && res.data.message === 'Session Expire'){
          localStorage.clear()
          props.setLoggedIn(false)
        }
      }).catch(error => {
        setLoading(false)
        setError(error)
      })
    }

  }, [props])
  return (
    <div className='container'>
      <div className='wrap' style={{ flexDirection: 'column' }}>
        <div className='heading'>My Commission</div>
        <div className='Commission-table-view'>
          {isLoading ? (
            <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
          ) : (
            commissionSlab.length > 0 ? (
            <table className="Commission-table-Box">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Operator</th>
                  <th>Slab</th>
                  <th>Circle</th>
                  <th>Daily Limit</th>
                  <th>Slab Type</th>
                </tr>
              </thead>
              <tbody>
                {commissionSlab.map((commissionReport, index) => (
                  <tr key={commissionReport.id.toString()}>
                    <td>{index + 1}</td>
                    <td>{commissionReport.opt}</td>
                    {commissionReport.agentSlabType !== 'percentage' ? (
                    <td>₹{commissionReport.agentCommission}</td>
                    ):(
                      <td>{commissionReport.agentCommission}%</td>
                    )}
                    <td>{commissionReport.circle}</td>
                    <td>{commissionReport.dailyLimit}</td>
                    <td>{commissionReport.isCommission}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
              <div className='Commission-heading p-1'>{error}</div>
            ))}
        </div>
      </div>
    </div>
  )
}
