import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from '@mui/material';

export default function APIList(props) {
  const [errorMessae, setErrorMessae] = useState("");
  const [apiList, setApiList] = useState([]);
  const [loading, setLoading] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setLoading(true)
    axios
      .get("/api/admin/settings/api/list/" + props.user.id, {
        headers: { Authorization: props.user.token },
      })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          const data = res.data;
          console.log(res)
          if (data.status === 1) {
            setApiList(data.apiList);
          } else {
            setApiList([])
            setErrorMessae(data.message);
          }
        } else {
          setErrorMessae(res.statusText);
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e);
      });
  }, [props]);

  function goto(path, apiId) {
    history.push(path, {
      apiId: apiId,
    });
  }

  return (
    <div className="box-shadow-6">
      <div className="heading p-2">API List</div>
      {errorMessae !== "" ? (
        <div className="pl-2 pr-2 pb-2">
          <div className="alert alert-default">{errorMessae}</div>
        </div>
      ) : (
        ""
      )}

      {loading ? (
            <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
          ) : (
        
        apiList.length > 0 ? (
        <div>
          <table className="trans-report-table">
          <thead>
            <tr>
              <th>S.N</th>
              <th>API ID</th>
              <th>Name</th>
              <th>Website</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {apiList.map((list, index) => (
              <tr key={list.id}>
                <td>{index + 1}</td>
                <td>{list.id}</td>
                <td>{list.vendorName}</td>
                <td>{list.website}</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="btn btn-primary"
                      onClick={() => goto("configApi/requestParam", list.id)}
                    >
                      ADD PARAM
                    </div>
                    <div className="btn btn-danger">DELETE</div>
                    <div className="btn btn-primary">EDIT</div>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      ))}
    </div>
  );
}
