import axios from 'axios'
import React, { useState } from 'react'

export default function Settings(props) {
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [currentMpin, setCurrentMpin] = useState('')
    const [newMpin, setNewMpin] = useState('')
    const [confirmMpin, setConfirmMpin] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [mpinError, setMpinError] = useState(false)

    function changeMpinHandler(e) {
        e.preventDefault();
        if (currentMpin !== '' && newMpin !== '' && confirmMpin !== '') {
            if (newMpin === confirmMpin) {
                setLoading(true)
                axios.post('/api/dt/mpin/change/' + props.user.id,
                    {
                        currentMpin: currentMpin,
                        newMpin: newMpin
                    },
                    {
                        headers: { Authorization: props.user.token }
                    }).then(res => {
                        if (res.status === 200) {
                            const data = res.data
                            console.log(res)
                            setCurrentMpin('')
                            setNewMpin('')
                            setConfirmMpin('')
                            setLoading(false)
                            if (data.status === 1) {
                                setError(data.message)
                            } else {
                                setMpinError(data.message)
                            }
                        }
                    }).catch(error => {
                        setMpinError(error)
                    })

            } else {
                setCurrentMpin('')
                setNewMpin('')
                setConfirmMpin('')
                setMpinError('Mpin do not match ')
            }
        } else {
            setMpinError('Empty details and fill all input properly')
        }
    }

    function changePasswordHandler(e) {
        e.preventDefault();
        if (currentPassword !== '' && newPassword !== '' && confirmPassword !== '') {
            if (newPassword === confirmPassword) {
                setLoading(true)
                axios.post('/api/dt/password/change/' + props.user.id,
                    {
                        currentPassword: currentPassword,
                        newPassword: newPassword
                    },
                    {
                        headers: { Authorization: props.user.token }
                    }).then(res => {
                        if (res.status === 200) {
                            const data = res.data
                            console.log(res)
                            setCurrentPassword('')
                            setNewPassword('')
                            setConfirmPassword('')
                            setLoading(false)
                            if (data.status === 1) {
                                setError(data.message)
                            } else {
                                setError(data.message)
                            }
                        }
                    }).catch(error => {
                        setError(error)
                    })

            } else {
                setError('Password do not match ')
            }
        } else {
            setError('Empty details and fill all input properly')
        }
    }
    return (
        <div>
            <div className="container">
                <div className="content-wrap">
                    <div className="heading">Change Password</div>
                    <div className="change_password_card">
                        {error ? <div className='heading p-1'>{error}</div> : ''}
                        <form method="POST" className="change_password_form">
                            <input type="password" className="form-control" id="currentPassword" name="currentPassword" value={currentPassword} placeholder="Enter current password" onChange={(e) => setCurrentPassword(e.target.value)} />
                            <input type="password" className="form-control" id="newPassword" name="newPassword" value={newPassword} placeholder="Enter new password" onChange={(e) => setNewPassword(e.target.value)} />
                            <input type="password" className="form-control" id="confirmPassword" name="confirmPassword" value={confirmPassword} placeholder="Enter confirm password" onChange={(e) => setConfirmPassword(e.target.value)} />
                            <div className='form-group'>
                                <button
                                    type='submit'
                                    id='changePwdBtn'
                                    name='changePwdBtn'
                                    className='btn btn-primary'
                                    onClick={isLoading ? null : changePasswordHandler}
                                >
                                    {isLoading ? 'Loading...' : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="heading">Change Mpin</div>
                    <div className="change_mpin_card">
                        {mpinError ? <div className='heading p-1'>{mpinError}</div> : ''}
                        <form method="POST" className="change_mpin_form">
                            <input type="password" maxLength='4' className="form-control" id="currentMpin" name="currentMpin" value={currentMpin} placeholder="Enter current mpin" onChange={(e) => setCurrentMpin(e.target.value)} />
                            <input type="password" maxLength='4' className="form-control" id="newMpin" name="newMpin" value={newMpin} placeholder="Enter new mpin" onChange={(e) => setNewMpin(e.target.value)} />
                            <input type="password" maxLength='4' className="form-control" id="confirmMpin" name="confirmMpin" value={confirmMpin} placeholder="Enter confirm mpin" onChange={(e) => setConfirmMpin(e.target.value)} />
                            <div className='form-group'>
                                <button
                                    type='submit'
                                    id='changeMpinBtn'
                                    name='changeMpinBtn'
                                    className='btn btn-primary'
                                    onClick={isLoading ? null : changeMpinHandler}
                                >
                                    {isLoading ? 'Loading...' : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
