import axios from "axios";
import { Link } from "react-router-dom";
import React from 'react'

export default function AdminHeader(props) {
  const logoutHandler = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (
      user.id !== null &&
      user.token !== null &&
      user.id !== undefined &&
      user.token !== undefined
    ) {
      axios
        .get("/api/user/" + user.id, { headers: { Authorization: user.token } })
        .then((res) => {
          if (res.status === 200) {
            localStorage.clear();
            props.setLoggedIn(false);
          } else {
            localStorage.clear();
            props.setLoggedIn(false);
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      localStorage.clear();
      props.setLoggedIn(false);
    }
  };

  return (
    <div className="headerBox">
      <div className="wrap">
        <div className="logo">
          <Link to="/" style={{ textDecoration: "none", color: "#680e7a" }}>
            BS
          </Link>
        </div>
        <div className="NavBarMenu">
          <ul>
            <li>
              <Link className="linkTextDecorate" to="/new_user">
                New User
              </Link>
            </li>
            <li>
              <Link className="linkTextDecorate" to="/users">
                User
              </Link>
            </li>
            <li>
              <Link className="linkTextDecorate" to="#">
                Report
              </Link>
              <div className="Sub-menu-2">
                <ul>
                  <li>
                    <Link
                      className="linkTextDecorate"
                      to="/report/transaction_history"
                    >
                      Transaction History
                    </Link>
                  </li>
                 
                  <li>
                    <Link
                      className="linkTextDecorate"
                      to="/report/aeps_history"
                    >
                      AEPS History
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="linkTextDecorate"
                      to="/report/settlement_history"
                    >
                      Settlement History
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="linkTextDecorate"
                      to="/report/account_statement"
                    >
                      Account Statement
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="linkTextDecorate"
                      to="/report/topup_history"
                    >
                      TopUp History
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="linkTextDecorate"
                      to="/report/operator_report"
                    >
                      Operator Report
                    </Link>
                  </li>
                  <li>
                    <Link className="linkTextDecorate" to="/report/api_report">
                      API Report
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link className="linkTextDecorate" to="/payment_request">
                Payment Requests
              </Link>
            </li>

            <li>
              <Link className="linkTextDecorate" to="/complain">
                Complaints
              </Link>
            </li>
            <li>
              <Link className="linkTextDecorate" to="/configApi">
                API Configuration
              </Link>
            </li>
            <li>
              <Link className="linkTextDecorate" to="#">
                More
              </Link>
              <div className="Sub-menu-2">
                <ul>
                  <li>
                    <Link className="linkTextDecorate" to="/settings">
                      Settings
                    </Link>
                  </li>
                  <li>
                    <Link className="linkTextDecorate" to="/packageList">
                      Package
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className="NavBarMenuBtn">
          <ul>
            <li style={{ cursor: "pointer" }} onClick={logoutHandler}>
              Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
