import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import './report.css'
import { CircularProgress } from '@mui/material';

export default function AepsHistory (props) {
  const [trxnsHistoryList, setTrxnsHistoryList] = useState([])
  const [isLoading, setLoading] = useState(false)
  var lastTxnId = ''

  useEffect(() => {
    if (props.user.id != null) {
      setLoading(true)
      axios
        .post(
          '/api/aeps/history/' + props.user.id,
          {
            lastId: lastTxnId
          },
          { headers: { Authorization: props.user.token } }
        )
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            const data = res.data.response
            console.log(res)
            if (data !== undefined && data !== null && data.status === 1) {
              setTrxnsHistoryList(data.list)
            }
          }
        })
        .catch(error => {
          alert(error.message)
        })
    }
  }, [props])

  return (
    <div>
      <div className='container'>
        <div className='wrap' style={{ flexDirection: 'column' }}>
          <div className='heading'>Aeps History</div>
          {isLoading ? (
            <div
              className='p-2'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className='trxns-report-card'>
              {trxnsHistoryList.length > 0 ? (
                <table className='trans-report-table'>
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>transaction Id</th>
                      <th>Type</th>
                      <th>UTR No</th>
                      <th>Operator Name</th>
                      <th>Aadhar Number</th>
                      <th>Date</th>
                      <th>Mobile No</th>
                      <th>Amount</th>
                      <th>Commission</th>
                      <th>Charge</th>
                      <th>Balance</th>
                      <th>GST</th>
                      <th>TDS</th>
                      <th>Status</th>
                      <th>Compain</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trxnsHistoryList.map((transaction, index) => {
                      lastTxnId = transaction.id
                      return (
                        <tr key={transaction.id.toString()}>
                          <td>{index + 1}</td>
                          <td>{transaction.id}</td>
                          <td>
                            {transaction.transType === 'CW'
                              ? 'Withdrawal'
                              : transaction.transType === 'BE'
                              ? 'Balance Equiry'
                              : 'Settlement'}
                          </td>
                          <td>{transaction.ref_no}</td>
                          <td>{transaction.BankName}</td>
                          <td>{transaction.aadharNo}</td>
                          <td>
                            {moment(transaction.rechargeAt).format(
                              'YYYY-MM-DD h:mm:ss a'
                            )}
                          </td>
                          <td>{transaction.mobile}</td>
                          <td>{transaction.amount}</td>
                          <td>
                            {transaction.commission !== null &&
                            transaction.commission !== ''
                              ? transaction.commission
                              : '0.00'}
                          </td>
                          <td>
                            {transaction.charge !== null &&
                            transaction.charge !== ''
                              ? transaction.charge
                              : '0.00'}
                          </td>
                          <td>
                            {transaction.balance !== '' && transaction.balance !== null
                              ? transaction.balance
                              : ''}
                          </td>
                          <td>
                            {transaction.gst !== '' && transaction.gst !== null
                              ? transaction.gst
                              : '0.00'}
                          </td>
                          <td>
                            {transaction.tds !== '' && transaction.tds !== null
                              ? transaction.tds
                              : '0.00'}
                          </td>
                          {transaction.status === '1' ? (
                            <td className='text-success'>Success</td>
                          ) : (
                            <td className='text-failure'>Failure</td>
                          ) }
                          {transaction.status === '1' ? (
                            <td>
                              <Link
                                to={{
                                  pathname: '/complain/',
                                  query: { rechargeId: transaction.id }
                                }}
                              >
                                <i className='material-icons'>handyman</i>
                              </Link>
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : (
                <div className='p-1'>
                  <div className='heading'>No Transaction available</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
