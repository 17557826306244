import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from '../Components/LoginPage/Login'
import DistributorProtected from '../Components/Distributor/DistributorProtected.js'
import Page404 from '../Page404'
import UserInfo from '../Components/Distributor/users/UserInfo'
import usersList from '../Components/Distributor/users/usersList'
import FundTransfer from '../Components/Distributor/users/FundTransfer'
import PaymentRequest from '../Components/Distributor/Payment_Request/PaymentRequest'
import CreateUsers from '../Components/Distributor/users/CreateUsers'
import Complaints from '../Components/Distributor/Complaints/Complaints'
import RechargesHistory from '../Components/Distributor/Report/RechargesHistory'
import DTHome from '../Components/Distributor/HomePage/DTHome.js'
import AccountStatement from '../Components/Distributor/Report/AccountStatement'
import TopUpHistory from '../Components/Distributor/Report/TopUpHistory'
import OperatorReport from '../Components/Distributor/Report/OperatorReport'
import Settings from '../Components/Distributor/Security/Settings'
import DMTHistory from '../Components/Distributor/Report/DMTHistory'
import RedirectHomePage from '../Components/LoginPage/RedirectHomePage'

export default function DistributorRouter() {
    return (
    <Switch>
      <DistributorProtected exact path='/' page={DTHome} />
      <DistributorProtected exact path='/new_user' page={CreateUsers} />
      <DistributorProtected exact path='/users' page={usersList} />
      <DistributorProtected exact path='/user/info' page={UserInfo} />
      <DistributorProtected exact path='/user/fund-transfer' page={FundTransfer} />
      <DistributorProtected exact path='/report/recharge_history' page={RechargesHistory} />
      <DistributorProtected exact path='/report/dmt_history' page={DMTHistory} />
      <DistributorProtected exact path='/report/account_statement' page={AccountStatement} />
      <DistributorProtected exact path='/report/topup_history' page={TopUpHistory} />
      <DistributorProtected exact path='/report/operator_report' page={OperatorReport} />
      <DistributorProtected exact path='/payment_request' page={PaymentRequest} />
      <DistributorProtected exact path='/complaint' page={Complaints} />
      <DistributorProtected exact path='/setting' page={Settings} />
      <Route exact path="/logout" component={RedirectHomePage} />
      <Route exact path='/login' component={Login} />
      <Route path='*' component={Page404} />
    </Switch>
    )
}
